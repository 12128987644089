import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useContext } from "react";
import { DashboardContext } from "states/DashboardContext";

export default function CensusMediaCheckBoxCard() {
  // global state
  const {censusMediaCheckboxState,} =useContext(DashboardContext)

  const [censusMediaCheckbox, setCensusMediaCheckbox] = censusMediaCheckboxState

  // local 

  const handleChange = (event) => {
    setCensusMediaCheckbox({
      ...censusMediaCheckbox,
      [event.target.name]: event.target.checked,
    });
  };

  const { all, results, documentary} = censusMediaCheckbox;

  const words = useTranslations()

  return (
    <div className="flex justify-center w-full">
      <FormControl component="fieldset" variant="standard">
        <Typography sx={{ fontWeight: 'bold' }}>{words.category}</Typography>
        <FormGroup>
          {/* <FormControlLabel
            control={
              <Checkbox checked={all} onChange={handleChange} name="all" />
            }
            label={words.allLabel}
          /> */}
          <FormControlLabel
            control={
              <Checkbox checked={results} onChange={handleChange} name="results" />
            }
            label={words.resultsLabel}
          />
          <FormControlLabel
            control={
              <Checkbox checked={documentary} onChange={handleChange} name="documentary" />
            }
            label={words.documentaryLabel}
          />
        
        </FormGroup>
      </FormControl>
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    category: language ? 'Categories' : 'Makundi',
    allLabel: language ? 'View All' : "Onesha Zote",
    resultsLabel: language ? 'Census Results' : "Matokeo ya sensa",
    documentaryLabel: language ? 'Documentary' : "Makala",
    language
  }
}