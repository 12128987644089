import { Typography } from "@mui/material";
import React, { useContext } from "react";
import houseHoldsize from "resources/img/CensusHouseHoldSize.png";
import houseHolds from "resources/img/cencusHouseHold.png";
import { DashboardContext } from "states/DashboardContext";

export default function CensusHouseHoldViz(props) {
  const words = useTranslations();
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div className="flex flex-col w-full p-5">
      <Typography>{words.household}</Typography>
      <div className="flex flex-row justify-around">
        <div className="flex flex-col  items-center gap-2">
          <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
            <Typography
              style={{
                padding: "2px",
                fontSize: "bold",
                paddingBottom: "0px",
                paddingTop: "0px",
              }}
            >
              2012
            </Typography>
          </div>
          <img
            src={houseHolds}
            style={{ height: "50px", width: "80px" }}
            alt="img"
          />
          {props.totalhousehold["2012"] ? (
            <Typography fontSize={30} fontWeight={"bold"}>
              {numberWithCommas(props.totalhousehold["2012"])}
            </Typography>
          ) : (
            <Typography fontSize={20}>{words.noData}</Typography>
          )}
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
            <Typography
              style={{
                padding: "2px",
                fontSize: "bold",
                paddingBottom: "0px",
                paddingTop: "0px",
              }}
            >
              2022
            </Typography>
          </div>
          <img
            src={houseHolds}
            style={{ height: "50px", width: "80px" }}
            alt="img"
          />
          {props.totalhousehold["2022"] ? (
            <Typography fontSize={30} fontWeight={"bold"}>
              {numberWithCommas(props.totalhousehold["2022"])}
            </Typography>
          ) : (
            <Typography fontSize={20}>{words.noData}</Typography>
          )}
        </div>
      </div>
      <Typography>{words.averagehousehold}</Typography>
      <div className="flex flex-row justify-around">
        <div className="flex flex-col items-center gap-2">
          <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
            <Typography
              style={{
                padding: "2px",
                fontSize: "bold",
                paddingBottom: "0px",
                paddingTop: "0px",
              }}
            >
              2012
            </Typography>
          </div>
          <img
            src={houseHoldsize}
            style={{ height: "50px", width: "70px" }}
            alt="img"
          />
          {props.averagehousehold["2012"] ? (
            <Typography fontSize={30} fontWeight={"bold"}>
              {props.averagehousehold["2012"]}
            </Typography>
          ) : (
            <Typography fontSize={20}>{words.noData}</Typography>
          )}
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
            <Typography
              style={{
                padding: "2px",
                fontSize: "bold",
                paddingBottom: "0px",
                paddingTop: "0px",
              }}
            >
              2022
            </Typography>
          </div>
          <img
            src={houseHoldsize}
            style={{ height: "50px", width: "70px" }}
            alt="img"
          />
          {props.averagehousehold["2022"] ? (
            <Typography fontSize={30} fontWeight={"bold"}>
              {props.averagehousehold["2022"]}
            </Typography>
          ) : (
            <Typography fontSize={20}>{words.noData}</Typography>
          )}
        </div>
      </div>
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    household: language ? "Number of Households" : "Idadi ya Kaya",
    averagehousehold: language
      ? "Average Household Size"
      : "Wastani wa Watu kwa Kaya",

    noData: language ? "No Data " : "Hakuna Taarifa",
    language,
  };
};
