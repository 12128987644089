import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { DashboardContext } from "states/DashboardContext";
import { CensusMaterialTabs } from "ui/components/census/CensusMaterialTabs";

export function CensusVisualization() {
    const { languageState } = useContext(DashboardContext);
    const [language] = languageState;
    const [selectedIndex, setSelectedindex] = React.useState(0);
    const visualizationsMenuItems = [
      language
        ? { label: "Demography", path: "." }
        : { label: "Demografia", path: "." },
      language
        ? { label: "Buildings", path: "buildings" }
        : { label: "Majengo", path: "buildings" },
      language
        ? { label: "Social services", path: "services" }
        : { label: "Huduma za Jamii", path: "services" },
    ];
    const navigate = useNavigate();
    return (
      <div className="flex flex-col w-full min-h-screen px-4 py-10">
        
        <CensusMaterialTabs
          value={selectedIndex}
          handler={(e, val) => {
            setSelectedindex(val);
          }}
          options={visualizationsMenuItems}
          navFn={(path) => {
            navigate(`/dissemination/visualization/${path}`);
          }}
        />
        <br />
        <div className="flex justify-center"><p>Source: Population and Housing (PHC) 2022 & Population and Housing (PHC) 2012, National Bureau of Statistics, Tanzania</p></div>
        <Outlet />
      </div>
    );
  }