import React from "react";
import { CensusCadBox } from "./CensusCardBox";

function CensusInfoCrds({ cardData, color, showBorder = true }) {
  return (
    <div className="flex flex-col w-full lg:flex-row rounded-t-xl overflow-clip">
      {cardData?.map((cardItem, index) => {
        return (
          <CensusCadBox
            key={index}
            label={cardItem.label}
            data={cardItem.data}
            color={color}
            index={index}
            contentHeader={cardItem.contentHeader}
            showBorder={showBorder}
          />
        );
      })}
    </div>
  );
}

export default CensusInfoCrds;
