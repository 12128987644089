import ReactECharts from "echarts-for-react";
import { useContext } from "react";
import { DashboardContext } from "states/DashboardContext";

function BuildingCountPerRegion() {
  const words = useTranslations();

  const options = {
    title: {
      text: words.chartTitle,
      textStyle: {
        fontSize: "20",
      },
    },
    maintainAspectRatio: false,
    grid: {
      containLabel: true,
      left: "5%",
      bottom: "0%",
      right: "5%",
      top: "12%",
    },
    tooltip: { trigger: "axis" },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar"] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    dataZoom: [
      { show: false },
      { type: "inside" },
      {
        show: false,
        yAxisIndex: 10,
        filterMode: "empty",
        width: 30,
        height: "70%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    xAxis: {
      type: "category",
      data: ((regions) => {
        const dt = regions.map((region) => {
          return region.name;
        });
        return dt;
      })(regionData),
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 45,
        fontSize: "16",
        fontWeight: "bold",
      },
    },
    yAxis: [
      {
        type: "value",
        name: words.chartYAxis,
        nameLocation: "middle",
        nameGap: 100,
        axisLabel: {
          formatter: "{value}",
          show: true,
          interval: 0,
          rotate: 0,
          fontSize: "16",
          fontWeight: "800",
        },
      },
    ],
    series: [
      {
        type: "bar",
        name: "",
        barMaxWidth: 20,
        data: ((regions) => {
          const regionCounts = regions.map((region) => region.value);
          const dt = regionCounts.map((count, index) => {
            return {
              value: count,
              itemStyle: {
                color: "#1985A1",
              },
            };
          });
          return dt;
        })(regionData),
      },
    ],
  };
  return (
    <div className="w-[100%] bg-white shadow-xl rounded-xl p-4 flex flex-col gap-4 text-[1.5vw]">
      <div className="flex flex-col items-center justify-center w-full h-full gap-2">
        <ReactECharts
          style={{ height: "80vh", minHeight: 200, width: "100%" }}
          option={options}
        />
      </div>
    </div>
  );
}

export default BuildingCountPerRegion;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    chartTitle: language
      ? "Number of buildings by region"
      : "Idadi ya Majengo kwa Mikoa",
    chartYAxis: language
      ? "Number of buildings"
      : "Idadi ya majengo",
    language,
  };
};

const regionData = [
  { name: "Dodoma", value: 836909 },
  { name: "Arusha", value: 553702 },
  { name: "Kilimanjaro", value: 515091 },
  { name: "Tanga", value: 676397 },
  { name: "Morogoro", value: 762454},
  { name: "Pwani", value: 504979},
  { name: "Dar es Salaam", value: 913707 },
  { name: "Lindi", value: 341398 },
  { name: "Mtwara", value: 511336 },
  { name: "Ruvuma", value: 448328 },
  { name: "Iringa", value: 357777  },
  { name: "Mbeya", value: 623054 },
  { name: "Singida", value: 463353},
  { name: "Tabora", value: 680103 },
  { name: "Rukwa", value: 302368 },
  { name: "Kigoma", value: 458567 },
  { name: "Shinyanga", value: 421743 },
  { name: "Kagera", value: 716458 },
  { name: "Mwanza", value: 868430 },
  { name: "Mara", value: 636185 },
  { name: "Manyara", value: 430323 },
  { name: "Njombe", value: 279349 },
  { name: "Katavi", value: 279349 },
  { name: "Simiyu", value: 430378 },
  { name: "Geita", value: 624100 },
  { name: "Songwe", value: 362113  },
  { name: "Kaskazini Unguja", value: 74764 },
  { name: "Kusini Unguja", value: 64453 },
  { name: "Mjini Magharibi", value: 177450 },
  { name: "Kaskazini Pemba", value: 63490 },
  { name: "Kusini Pemba", value: 60264 },
].sort((a, b) => (a.value < b.value ? 1 : -1));
