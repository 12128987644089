import React, { useState } from "react";
import CensusMediaCard from "../../elements/census/CensusMediaCard";
import CensusMediaCheckBoxCard from "../../elements/census/CensusMediaCheckBoxCard";
import CensusSingleMedia from "../../elements/census/CensusSingleMedia";
import profileThumbsnail from "../../../resources/img/censusProfilethumb.png";
import { useContext } from "react";
import { DashboardContext } from "states/DashboardContext";
export default function CensusMedia() {

  // global state
  const {censusMediaCheckboxState,} = useContext(DashboardContext)
  const [censusMediaCheckbox, setCensusMediaCheckbox] = censusMediaCheckboxState
  const { all, results, documentary} = censusMediaCheckbox; 


  // local
  const words = useTranslations()
  // card data
  const media = [
    {
      id: 1,
      title: "Uzinduzi wa matokeo ya Sensa ya watu na makazi - Tanzania 2022",
      source: "NBS Tanzania",
      category: words.resultsLabel,
      link: "https://www.youtube.com/watch?v=HWw4q9ZUpDw",
      type: results
    },
    {
      id: 2,
      title: "Uzinduzi wa ripoti ya matokeo ya Sensa ya watu na makazi - Tanzania 2022",
      source: "NBS Tanzania",
      category: words.resultsLabel,
      link: "https://www.youtube.com/watch?v=mU7Ant7mTrQ",
      type: results
    },
   
  ];
  const [viewMedia, setViewMedia] = useState(0);

  function handleClick(id) {
    setViewMedia(id);
  }

  
  return (
    <div className="w-full h-full">
      {viewMedia === 0 ? (
        <div className="flex flex-row m-3 border shadow-black  bg-white">
          <div className="flex basis-1/5 w-full pt-3 ">
            <CensusMediaCheckBoxCard />
          </div>
          <div className="flex flex-col basis-4/5 gap-5 pt-3">
            {media.map(
              (item) =>
                item.type === true   && (
                  <CensusMediaCard
                    key={item.id}
                    props={item}
                    handleClick={handleClick}
                  />
                )
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-row m-3 border shadow-black w-full h-full">
          {media.map(
            (item) =>
              item.id === viewMedia && (
                <CensusSingleMedia props={item} handleClick={handleClick} />
              )
          )}
        </div>
      )}
    </div>
  );
}


const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    category: language ? 'Categories' : 'Makundi',
    allLabel: language ? 'View All' : "Onesha Zote",
    resultsLabel: language ? 'Census Results' : "Matokeo ya sensa",
    documentaryLabel: language ? 'Documentary' : "Makala",
    language
  }
}