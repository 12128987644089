import { Button } from "@mui/material";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export function CensusPageNotFound() {
  const navigate = useNavigate();
  const onGoBack = () => navigate(-1);
  return (
    <div className="bg-white h-screen w-screen flex flex-col items-center justify-center gap-4">
      <p className="text-5xl font-bold">404</p>
      <p>Page not found</p>

      <Button startIcon={<BsArrowLeftShort />} onClick={onGoBack}>
        Go back
      </Button>
    </div>
  );
}
