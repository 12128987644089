import BuildingsTanzania2 from "../CensusResults/components/buildingsTanzania2";
import BuildingCountPerRegion from "../CensusResults/components/buildingCountPerRegion";
import BuildingCountPerRegionWithAddress from "../CensusResults/components/buildingCountPerRegionWithAdress";

function CensusVisulaizationsMajengo() {
  return (
    <div className="flex flex-col items-center w-full gap-10 px-4">
      <BuildingsTanzania2 />
      <BuildingCountPerRegion />
      <BuildingCountPerRegionWithAddress />
      <br />
    </div>
  );
}

export default CensusVisulaizationsMajengo;
