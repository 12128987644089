import { Typography } from "@mui/material";

export function CensusDisseminationMobileTitle({ language, censuslogo }) {
  return (
    <div className="md:hidden flex flex-col items-center space-y-1">
      <img className="h-20" src={censuslogo} alt="logo" />
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          fontSize: "x-large",
          textAlign: "center",
        }}
      >
        CENSUS INFORMATION
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          fontSize: "large",
          textAlign: "center",
        }}
      >
        DISSEMINATION PLATFORM
      </Typography>
      <div className="w-full flex items-center space-x-1">
        <div className="h-[1px] w-full bg-white" />
        <Typography
          className="pl-[3vw] pr-[3vw]"
          style={{
            fontFamily: "cursive",
            color: "white",
            whiteSpace: "nowrap",
          }}
        >
          {language === true ? "Census Results" : "Matokeo ya Sensa"}
        </Typography>
        <div className="h-[1px] w-full bg-white" />
      </div>
    </div>
  );
}
