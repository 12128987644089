// Dummy Census Data
export const censusStoryData = {
  "1967": {
    header: "The first official Census",
    notableEvent: "The census used pencil, paper, and a vast army of enumerators.",
  },
  "1978": {
    header: "Fast forward to the second post-independence Census",
    notableEvent: " The data processing was done in both manual and automatic technique with latter using ICL key punch machine.",
  },
  "1988": {
    header: "A turning for Data Processing  and Dissemination methods",
    notableEvent: "Used computer extensively for data processing e.g the ICL DRS300 mini-computers.",
  },
  "2002": {
    header: "The introduction of Optical Character Recognition technology",
    notableEvent: "Used 100% electronic data capture system. ",
  },
  "2012": {
    header: "Updated OCR technology and software",
    notableEvent: "Tanzania witnessed the proliferation of upgrades in softwares for data processing operations.",
  },
  "2022": {
    header: "Full Digital Census",
    notableEvent: "State-of-the-art technology, powered by smart technology devices was implemented from the first phase untill the final",
  }
};

// Dummy Census Data
export const censusStoryDataSw = {
  "1967": {
    header: "Sensa rasmi ya kwanza",
    notableEvent: "Sensa ilitumia penseli, karatasi, na wadadisi wengi",
  },
  "1978": {
    header: "Sensa ya pili baada ya Uhuru",
    notableEvent: "Uchakataji wa taarifa ulifanyika kwa kutumia nguvu kazi na utaalamu wa kiufundi na baadaye kutumia utaalam wa Tehama uliotumia mashine za mkono ‘Key punch machine’",
  },
  "1988": {
    header: "Hatua ya viwango vya uchakataji wa taarifa na usambazaji wa takwimu",
    notableEvent: "Ilitumia computer ipasavyo katika uchakataji wa taarifa mfano ICL DRS300 mini-computer",
  },
  "2002": {
    header: "Kuanza kwa teknolojia ya ‘Optical Character Recognition’",
    notableEvent: "Ilitumia mfumo wa kieletroniki katika ukusanyaji wa taarifa kwa asilimia 100",
  },
  "2012": {
    header: "Teknologia ya OCR na Software",
    notableEvent: "Tanzania ilishuhudia kusambaa kwa ubora wa softwares katika shughuli za uchakataji wa taarifa",
  },
  "2022": {
    header: "Sensa ya Kijiditali",
    notableEvent: "Sensa ya kisasa katika usanii wa kiteknolojia, iliyowezeshwa  na vifaa mahiri, ilitekelezwa kuanzia hatua ya kwanza hadi ya mwisho",
  }
};

 
