import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { useParams } from "react-router-dom";
import gfm from "remark-gfm";

export function DataSharingMarkdown() {
  const { markdownKey } = useParams();
  const [markdown, setMarkdown] = useState("");
  useEffect(() => {
    const url = `/data-sharing/${markdownKey}.md`; // Constructing URL directly
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch markdown");
        }
        return response.text();
      })
      .then((response) => setMarkdown(response))
      .catch(() => setMarkdown(""));
  }, [markdownKey]);

  return (
    <div className="prose">
      <Markdown className="all-initial" remarkPlugins={[gfm]}>
        {markdown}
      </Markdown>
    </div>
  );
}
