import { useContext, useState } from "react";
import CensusHospitali from "resources/img/CensusHospitali.png";
import CensusKituoAfya from "resources/img/CensusKituoAfya.png";
import CensusZahanati from "resources/img/CensusZahanati.png";
import CensusShule from "resources/img/CensusShule.png";
import CensusPrimarySchools from "resources/img/CensusPrimarySchools.png";
import CensusSecondarySchools from "resources/img/CensusSecondarySchools.png";
import censusJumlaVituo from "resources/img/censusJumlaVituo.png";
import { DashboardContext } from "states/DashboardContext";
import CensusInfoCrds from "ui/components/census/CensusInfoCrds";
import CencusButton from "ui/elements/census/CencusButton";

function SocialServices2() {
  const words = useTranslations();
  const [selectedButton, setSelectedButton] = useState(0);
  const handleSelectedButtonChange = (index) => setSelectedButton(index);
  const info = [
    {
      label: `${words.healthservice}`,
      component: (
        <>
          <div className="flex flex-col w-full gap-4 p-4 font-bold bg-white shadow-xl rounded-xl">
            <p className="w-full border-b-2 border-b-[#E6E6E6] font-normal text-xl">
              {words.socialservice}
            </p>
            <p className="text-lg font-normal">{words.idadiyavituovyaafya}, Tanzania</p>
            <div className="flex gap-4 flex-wrap px-[5%]">
              <div className="flex items-center space-x-2 w-full lg:w-[30%]">
                <img src={censusJumlaVituo} alt="" className="h-[80px]" />
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-normal">{words.jumlavituovyaafya}</p>
                  <p className="text-2xl font-bold">10,107</p>
                </div>
              </div>

              <div className="flex items-center space-x-2 w-full lg:w-[30%]">
                <img src={CensusZahanati} alt="" className="h-[80px]" />
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-normal">{words.clinic}</p>
                  <p className="text-2xl font-bold">7,965</p>
                </div>
              </div>

              <div className="flex items-center space-x-2 w-full lg:w-[30%]">
                <img src={CensusKituoAfya} alt="" className="h-[80px]" />
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-normal">{words.healthCenter}</p>
                  <p className="text-2xl font-bold">1,466</p>
                </div>
              </div>

              <div className="flex items-center space-x-2 w-full lg:w-[30%]">
                <img src={CensusHospitali} alt="" className="h-[80px]" />
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-normal">{words.hospital}</p>
                  <p className="text-2xl font-bold">676</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full gap-4 p-4 bg-white shadow-xl rounded-xl">
            <p className="text-lg">
              {words.social} {words.social1}
            </p>
            <CensusInfoCrds
              cardData={[
                {
                  label: words.tanzaniaMainLand,
                  data: [
                    {
                      image: censusJumlaVituo,
                      count: "9,772                     ",
                      label: words.jumlavituovyaafya,
                    },
                    {
                      image: CensusZahanati,
                      count: "7,680                      ",
                      label: words.clinic,
                    },
                    {
                      image: CensusKituoAfya,
                      count: "1,430                      ",
                      label: words.healthCenter,
                    },
                    {
                      image: CensusHospitali,
                      count: "662                      ",
                      label: words.hospital,
                    },
                  ],
                },
                {
                  label: "Tanzania Zanzibar",
                  data: [
                    {
                      image: censusJumlaVituo,
                      count: "335                     ",
                      label: words.jumlavituovyaafya,
                    },
                    {
                      image: CensusZahanati,
                      count: "285                      ",
                      label: words.clinic,
                    },
                    {
                      image: CensusKituoAfya,
                      count: "36                    ",
                      label: words.healthCenter,
                    },
                    {
                      image: CensusHospitali,
                      count: "14                      ",
                      label: words.hospital,
                    },
                  ],
                },
              ]}
            />
          </div>
        </>
      ),
    },
    {
      label: `${words.school}`,
      component: (
        <>
          <div className="flex flex-col w-full gap-4 p-4 font-bold bg-white shadow-xl rounded-xl">
            <p className="w-full border-b-2 border-b-[#E6E6E6] font-normal text-xl">
              {words.socialservice}
            </p>
            <p className="text-lg font-normal">{words.idadiyashule}, Tanzania</p>
            <div className="flex gap-4 flex-wrap px-[5%]">
              <div className="flex items-center space-x-2 w-full lg:w-[45%]">
                <img src={CensusShule} alt="" className="h-[80px]" />
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-normal">{words.shulejumla}</p>
                  <p className="text-2xl font-bold">25,626</p>
                </div>
              </div>

              <div className="flex items-center space-x-2 w-full lg:w-[45%]">
                <img src={CensusPrimarySchools} alt="" className="h-[80px]" />
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-normal">{words.primarySchool}</p>
                  <p className="text-2xl font-bold">19,769</p>
                </div>
              </div>

              <div className="flex items-center space-x-2 w-full lg:w-[45%]">
                <img src={CensusSecondarySchools} alt="" className="h-[80px]" />
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-normal">{words.secondarySchool}</p>
                  <p className="text-2xl font-bold">5,857</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full gap-4 p-4 bg-white shadow-xl rounded-xl">
            <p className="text-lg">
              {words.social} {words.social2}
            </p>
            <CensusInfoCrds
              cardData={[
                {
                  label: words.tanzaniaMainLand,
                  data: [
                    {
                      image: CensusShule,
                      count: "24,858                      ",
                      label: words.shulejumla,
                    },
                    {
                      image: CensusPrimarySchools,
                      count: "19,266                      ",
                      label: words.primarySchool,
                    },
                    {
                      image: CensusSecondarySchools,
                      count: "5,592                      ",
                      label: words.secondarySchool,
                    },
                  ],
                },
                {
                  label: "Tanzania Zanzibar",
                  data: [
                    {
                      image: CensusShule,
                      count: "768                      ",
                      label: words.shulejumla,
                    },
                    {
                      image: CensusPrimarySchools,
                      count: "503",
                      label: words.primarySchool,
                    },
                    {
                      image: CensusSecondarySchools,
                      count: "265",
                      label: words.secondarySchool,
                    },
                  ],
                },
              ]}
            />
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="w-full flex flex-col min-h-screen gap-4 px-4">
      <div className="flex items-center gap-2">
        {info.map((dt, index) => {
          return (
            <CencusButton
              key={index}
              name={dt.label}
              handleClick={() => handleSelectedButtonChange(index)}
              active={selectedButton === index}
            />
          );
        })}
      </div>
      {info[selectedButton].component}
    </div>
  );
}

export default SocialServices2;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    socialservice: language ? "Social Services" : "Huduma za jamii",
    healthservice: language ? "Health Services" : "Huduma za afya",
    idadiyavituovyaafya: language
      ? "A. Number of health services centres" :
       "A. Idadi ya vituo vya kutolea huduma za Afya",
    clinic: language ? "Dispensaries" : "Zahanati",
    school: language ? "Schools" : "Shule",
    healthCenter: language ? "Health centers" : "Vituo vya afya",
    hospital: language ? "Hospitals" : "Hospitali",
    idadiyashule: language ? "B. Number of schools" : "B. Idadi ya shule",
    shulejumla: language ? "Total number of schools":"Jumla ya shule" ,
    jumlavituovyaafya: language ? "Total number of health facilities": "Jumla ya vituo vya kutolea huduma za Afya",
    primarySchool: language ? "Primary Schools" : "Shule za msingi",
    secondarySchool: language ? "Secondary Schools" : "Shule za sekondari",
    tanzaniaMainLand: language ? "Tanzania Mainland" : "Tanzania Bara",
    countLabel: language ? "Population in Tanzania" : "Idadi ya Watu, Tanzania",
    language,
  };
};
