import React, { useState, createContext, useRef } from "react";

export const DashboardContext = createContext();

export const DashboardProvider = (props) => {
  // Census
  const [showCensusMap, setCensusShowMap] = useState(true);
  const [dataSource, setDatasource] = useState("");
  const [censusIndicatorName, setCensusIndicatorName] = useState("");
  const [censusSubGroupName, setCensusSubGroupName] = useState("");
  const [censusMapData, setCensusMapData] = useState([]);
  const [censusYearSliderValue, setCensusYearSliderValue] = useState("2022");
  const [censusGuideModal, setCensusGuideModal] = useState(false);
  const [censusIsDefaultData, setCensusIsDefaultData] = useState(true);
  const [censusDefaultMapData, setCensusDefaultMapData] = useState([]);
  const [censusMapIsDrillDown, setCensusMapIsDrillDown] = useState(false);
  const [censusMapDrillDownLevel, setCensusMapDrillDownLevel] =
    useState("LVL3");
  const [censusMapDrillDownAreaName, setCensusMapDrillDownAreaName] = useState(
    []
  );
  const [censusMapDrillDownData, setCensusMapDrillDownData] = useState([]);
  const [currentHomeYear, setCurrentHomeYear] = useState("2022");
  const [censusMediaCheckbox, setCensusMediaCheckbox] = useState({
    all: true,
    results: true,
    documentary: true,
  });

  const [currentVisualiztionYear, setCurrentVisualiztionYear] = useState(2022);
  const [censusRegionShapefile, setCensusRegionShapefile] = useState({});
  const [censusMapOptions, setCensusMapOptions] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [censusVizData, setCensusVizData] = useState([]);
  const [censusInitialLoadViz, setCensusInitialLoadViz] = useState(true);
  const [vizAreaName, setVizAreaName] = useState("Tanzania");
  const [censusAgeGroup, setCensusAgeGroup] = useState([]);
  const [censusSpecialGroup, setCensusSpecialGroup] = useState([]);
  const [censusSelectedIndicator, setCensusSelectedIndicator] = useState([])
  const [sector, setSector] = useState([])
  const [subsector, setSubsector] = useState([])
  const [indicator, setIndicator] = useState([])
  const [subgroup, setSubgroup] = useState([])
  
  // LAnguage
  const [language, setLanguage] = useState(true);
  const [loading, setLoading] = useState(false);

  return (
    <DashboardContext.Provider
      value={{
        // Cencus
        showCensusMapState: [showCensusMap, setCensusShowMap],
        dataSourceState: [dataSource, setDatasource],
        censusIndicatorNameState: [censusIndicatorName, setCensusIndicatorName],
        censusSubGroupNameState: [censusSubGroupName, setCensusSubGroupName],
        censusMapDataState: [censusMapData, setCensusMapData],
        censusYearSliderValueState: [
          censusYearSliderValue,
          setCensusYearSliderValue,
        ],
        censusGuideModalState: [censusGuideModal, setCensusGuideModal],
        censusIsDefaultDataState: [censusIsDefaultData, setCensusIsDefaultData],
        censusDefaultMapDataState: [
          censusDefaultMapData,
          setCensusDefaultMapData,
        ],
        censusMapIsDrillDownState: [
          censusMapIsDrillDown,
          setCensusMapIsDrillDown,
        ],
        censusMapDrillDownLevelState: [
          censusMapDrillDownLevel,
          setCensusMapDrillDownLevel,
        ],
        censusMapDrillDownAreaNameState: [
          censusMapDrillDownAreaName,
          setCensusMapDrillDownAreaName,
        ],
        censusMapDrillDownDataState: [
          censusMapDrillDownData,
          setCensusMapDrillDownData,
        ],
        currentHomeYearState: [currentHomeYear, setCurrentHomeYear],
        censusMediaCheckboxState: [censusMediaCheckbox, setCensusMediaCheckbox],
        currentVisualiztionYearState: [
          currentVisualiztionYear,
          setCurrentVisualiztionYear,
        ],
        censusRegionShapefileState: [
          censusRegionShapefile,
          setCensusRegionShapefile,
        ],

        censusMapOptionsState: [censusMapOptions, setCensusMapOptions],
        filterDataState: [filterData, setFilterData],
        censusVizDataState: [censusVizData, setCensusVizData],
        censusInitialLoadVizState: [censusInitialLoadViz, setCensusInitialLoadViz],
        censusVizNameState: [vizAreaName, setVizAreaName],
        censusAgeGroupState:  [censusAgeGroup, setCensusAgeGroup],
        censusSpecialGroupState:  [censusSpecialGroup, setCensusSpecialGroup],
        censusSelectedIndicatorState: [censusSelectedIndicator, setCensusSelectedIndicator],
        sectorState: [sector, setSector],
        subsectorState: [subsector, setSubsector],
        subgroupState: [subgroup, setSubgroup],
        indicatorState:  [indicator, setIndicator],
        // Language
        languageState: [language, setLanguage],
        loadingState: [loading, setLoading],
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
