import { useLocation, useNavigate } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { BiMenu } from "react-icons/bi";
import { DashboardContext } from "states/DashboardContext";

export function CensusGlobalHamburgerMenu({
  CensusStoryMenu,
  CensusDisseminationMenu,
  CensusDataSharingMenu,
  color = 'white'
}) {
  const [open, setOpen] = React.useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const words = useTranslations();

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(isOpen);
  };

  const listConstruct = [
    {
      label: words.homeWord,
      menuItems: CensusStoryMenu ?? null,
      show: pathname === "/",
      path: "/",
    },
    {
      label: words.storyWord,
      menuItems: CensusStoryMenu ?? null,
      show: pathname.startsWith("/story"),
      path: "/story",
    },
    {
      label: words.disseminationWord,
      menuItems: CensusDisseminationMenu ?? null,
      show: pathname.startsWith("/dissemination"),
      path: "/dissemination/results",
    },
    {
      label: words.sharingWord,
      menuItems: CensusDataSharingMenu ?? null,
      show: pathname.startsWith("/data-sharing"),
      path: "/data-sharing",
    },
  ];

  const list = () => {
    return (
      <Box
        sx={{ width: "80vw", maxWidth: 400 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        className="flex flex-col gap-2 max-w-[400px]"
      >
        <div className="flex flex-col gap-2 px-4 pt-4">
          <p className="text-sm text-gray-500">{words.menuTitle}</p>
        </div>

        <List className="flex flex-col gap-2">
          {listConstruct.map(({ label, menuItems, show, path }, index) => (
            <>
              <ListItem
                key={label}
                disablePadding
                onClick={() => navigate(path)}
              >
                <ListItemButton
                  sx={{
                    color: show ? "#fff" : undefined,
                    backgroundColor: show ? "#0f7895" : undefined,
                    ":hover": {
                      color: "#fff",
                      backgroundColor: "#176B87",
                    },
                  }}
                >
                  <ListItemText primary={`${words.censusWord} ${label}`} />
                </ListItemButton>
              </ListItem>

              <div className="md:hidden pl-5">{show && <>{menuItems}</>}</div>
            </>
          ))}
        </List>
        {/* <Divider /> */}
      </Box>
    );
  };
  return (
    <div className="py-4 relative px-4">
      <BiMenu
        onClick={toggleDrawer(true)}
        className="text-2xl cursor-pointer "
        style={{color}}
      />

      <Drawer anchor={"left"} open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}


const useTranslations = () => {
  const { languageState } = React.useContext(DashboardContext);
  const [language] = languageState;

  return {
    menuTitle: language ? 'Main Menu' : 'Menu Kuu',
    homeWord: language ? 'Home' : 'Nyumbani',
    storyWord: language ? 'Story' : 'Historia',
    disseminationWord: language ? 'Dissemination' : 'Usambazaji',
    sharingWord: language ? 'Data Sharing' : 'Ubadilishanaji',
    censusWord: language ? 'Census' : 'Sensa',    
    language
  };
};