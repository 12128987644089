import DownloadIcon from "@mui/icons-material/Download";
import IosShareIcon from "@mui/icons-material/IosShare";
import Button from "@mui/material/Button";
import { useContext } from "react";
import Papa from 'papaparse';
import { DashboardContext } from "../../../states/DashboardContext";
import CensusDataSource from "../../elements/census/CensusDataSource";
export default function CensusDataDownloadPane() {
  // import global state
  const {
    dataSourceState,
    censusYearSliderValueState,
    censusIndicatorNameState,
    censusIsDefaultDataState,
    censusDefaultMapDataState,
    censusMapIsDrillDownState,
    censusMapDrillDownDataState,
    censusMapDrillDownAreaNameState,
    censusMapDataState,
    censusSubGroupNameState,
    censusMapDrillDownLevelState,
    censusSelectedIndicatorState,
    subgroupState
  } = useContext(DashboardContext);
  const [dataSource] = dataSourceState;
  const [censusYearSliderValue, setCensusYearSliderValue] =
    censusYearSliderValueState;

  const [censusMapData, setCensusMapData] = censusMapDataState;

  const [censusIndicatorName, setCensusIndicatorName] =
    censusIndicatorNameState;
  const [censusSubGroupName, setCensusSubGroupName] = censusSubGroupNameState;
  const [censusIsDefaultData, setCensusIsDefaultData] =
    censusIsDefaultDataState;
  const [censusDefaultMapData, setCensusDefaultMapData] =
    censusDefaultMapDataState;
  const [censusMapIsDrillDown, setCensusMapIsDrillDown] =
    censusMapIsDrillDownState;
  const [censusMapDrillDownAreaName, setCensusMapDrillDownAreaName] =
    censusMapDrillDownAreaNameState;
  const [censusMapDrillDownData, setCensusMapDrillDownData] =
    censusMapDrillDownDataState;
    const [censusMapDrillDownLevel, setCensusMapDrillDownLevel] =
    censusMapDrillDownLevelState;
    const [censusSelectedIndicator] = censusSelectedIndicatorState;
    const [subgroup, setSubgroup] = subgroupState

  const words = useTranslations();


  
// fileDownload(data, 'filename.csv');

  const handleDownload = () => {
    // Prepare the data to download
    let data = censusIsDefaultData ? censusDefaultMapData : censusMapData;

    if (censusMapIsDrillDown) {
      data = data.filter(
        (row) =>
          parseInt(row.time_name) === parseInt(censusYearSliderValue.trim()) &&
          row.area_level === censusMapDrillDownLevel &&
          parseInt(row.parent_code) === parseInt(censusMapDrillDownData)
      );
    } else {
      data = data.filter(
        (row) =>
          parseInt(row.time_name) === parseInt(censusYearSliderValue.trim()) &&
          ["LVL1", "LVL2", "LVL3"].includes(row.area_level)
      );
    }

    // Filter out unique area codes
    const uniqueAreas = [
      ...new Map(
      data.map(v => [JSON.stringify({ area_code: v.area_code, area_name: v.area_name, area_level: v.area_level }), { area_code: v.area_code, area_name: v.area_name,  area_level: v.area_level }])
      ).values()
    ];

    // Map data to unique area codes
    const mappedData = uniqueAreas.map(area => {
      const areaData = {
        area_name: area.area_name
        
      };
    
      const indicatorsData = censusIsDefaultData === true ?
        [{
          indicatorkey: 1,
          subgroupkey: [1]
        }].flatMap(indicator =>
          indicator.subgroupkey.map(subgroupkey => {
            const indicatorData = data.find(
              row => row.area_code === area.area_code
            );

            const obj = subgroup.find(row =>
              parseInt(indicator.indicatorkey) === row.indicatorkey &&
              row.subgroupkey === subgroupkey
            );
            return {
              indicatorKey: indicator.indicatorkey,
              subgroupKey: subgroupkey,
              value: indicatorData ? indicatorData.data_value : null,
              indicatorName: "Population Size",
              subgroupName: "Total"
            };
          })
        ) :
        censusSelectedIndicator.flatMap(indicator =>
          indicator.subgroupkey.map(subgroupkey => {
            const indicatorData = data.find(
              row =>
                row.area_code === area.area_code &&
                parseInt(row.indicatorkey) === indicator.indicatorkey &&
                row.subgroupkey === subgroupkey
            );

            const obj = subgroup.find(row =>
              parseInt(indicator.indicatorkey) === row.indicatorkey &&
              row.subgroupkey === subgroupkey
            );
            return {
              indicatorKey: indicator.indicatorkey,
              subgroupKey: subgroupkey,
              value: indicatorData ? indicatorData.data_value : null,
              indicatorName: indicatorData ? indicatorData.indicator_name : "Unknown",
              subgroupName: indicatorData ? indicatorData.subgroup_name : "Unknown"
            };
          })
        );
    
      // Combine all indicators into the areaData object under their specific keys
      indicatorsData.forEach(indicator => {
        const key = `${indicator.indicatorName}-${indicator.subgroupName}`;
        areaData[key] = indicator.value;
      });
    
      return areaData;
    });
    
    
    
    // Generate the CSV string from the data
    const csv = Papa.unparse(mappedData);

    // // Trigger the download of the CSV file
    var fileDownload = require('js-file-download');
    fileDownload(csv, `data.csv`);
  };
  return (
    <div className="flex flex-row justify-between w-full h-full">
  {/* Data source */}
  <div className="w-full">
    <CensusDataSource />
  </div>

  {/* Bottom Buttons */}
  <div className="flex items-center gap-2">
      <Button
        variant="contained"
        startIcon={<IosShareIcon />}
        sx={{
          background: "#2C9443",
        }}
      >
        <p className="text-xs">{words.share}</p>
      </Button>
      <Button
        onClick={handleDownload}
        variant="contained"
        startIcon={<DownloadIcon />}
        sx={{
          background: "#525252",
        }}
      >
        <p className="text-xs">{words.download}</p>
      </Button>
  </div>
</div>

  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    selectIndicator: language ? "Select indicator" : "Chagua Kiashiria",
    share: language ? "Share" : "Shirikisha",
    download: language ? "Download" : "Pakua",
    language,
  };
};
