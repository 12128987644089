import ReactECharts from "echarts-for-react";
import { useContext } from "react";
import { DashboardContext } from "states/DashboardContext";

function BuildingCountPerRegionWithAddress() {
  const words = useTranslations();

  const options = {
    title: {
      text: words.chartTitle,
      textStyle: {
        fontSize: "20",
      },
    },
    maintainAspectRatio: false,
    grid: {
      containLabel: true,
      left: "5%",
      bottom: "0%",
      right: "5%",
      top: "12%",
    },
    tooltip: { trigger: "axis" },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar"] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    dataZoom: [
      { show: false },
      { type: "inside" },
      {
        show: true,
        yAxisIndex: 10,
        filterMode: "empty",
        width: 30,
        height: "70%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    xAxis: {
      type: "category",
      data: ((regions) => {
        const dt = regions.map((region) => {
          return region.name;
        });
        return dt;
      })(regionData),
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 45,
        fontSize: "16",
        fontWeight: "800",
      },
    },
    yAxis: [
      {
        type: "value",
        name: words.chartYAxis,
        nameLocation: "middle",
        nameGap: 100,
        axisLabel: {
          formatter: "{value}",
          show: true,
          interval: 0,
          rotate: 0,
          fontSize: "16",
          fontWeight: "800",
        },
      },
    ],
    series: [
      {
        type: "bar",
        name: "",
        barMaxWidth: 20,
        data: ((regions) => {
          const regionCounts = regions.map((region) => region.value);
          const dt = regionCounts.map((count, index) => {
            return {
              value: count,
              itemStyle: {
                color: "#1985A1",
              },
            };
          });
          return dt;
        })(regionData),
      },
    ],
  };
  return (
    <div className="w-[100%] bg-white shadow-xl rounded-xl p-4 flex flex-col gap-4 text-[1.5vw]">
      <div className="flex flex-col items-center justify-center w-full h-full gap-2">
        <ReactECharts
          style={{ height: "80vh", minHeight: 200, width: "100%" }}
          option={options}
        />
      </div>
    </div>
  );
}

export default BuildingCountPerRegionWithAddress;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    chartTitle: language
      ? "Number of buildings with home addresses by region"
      : "Idadi ya Majengo yenye anwani za makazi  kwa Mikoa",
    chartYAxis: language
      ? "Number of Buildings"
      : "Idadi ya majengo",
    language,
  };
};

const regionData = [
  { name: "Dodoma", value: 531072 },
  { name: "Arusha", value: 410498 },
  { name: "Kilimanjaro", value: 406838 },
  { name: "Tanga", value: 482127  },
  { name: "Morogoro", value: 536095},
  { name: "Pwani", value: 352391  },
  { name: "Dar es Salaam", value: 638673 },
  { name: "Lindi", value: 275573 },
  { name: "Mtwara", value: 451095 },
  { name: "Ruvuma", value: 370620 },
  { name: "Iringa", value: 286161 },
  { name: "Mbeya", value: 466058 },
  { name: "Singida", value: 332499 },
  { name: "Tabora", value: 482033  },
  { name: "Rukwa", value: 198424 },
  { name: "Kigoma", value: 198424 },
  { name: "Shinyanga", value: 283042 },
  { name: "Kagera", value: 598332 },
  { name: "Mwanza", value: 637231  },
  { name: "Mara", value: 490774 },
  { name: "Manyara", value: 324647 },
  { name: "Njombe", value: 244920 },
  { name: "Katavi", value: 141988 },
  { name: "Simiyu", value: 352371 },
  { name: "Geita", value: 520154 },
  { name: "Songwe", value: 239587 },
  { name: "Kaskazini Unguja", value: 56943 },
  { name: "Kusini Unguja", value: 49243 },
  { name: "Mjini Magharibi", value: 130218 },
  { name: "Kaskazini Pemba", value: 57058 },
  { name: "Kusini Pemba", value: 50939 },
].sort((a, b) => (a.value < b.value ? 1 : -1));
