import { Typography } from "@mui/material";
import React, { useContext } from "react";
import ReactPlayer from "react-player";
import Button from "@mui/material/Button";
import { DashboardContext } from "states/DashboardContext";

export default function CensusMediaCard({ props, handleClick }) {

  function capitalizeFirstLetter(strings) {
    let string = strings.toLowerCase()
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const words = useTranslations()

  return (
    <div className="flex flex-row w-full h-[40vh] mb-[5vh] mt-[5vh] gap-3">
      <div className="flex basis-2/5">
        <ReactPlayer
          url={props.link}
          className="react-player"
          playing={false}
          light={props?.light && `${props.light}`}
          width="100%"
          height="100%"
        />
      </div>
      <div className="flex flex-col basis-3/5">
        <div className="flex flex-row basis-2/3 ">
          <div className="basis-1/2">
            {" "}
            <Typography sx={{ fontWeight: 'bold' }}> {props.title} </Typography>
            <Typography fontSize='small'> {props.source}</Typography>
          </div>
          <div className="flex items-end justify-center basis-1/2">
            <Typography>{props.category}</Typography>
          </div>
        </div>
        <div className="basis-1/3">
          <Button onClick={() => handleClick(props.id)} variant="outlined">{words.view}</Button>
        </div>
      </div>
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    view: language ? 'View' : 'Angalia',
    language
  }
}


