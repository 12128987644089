import React, { useContext, useState,useEffect,} from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Button, IconButton, Grid } from "@mui/material";
import { motion } from "framer-motion";
import CensusLandingNavbar from "ui/components/census/CensusLandingNavbar";
import sensaLogo from "resources/img/censusSensaLogo.png";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

import FacebookIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import PublicIcon from "@mui/icons-material/Public";
import TwitterIcon from "@mui/icons-material/Twitter";
import {censusStoryData, censusStoryDataSw } from "states/census/census-story-data";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { DashboardContext } from "states/DashboardContext";
import clsx from "clsx";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import { Launch } from "@mui/icons-material";

// Styles for the dotted lines
const styles = {
  backgroundGradient: {
    background: `      
      linear-gradient(45deg, #fff 30%, transparent 70%), 
      linear-gradient(-45deg, #61A1F1 30%, transparent 70%), 
      linear-gradient(-45deg, #fff 30%, transparent 70%), 
      linear-gradient(35deg, #6CEB9A 30%, transparent 70%),         
      linear-gradient(185deg, #fff 30%, transparent 70%),         
      linear-gradient(-135deg, #6CEB9A 30%, transparent 70%), 
      linear-gradient(135deg, #fff 30%, transparent 70%), 
      linear-gradient(9deg, #fff 30%, transparent 70%),                        
      linear-gradient(-135deg, #fff 30%, transparent 70%)
    `,
  },

  timelineEventWrapperOdd: {
    position: "absolute",
    top: "15px",
    height: "1px",
    width: "calc(50% - 40px)",
    backgroundImage: "linear-gradient(to right, #495FBA 50%, transparent 50%)",
    backgroundSize: "10px 2px",
    left: "50%",
    transform: "translateX(100%)",
  },
  timelineEventWrapperEven: {
    position: "absolute",
    top: "15px",
    height: "1px",
    width: "calc(50% - 40px)",
    backgroundImage: "linear-gradient(to left, #495FBA 50%, transparent 50%)",
    backgroundSize: "10px 2px",
    right: "50%",
    transform: "translateX(-100%)",
  },
};

export default function CensusStory() {
  const navigate = useNavigate();
  const words = useTranslations();

  const [open, setOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);

  const handleOpen = (year) => {
    setSelectedYear(year);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [videoOpen, setVideoOpen] = useState(false);
  const handleVideoOpen = () => {
    setVideoOpen(true);
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
  };


  const [markdown, setMarkdown] = useState("");
  useEffect(() => {
    const url = `/census-story/${selectedYear}.md`; 
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch markdown");
        }
        return response.text();
      })
      .then((response) => setMarkdown(response))
      .catch(() => setMarkdown(""));
  }, [selectedYear]);

  return (
    <div
      className="flex flex-col min-h-screen"
      style={styles.backgroundGradient}
    >
      <CensusLandingNavbar />
      {/* Main Content */}
      <div className="flex-grow overflow-y-auto p-4 md:p-8 lg:pr-12 flex justify-center">
        {/* RoadMap Content */}
        <div className="relative w-full md:w-2/3 lg:w-2/3 xl:w-2/3">
          {/* RoadMap Header */}
          <div className="mb-16 mt-6 text-center">
            <h2 className="text-3xl font-extrabold text-black">
              {words.title}
            </h2>
            {/* <p className="text-lg font-bold text-[#0f7895]">
              {words.censusStorySubtitle}
            </p> */}
            <div className="w-12 mt-2 mx-auto border-t-4 rounded-xl border-[#0f7895] "></div>
          </div>
          <div className="flex flex-col items-center">
            {/* Timeline */}
            <div className="relative w-[2px] bg-[#0f7895] flex-grow">
              {/* Timeline Events */}
              {Object.keys(words.censusStoryData).map((year, idx) => (
                <div className="relative " key={year}>
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: idx * 0.1 }}
                    className="relative h-40 mb-24"
                  >
                    {/* Vertical Dots */}
                    <div className="absolute p-4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#0f7895] w-6 h-6 rounded-full" />

                    {/* Month Label */}
                    <p
                      className={`absolute left-1/2  transform md:-translate-x-1/2 -translate-y-1/2 text-[#0f7895] font-bold text-xs sm:text-sm md:text-base ${
                        idx % 2 === 0 ? "md:ml-20 -ml-20" : "-ml-20"
                      }`}
                    >
                      {words.march}
                    </p>

                    {/* Year Label */}
                    <h3
                      className={`absolute left-1/2  font-extrabold text-xl sm:text-2xl md:text-4xl text-black ${
                        idx % 2 === 0
                          ? "md:ml-20 -ml-20 transform -translate-x-2/33 translate-y-2 md:transform md:-translate-x-6 md:translate-y-2"
                          : "-ml-20 transform md:-translate-x-2/3 translate-y-2"
                      }`}
                    >
                      {year}
                    </h3>

                    {/* Info Card */}
                    <div
                      className={clsx(
                        " w-36 sm:w-48 md:w-72 md:relative left-[2px] transform -translate-y-4 p-2 md:p-4 text-center rounded-lg shadow-md bg-[#0f7895] cursor-pointer",
                        idx % 2 === 0 ? "ml-10 md:-ml-[360px]" : "ml-10 md:ml-[80px]"
                      )}
                      onClick={() => handleOpen(year)}
                    >
                      <div
                        style={
                          idx % 2 === 0
                            ? styles.timelineEventWrapperOdd
                            : styles.timelineEventWrapperEven
                        }
                        className="hidden md:block"
                      ></div>

                      <h4 className="text-white font-bold mb-2 text-sm md:text-xl text-left md:text-center">
                        {words.censusStoryData[year].header}
                      </h4>
                      <p className="text-white text-xs md:text-sm text-left md:text-center">
                        {words.censusStoryData[year].notableEvent}
                      </p>
                      <p className="text-yellow-100 text-xs md:text-sm underline mt-2 text-left md:text-center">
                        {words.moreDetails}
                      </p>
                    </div>
                  </motion.div>
                </div>
              ))}

              {/* Modal displaying the details */}

              <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                //Backdrop={Backdrop.styles={ backgroundColor: 'transparent' }}
                sx={{
                  "& > .MuiBackdrop-root": {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "transparent",
                  },
                }}
                //BackdropProps={{ style: { backgroundColor: 'transparent' } }}
                className="flex items-center justify-center relative"
              >
                <Fade in={open}>
                  <div
                    className="bg-[#0f7895] p-8 text-white rounded-xl w-3/4 h-auto overflow-y-auto relative"
                    style={{ maxHeight: "80vh" }}
                  >

                    {/* Header */}
                    <div className="flex justify-between items-center ">
                      
                      <div className="absolute top-2 right-2">
                        <IconButton
                          onClick={handleClose}
                          style={{ color: "white" }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                    {/* Content */}
                    <div className="flex flex-wrap">
                          <div className="prose">
                            <Markdown className="all-initial text-white" remarkPlugins={[gfm]}>
                              {markdown}
                            </Markdown>
                          </div>
                     
                    </div>
                  </div>
                </Fade>
              </Modal>
            </div>

            {/* Horizontal End Line */}
            <div className="w-8 h-[1px] bg-[#0f7895]"></div>
          </div>

          {/* Divider */}
          <div className="my-8 border-t border-black w-full"></div>

          <div className="mb-2 mt-16 text-center">
            <h2 className="text-xl md:text-2xl font-extrabold text-black">
              {words.censusDocumentary}
            </h2>
            <p className="text-sm md:text-md font-bold text-[#0f7895]">
              {words.watchDocumentary}
            </p>
            <div className="w-12 mt-2 mx-auto border-t-4 rounded-xl border-[#0f7895] "></div>
          </div>

          <div className="pb-12">
            <motion.div
              className="flex flex-wrap flex-col md:flex-row items-center p-6 rounded shadow-lg mt-12 bg-[#0f7895]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
            >
              <img
                src={sensaLogo}
                alt="Census Animation Thumbnail"
                className="rounded w-full md:w-auto h-auto md:h-80 mx-auto mb-4 md:mb-0 bg-white object-contain"
                onClick={handleVideoOpen} // Open video modal on image click
              />

              {/* Video Modal */}
              <Modal
                open={videoOpen}
                onClose={handleVideoClose}
                closeAfterTransition
                //Backdrop={Backdrop.styles={ backgroundColor: 'transparent' }}
                //</motion.div>components={{ style: { backgroundColor: 'transparent' } }}
                className="flex items-center justify-center relative"
              >
                <Fade in={videoOpen}>
                  <div className="bg-[#0f7895] p-8 text-white rounded-xl w-3/4 h-auto overflow-x-auto overflow-y-auto relative">
                    <div className="absolute top-2 right-2">
                      <IconButton
                        onClick={handleVideoClose}
                        style={{ color: "white" }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>

                    {/* Video Content */}
                    <video controls autoPlay={true} className="w-full">
                      <source
                        src={words.videoPath}
                        type="video/mp4"
                      />
                      {words.browserDoesnotSupport}
                    </video>
                  </div>
                </Fade>
              </Modal>

              {/* Description */}
              <div className="flex-1 text-center text-white mt-4 md:mt-0">
                <h3 className="text-lg md:text-xl font-semibold mb-4 md:mb-8">
                  {words.censusDocumentary}
                </h3>
                
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#63BC48", color: "white" }}
                  className="mx-auto rounded-xl"
                  startIcon={<PlayArrowIcon />}
                  onClick={handleVideoOpen}
                >
                  {words.watch}
                </Button>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="my-8 border-t border-black w-full"></div>
      {/* Footer */}
      <footer className="flex p-4 mt-auto md:px-10 justify-between items-center">
        <div className="flex space-x-2 md:space-x-4">
          <a href="#" className="mx-2">
            <FacebookIcon fontSize="large" className="rounded-full" />
          </a>
          <a href="#" className="mx-2">
            <TwitterIcon fontSize="large" className="rounded-full" />
          </a>
          <a href="#" className="mx-2">
            <InstagramIcon fontSize="large" className="rounded-full" />
          </a>
          <a href="#" className="mx-2">
            <PublicIcon fontSize="large" className="rounded-full" />
          </a>
        </div>
        <img
          src={sensaLogo}
          alt="logo"
          className="w-12 h-12 md:w-24 md:h-24 rounded-full"
        />
      </footer>
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    title: language ? "Census Story" : "Historia ya Sensa",
    censusStorySubtitle: language ? "Let us show you" : "Fuatilia nasi",
    censusStoryData: language? censusStoryData:censusStoryDataSw,
    moreDetails: language? "Click for more details.":"Bofya kwa taarifa zaidi",
    censusDocumentary: language? "Census Story Documentary":"Historia ya sensa",
    censusHistory: language? "Census History Animation":"Historia ya sensa",
    watchDocumentary: language? "Watch the evolution of census":"Angalia hatua za maendeleo ya Sensa",
    watch: language? "Watch":"Tazama",
    march: language? "March":"Machi",
    browserDoesnotSupport: language? "Your browser does not support the video tag.":"Kivinjari chako hakiungi mkono lebo ya video.",
    videoPath: language? "video/story-english.mp4" : "video/story-swahili.mp4",
    language,
  };
};
