import { Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { useContext } from "react";
import { BiMenu } from "react-icons/bi";
import { MdClear } from "react-icons/md";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import censuslogo from "../../../resources/img/censusIconON.png";
import { DashboardContext } from "../../../states/DashboardContext";
import CensusGuideModal from "../../elements/census/CensusGuideModal";
import CensusHomePage from "./CensusHomePage";
import CensusMapView from "./CensusMapView";
import CensusMedia from "./CensusMedia";
import CensusPublication from "./CensusPublication";
import CensusResults from "./CensusResults";
import CensusVisulaizationsCommunityServices from "./CensusVisualizationsCommunityServices/CensusVisulaizationsCommunityServices";
import CensusVisulaizationsMajengo from "./CensusVisualizationsMajengo/CensusVisulaizationsMajengo";
import CensusDemographyVisual from "./CensusVisuals/CensusDemographyVisual";
import newGif from "../../../resources/img/CensusNewGif.gif";
import Badge from "@mui/material/Badge";

export default function CensusLayoutWrapper2() {
  // import global state
  const { censusGuideModalState, languageState } = useContext(DashboardContext);
  const [censusGuideModal, setCensusGuideModal] = censusGuideModalState;
  const [language, setLanguage] = languageState;

  // Click to open census guide modal
  function handleOpen() {
    setCensusGuideModal(true);
  }
  function changeLanguage() {
    setLanguage(!language);
  }

  // Local
  const censusLayoutHook = useCensusLayoutWrapper();

  // mobile menu toggler
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const toggleMobileMenuShowing = () => {
    setShowMobileMenu(!showMobileMenu);
    // handling background scrolling
    if (!showMobileMenu) {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "scroll";
  };

  // mobile menu overlay
  const Overlay = ({ onClick }) => {
    return (
      <div
        onClick={onClick}
        className="fixed top-0 left-0 bottom-0 right-0 cursor-pointer bg-transparent z-[899]"
      />
    );
  };

  const menuList =
    language === true
      ? censusLayoutHook.mainMenuItems
      : censusLayoutHook.menuItemsSwahili;

  const menuList2 = [
    {
      label: language === true ? "English" : "Kiswahili",
      onClick: changeLanguage,
    },
  ];

  // mobile menu card
  const MobileMenu = () => {
    const handleMenuClick = (index) => {
      censusLayoutHook.setSelectedMainTabIndex(index);
      setShowMobileMenu(false);
      // handling background scrolling
      if (!showMobileMenu) {
        document.body.style.overflow = "hidden";
      } else document.body.style.overflow = "scroll";
    };
    return (
      <div className="fixed top-0 w-[75%] bottom-0 right-0 py-5 bg-[#fff] h-screen overflow-y-scroll z-[999] shadow-md">
        <div className="flex flex-col space-y-2 p-4">
          {menuList.map((menu, index) => (
            <div
              key={index}
              onClick={() => handleMenuClick(index)}
              className={`
              w-full px-2 py-3 text-sm rounded-lg cursor-pointer hover:bg-[#0f7895] hover:text-white transition-all
              ${
                index === censusLayoutHook.selectedMainTabIndex &&
                "bg-[#0f7895] text-white"
              }
              `}
            >
              {menu}
            </div>
          ))}
        </div>

        <hr className="text-[#0f7895]" />

        <div className="flex flex-col space-y-2 p-4">
          {menuList2.map((menu, index) => (
            <div
              key={index}
              onClick={() => {
                menu.onClick();
                setShowMobileMenu(false);
                // handling background scrolling
                if (!showMobileMenu) {
                  document.body.style.overflow = "hidden";
                } else document.body.style.overflow = "scroll";
              }}
              className={`
              w-full px-2 py-3 text-sm rounded-lg cursor-pointer hover:bg-[#0f7895] hover:text-white transition-all
              `}
            >
              {menu.label}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full bg-[#EFEFEF] ">
      <div className="w-full bg-[#0f7895]">
        {/* Guide Modal */}
        <CensusGuideModal />

        <div className="w-full relative flex md:hidden bg-[#0f7895] py-4 backdrop-blur-md z-50 text-white min-h-[50px] items-center justify-center px-4">
          {!showMobileMenu ? (
            <BiMenu
              onClick={toggleMobileMenuShowing}
              className="text-2xl cursor-pointer absolute top-4 left-4"
            />
          ) : (
            <MdClear
              onClick={toggleMobileMenuShowing}
              className="text-2xl cursor-pointer absolute top-4 left-4"
            />
          )}
          <div className="flex flex-col items-center space-y-1">
            <img className="h-20" src={censuslogo} alt="logo" />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                fontSize: "x-large",
                textAlign: "center",
              }}
            >
              CENSUS INFORMATION
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                fontSize: "large",
                textAlign: "center",
              }}
            >
              DISSEMINATION PLATFORM
            </Typography>
            <div className="w-full flex items-center space-x-1">
              <div className="h-[1px] w-full bg-white" />
              <Typography
                className="pl-[3vw] pr-[3vw]"
                style={{
                  fontFamily: "cursive",
                  color: "white",
                  whiteSpace: "nowrap",
                }}
              >
                {language === true ? "Census Results" : "Matokeo ya Sensa"}
              </Typography>
              <div className="h-[1px] w-full bg-white" />
            </div>
          </div>
        </div>

        {showMobileMenu && <Overlay onClick={toggleMobileMenuShowing} />}
        {showMobileMenu && <MobileMenu />}

        <div className="hidden md:flex flex-row items-center justify-center">
          <div className="justify-end basis-1/2">
            <Typography
              align="end"
              variant="h5"
              sx={{ fontWeight: "bold", color: "white" }}
            >
              CENSUS INFORMATION
            </Typography>
          </div>
          <img className="h-20 pr-[3vw] pl-[3vw]" src={censuslogo} alt="logo" />
          <div className="basis-1/2">
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "white" }}
            >
              DISSEMINATION PLATFORM
            </Typography>
          </div>
        </div>
        <div className="hidden md:flex flex-row items-center justify-center">
          <div className="w-[10%] h-0.5 bg-white"></div>
          <Typography
            className="pl-[3vw] pr-[3vw]"
            style={{ fontFamily: "cursive", color: "white" }}
          >
            {language === true ? "Census Results" : "Matokeo ya Sensa"}
          </Typography>
          <div className="w-[10%] h-0.5 bg-white"></div>
        </div>

        <div className="hidden md:flex flex-col items-center justify-between w-full lg:flex-row">
          <MaterialTabs
            value={censusLayoutHook.selectedMainTabIndex}
            handler={censusLayoutHook.handleMainTabChange}
            options={
              language === true
                ? censusLayoutHook.mainMenuItems
                : censusLayoutHook.menuItemsSwahili
            }
          />

          <div className="flex flex-row gap-4 pt-2 pr-2">
            <FormControl size="small" variant="standard">
              <InputLabel sx={{ color: "white" }} id="demo-simple-select-label">
                {language === true ? " Language" : "Lugha"}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                label={language === true ? " Language" : "Lugha"}
                onChange={changeLanguage}
                sx={{
                  color: "#1565C0",
                  borderColor: "white",
                  background: "white",
                }}
              >
                <MenuItem value={true}>English</MenuItem>
                <MenuItem value={false}>Kiswahili</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      {((index) => {
        switch (index) {
          case 0:
            return <CensusHomePage />;

          case 1:
            return <CensusMapView />;

          case 2:
            return <CensusResults />;

          case 4:
            return (
              <>
                <br /> <CensusMedia />
              </>
            );
          case 5:
            return (
              <>
                <br />
                <CensusPublication />
              </>
            );

          case 3:
            return (
              <div className="flex flex-col w-full min-h-screen px-4 py-10">
                <MaterialTabs
                  value={censusLayoutHook.selectedVisualizationTabIndex}
                  handler={censusLayoutHook.handleVisualizationTabChange}
                  options={censusLayoutHook.visualizationsMenuItems}
                />

                {((index) => {
                  switch (index) {
                    case 0:
                      return (
                        <>
                          <br />
                          <CensusDemographyVisual />
                        </>
                      );

                    case 1:
                      return (
                        <>
                          <br />
                          <CensusVisulaizationsMajengo />
                        </>
                      );

                    case 2:
                      return (
                        <>
                          <br />
                          <CensusVisulaizationsCommunityServices />
                        </>
                      );

                    default:
                      break;
                  }
                })(censusLayoutHook.selectedVisualizationTabIndex)}
              </div>
            );

          default:
            break;
        }
      })(censusLayoutHook.selectedMainTabIndex)}
    </div>
  );
}

function useCensusLayoutWrapper() {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  const mainMenuItems = [
    "Home",
    "Dashboard",
    "Census 2022 Results",
    "Visualization",
    "Census Stories",
    <Badge
      badgeContent={
        <img style={{ height: "35px" }} src={newGif} alt="loading..." />
      }
    >
      <p>Publications & Other Channels</p>
    </Badge>,
  ];
  const menuItemsSwahili = [
    "Nyumbani",
    "Dashibodi",
    "Matokeo ya Sensa 2022",
    "Taswira",
    "Hadithi za Sensa",
    <Badge
      badgeContent={
        <img style={{ height: "35px" }} src={newGif} alt="loading..." />
      }
    >
      <p>Machapisho & Njia Zingine</p>
    </Badge>,
  ];
  const [selectedMainTabIndex, setSelectedMainTabIndex] = React.useState(5);
  const handleMainTabChange = (event, newValue) =>
    setSelectedMainTabIndex(newValue);

  const visualizationsMenuItems = [
    language ? "Demography" : "Demografia",
    language ? "Buildings" : "Majengo",
    language ? "Social services" : "Huduma za Jamii",
  ];

  const [selectedVisualizationTabIndex, setSelectedVisualizationTabIndex] =
    React.useState(0);
  const handleVisualizationTabChange = (event, newValue) =>
    setSelectedVisualizationTabIndex(newValue);

  const demographyMenuItems = ["Population Size"];
  const [selectedDemographyTabIndex, setSelectedDemographyTabIndex] =
    React.useState(0);
  const handleDemographyTabChange = (event, newValue) =>
    setSelectedDemographyTabIndex(newValue);

  return {
    mainMenuItems,
    menuItemsSwahili,
    selectedMainTabIndex,
    handleMainTabChange,
    visualizationsMenuItems,
    selectedVisualizationTabIndex,
    handleVisualizationTabChange,
    demographyMenuItems,
    selectedDemographyTabIndex,
    handleDemographyTabChange,
    setSelectedMainTabIndex,
  };
}

export function MaterialTabs({ value, handler, options }) {
  return (
    <Tabs
      value={value}
      onChange={handler}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      className="bg-[#0F7995] w-full"
      TabIndicatorProps={{
        style: { background: "#F6C904", textColor: "#F6C904" },
      }}
    >
      {options.map((option, index) => (
        <Tab
          key={index}
          label={option}
          TabIndicatorProps={{ style: { color: "#F6C904" } }}
          style={{ textTransform: "capitalize", color: "white" }}
        />
      ))}
    </Tabs>
  );
}
