import { Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ReactPlayer from "react-player";

export default function CensusSingleMedia({ props, handleClick }) {
  function capitalizeFirstLetter(strings) {
    let string = strings.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div className="w-full h-screen flex-col mt-[3%] mx-[6%] justify-around">
      <div className="w-full">
        <Typography sx={{fontWeight:'bold'}}>{capitalizeFirstLetter(props.title)}</Typography>
      </div>
      <div className="flex flex-row justify-between w-full ">
        <Typography>{props.source}</Typography>
        <Typography>{props.category}</Typography>
      </div>
      <div className="w-full">
        <Typography>Description</Typography>
      </div>

      <div className="flex h-[80%]">
        <ReactPlayer
          url={props.link}
          className="react-player"
          playing={true}
          controls = {true}
          light={props?.light && `${props.light}`}
          width="100%"
          height="100%"
        />
      </div>
      <div className="w-full flex justify-end" >
        <Button
          onClick={() => handleClick(0)}
          variant="outlined"
          startIcon={<KeyboardBackspaceIcon />}
        >
          Back
        </Button>
      </div>
    </div>
  );
}
