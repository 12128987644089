export const CensusCadBox = ({
  label,
  data,
  color,
  index,
  contentHeader,
  showBorder = true,
}) => {
  return (
    <div className="flex flex-col w-full">
      <div
        style={{ backgroundColor: color ?? "#057693" }}
        className="w-full py-4 text-2xl text-center text-white"
      >
        {label}
      </div>
      <div
        className={`w-full border-[#D5D5D5] border-[transparent] 
        ${
          index !== 0 && showBorder
            ? "lg:border-l-[1px] lg:border-l-[#999999]"
            : "border-none"
        }
         flex flex-col gap-3 px-[10%] py-[5%] min-h-[30vh] divide-y-2`}
      >
        {contentHeader && (
          <p className="w-full text-xl text-center">{contentHeader}</p>
        )}
        {data.map((dt, index) => {
          return (
            <div key={index} className="flex flex-col items-center w-full">
              {/* {dt.label && <p className="text-xl">{dt.label}</p>} */}
              <div className="flex items-center w-full gap-2">
                <div className="w-[30%] flex items-center justify-end">
                  <div
                    className={`w-[100px] aspect-square flex items-center justify-center`}
                  >
                    <img
                      src={dt.image}
                      alt=""
                      className={`${
                        dt.total ? "h-full" : "h-[70%]"
                      } object-contain w-full `}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <div className="w-[70%]">
                    {dt.label && (
                      <p className="text-sm font-normal">{dt.label}</p>
                    )}
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: dt.total ? "xx-large" : "x-large",
                      }}
                      className="min-w-[100px] whitespace-nowrap"
                    >
                      {dt.count}{" "}
                      {dt.percentage && (
                        <span className="ml-3">({dt.percentage}%)</span>
                      )}
                    </p>
                  </div>
                  <div className="flex flex-row gap-5">
                    <div>
                      <p className="text-sm font-normal">{dt.urban}</p>
                      <p className="text-l">{dt.urbanValue}</p>
                    </div>
                    <div>
                      <p className="text-sm font-normal">{dt.rural}</p>
                      <p className="text-l">{dt.ruralValue}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
