import { useContext, useState } from "react";
import CensusHospitali from "resources/img/CensusHospitali.png";
import CensusKituoAfya from "resources/img/CensusKituoAfya.png";
import CensusPrimarySchools from "resources/img/CensusPrimarySchools.png";
import CensusSecondarySchools from "resources/img/CensusSecondarySchools.png";
import CensusZahanati from "resources/img/CensusZahanati.png";
import { DashboardContext } from "states/DashboardContext";
import { Card, CardRow } from "ui/components/census/Card";
import CencusButton from "ui/elements/census/CencusButton";
import { SectionLabel } from "../CensusResults";
import {
  HealthCardContainer,
  HealthCardImage,
  HealthCardLabel,
} from "../CensusResults/components/communityServicesHealth";
import {
  SchoolCardContainer,
  SchoolCardImage,
  SchoolCardLabel,
} from "../CensusResults/components/communityServicesSchools";
import HospitaliKwaMikoa from "./components/HospitaliKwaMikoa";
import ShuleMisingiKwaMikoa from "./components/ShuleMisingiKwaMikoa";
import ShuleSekondariKwaMikoa from "./components/ShuleSekondariKwaMikoa";
import VituoVyaAfyaKwaMikoa from "./components/VituoVyaAfyaKwaMikoa";
import ZahanatiKwaMikoa from "./components/ZahanatiKwaMikoa";

function CensusVisulaizationsCommunityServices() {
  const labelBackground = "#1E6D2C";
  const words = useTranslations();
  const [selectedButton, setSelectedButton] = useState(0);
  const handleSelectedButtonChange = (index) => setSelectedButton(index);
  const info = [
    {
      label: `${words.hudumazaafya}`,
      component: (
        <div className="flex flex-col w-full gap-10">
          <SectionLabel label={words.healthServicesHeaderLabel} />
          <CommunityServicesCardsHealth labelBackground={labelBackground} />
          <ZahanatiKwaMikoa />
          <VituoVyaAfyaKwaMikoa />
          <HospitaliKwaMikoa />
          <br />
        </div>
      ),
    },
    {
      label: `${words.shule}`,
      component: (
        <div className="flex flex-col w-full gap-10">
          <SectionLabel label={words.schoolsHeaderLabel} />
          <CommunityServicesCardsSchools labelBackground={labelBackground} />
          <ShuleMisingiKwaMikoa />
          <ShuleSekondariKwaMikoa />
          <br />
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col min-h-screen gap-4 px-4">
      <div className="flex items-center gap-2">
        {info.map((dt, index) => {
          return (
            <CencusButton
              key={index}
              name={dt.label}
              handleClick={() => handleSelectedButtonChange(index)}
              active={selectedButton === index}
            />
          );
        })}
      </div>
      {info[selectedButton].component}
    </div>
  );
}

export default CensusVisulaizationsCommunityServices;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    socialServices: language ? "Social Services" : "Huduma za Jamii",
    section1: language
      ? "Number of health centers"
      : "Idadi ya vituo vya kutolea huduma za afya",
    section2: language ? "Number of schools" : "Idadi ya shule",
    clinic: language ? "Dispensaries" : "Zahanati",
    healthCenter: language ? "Health centers" : "Vituo vya afya",
    hospital: language ? "Hospitals" : "Hospitali",
    tanzaniaMainland: language ? "Tanzania Mainland" : "Tanzania Bara",
    primarySchool: language ? "Primary Schools" : "Shule za msingi",
    secondarySchool: language ? "Secondary Schools" : "Shule za sekondari",
    healthServicesHeaderLabel: language
      ? "Number of health centers"
      : "Idadi ya vituo vya kutolea huduma za afya",
    schoolsHeaderLabel: language ? "Number of schools" : "Idadi ya shule",
    hudumazaafya: language? 'Health serevices': 'Huduma za afya',
    shule: language? 'Schools': 'Shule',
    language,
  };
};

const CommunityServicesCardsHealth = ({ labelBackground }) => {
  const words = useTranslations();
  return (
    <div className="flex flex-col lg:flex-row justify-between w-full gap-20 items-center bg-[whitesmoke] rounded-lg">
      <Card
        label="Tanzania"
        children={[
          { image: CensusZahanati, count: "7,965", label: words.clinic },
          { image: CensusKituoAfya, count: "1,466", label: words.healthCenter },
          { image: CensusHospitali, count: "676", label: words.hospital },
        ].map((item, index) => (
          <HealthCardContainer key={index}>
            <HealthCardLabel label={item.label} />
            <CardRow
              Icon={
                <HealthCardImage
                  image={item.image}
                  biggerImage={item.biggerImage}
                />
              }
              value={{ count: item.count }}
            />
          </HealthCardContainer>
        ))}
      />

      <Card
        label={words.tanzaniaMainland}
        children={[
          {
            image: CensusZahanati,
            count: "7,680                      ",
            label: words.clinic,
          },
          {
            image: CensusKituoAfya,
            count: "1,430                      ",
            label: words.healthCenter,
          },
          {
            image: CensusHospitali,
            count: "662                      ",
            label: words.hospital,
          },
        ].map((item, index) => (
          <HealthCardContainer key={index}>
            <HealthCardLabel label={item.label} />
            <CardRow
              Icon={
                <HealthCardImage
                  image={item.image}
                  biggerImage={item.biggerImage}
                />
              }
              value={{ count: item.count }}
            />
          </HealthCardContainer>
        ))}
      />

      <Card
        label="Tanzania Zanzibar"
        children={[
          {
            image: CensusZahanati,
            count: "285                      ",
            label: words.clinic,
          },
          {
            image: CensusKituoAfya,
            count: "36                    ",
            label: words.healthCenter,
          },
          {
            image: CensusHospitali,
            count: "14                      ",
            label: words.hospital,
          },
        ].map((item, index) => (
          <HealthCardContainer key={index}>
            <HealthCardLabel label={item.label} />
            <CardRow
              Icon={
                <HealthCardImage
                  image={item.image}
                  biggerImage={item.biggerImage}
                />
              }
              value={{ count: item.count }}
            />
          </HealthCardContainer>
        ))}
      />
    </div>
  );
};

const CommunityServicesCardsSchools = ({ labelBackground }) => {
  const words = useTranslations();
  return (
    <div className="flex flex-col lg:flex-row justify-between w-full gap-20 items-center bg-[whitesmoke] rounded-lg">
      <Card
        label="Tanzania"
        children={[
          {
            image: CensusPrimarySchools,
            count: "19,769",
            label: words.primarySchool,
          },
          {
            image: CensusSecondarySchools,
            count: "5,857",
            label: words.secondarySchool,
          },
        ].map((item, index) => (
          <SchoolCardContainer key={index}>
            <SchoolCardLabel label={item.label} />
            <CardRow
              Icon={
                <SchoolCardImage
                  image={item.image}
                  biggerImage={item.biggerImage}
                />
              }
              value={{ count: item.count }}
            />
          </SchoolCardContainer>
        ))}
      />

      <Card
        label={words.tanzaniaMainland}
        children={[
          {
            image: CensusPrimarySchools,
            count: "19,266                      ",
            label: words.primarySchool,
          },
          {
            image: CensusSecondarySchools,
            count: "5,592                      ",
            label: words.secondarySchool,
          },
        ].map((item, index) => (
          <SchoolCardContainer key={index}>
            <SchoolCardLabel label={item.label} />
            <CardRow
              Icon={
                <SchoolCardImage
                  image={item.image}
                  biggerImage={item.biggerImage}
                />
              }
              value={{ count: item.count }}
            />
          </SchoolCardContainer>
        ))}
      />

      <Card
        label="Tanzania Zanzibar"
        children={[
          {
            image: CensusPrimarySchools,
            count: "503",
            label: words.primarySchool,
          },
          {
            image: CensusSecondarySchools,
            count: "265",
            label: words.secondarySchool,
          },
        ].map((item, index) => (
          <SchoolCardContainer key={index}>
            <SchoolCardLabel label={item.label} />
            <CardRow
              Icon={
                <SchoolCardImage
                  image={item.image}
                  biggerImage={item.biggerImage}
                />
              }
              value={{ count: item.count }}
            />
          </SchoolCardContainer>
        ))}
      />
    </div>
  );
};
