import React, { useContext } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { DashboardContext } from "states/DashboardContext";

export default function CensusPyramid(props) {
  // Age categories
  var categories = [
    "0-4",
    "5-9",
    "10-14",
    "15-19",
    "20-24",
    "25-29",
    "30-34",
    "35-39",
    "40-44",
    "45-49",
    "50-54",
    "55-59",
    "60-64",
    "65-69",
    "70-74",
    "75-79",
    "80+",
  ];
  const words = useTranslations();
  const options = {
    chart: {
      type: "bar",
      height: "500px",
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      categories: categories,
      reversed: false,
      labels: {
        step: 1,
      },
    },

    yAxis: {
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
    },

    plotOptions: {
      series: {
        stacking: "normal",
      },
    },

    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          this.series.name +
          `, ${words.age} ` +
          this.point.category +
          "</b><br/>" +
          `${words.population}: ` +
          Highcharts.numberFormat(Math.abs(this.point.y), 0, "", ",")
        );
      },
    },

    series: [
      {
        name: `${words.male}`,
        color: "#077592",
        data: props.maleValue.map(function (number) {
          return number === null ? null : -number;
        }),
        connectNulls: true,
        dataLabels: {
          enabled: true,
          inside: false,
          formatter: function () {
            return Highcharts.numberFormat(-this.y, 0, "", ",");
          },
          style: {
            color: "black",
          },
        },
      },
      {
        name: `${words.female}`,
        color: "#FF3C94",
        data: props.femaleValue.map(function (number) {
          return number === null ? null : number;
        }),
        connectNulls: true,
        dataLabels: {
          enabled: true,
          inside: false,
          formatter: function () {
            return Highcharts.numberFormat(this.y, 0, "", ",");
          },
          style: {
            color: "black",
          },
        },
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    population: language ? "Population" : "Idadi ya watu",
    male: language ? "Male" : "Wanaume",
    female: language ? "Female" : "Wanawake",
    age: language ? "Age" : "Umri",
    language,
  };
};
