import React, { useContext } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "react-spring";
import { DashboardContext } from "../../../states/DashboardContext";
import CensusGuideVideo from "./CensusGuideVideo";

// Define Fade options
const Fade = React.forwardRef(function Fade(props, ref) {
  // Unwrap props
  const { in: open, children, onEnter, onExited, ...other } = props;

  // Define Custom styling
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  minHheight: "70%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

// Define visual comonent
export default function CensusGuideModal() {
  const { censusGuideModalState } = useContext(DashboardContext);
  const [censusGuideModal, setCensusGuideModal] = censusGuideModalState;
  const handleClose = () => setCensusGuideModal(false);
  const words = useTranslations()

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={censusGuideModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={censusGuideModal}>
          <Box sx={style}>
            <div className="flex flex-col w-full h-full">
              <div className="flex flex-col basis-2/3">
                <div className="basis-1/2">
                  <Typography
                    id="spring-modal-title"
                    variant="h6"
                    component="div"
                    sx={{
                      textAlign: "center",
                      fontWeight: 'bold',
                      fontSize: {
                        lg: 20,
                        md: 22,
                        sm: 16,
                        xs: 14,
                      },
                    }}
                  >
                    {words.title}
                  </Typography>

                  <div className="w-full mt-2 rounded-xl aspect-video basis-1/2 overflow-clip">
                    <CensusGuideVideo />
                  </div>
                </div>
              </div>
              <div className="basis-1/3">
                <Typography
                  id="spring-modal-description"
                  sx={{
                    mt: 2,
                    textAlign: "center",
                    fontSize: {
                      lg: 16,
                      md: 14,
                      sm: 12,
                      xs: 10,
                    },
                  }}
                >
                  {words.info}
                </Typography>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    info: language ? `Is the tool which aim to disseminate Census data in Tanzania
    from 1967 to very recent 2022. The tool contains Dashboards ,
    Visualizations, Profile of 2022 result and Media information
    published about 2022 Census implementation`
      : `Ni mfumo wenye lengo la kusambaza taarifa za sensa za nchi ya Tanzania kuazia 1967 hadi hivi karibuni 2022.
    Mfumo huu unajumuisha Dashibodi, Taswira, Wasifu wa matokeo ya 2022 na Habari kuhusu utendaji wa sensa mwaka 2022.`,
    title: language ? 'Census Information Dissermiantion platform' : 'Mfumo wa kusambaza taarifa za Sensa',
    language
  }
}
