import { Typography } from "@mui/material";
import React from "react";
import { useContext, useState } from "react";
import { DashboardContext } from "states/DashboardContext";
import CensusPyramid from "ui/elements/census/CensusPyramid";
import CensusPopulationDensityViz from "./components/CensusPopulationDensityViz";
import CensusHouseHoldViz from "./components/CensusHouseHoldViz";
import CensusSexRatioVisual from "./components/CensusSexRatioVisual";
import CensusVisualDropDown from "./components/CensusVisualDropDown";
import CensusVisualPopulationSize from "./components/CensusVisualPopulationSize";
import CensusGrowthRateViz from "./components/CensusGrowthRateViz";
import CensusLifeExpectancyViz from "./components/CensusLifeExpectanyViz";
import CensusLoadingCmp from "ui/elements/census/CensusLoadingCmp";
import { useEffect } from "react";
import CensusService from "_services/Census/CensusService";

// Cesnsus service
const censusService = CensusService();

export default function CensusDemographyVisual() { 
  const {
    censusVizDataState,
    censusAgeGroupState,
    loadingState,
    censusInitialLoadVizState,
  } = useContext(DashboardContext);
  const [censusVizData, setCensusDataViz] = censusVizDataState;
  const [censusAgeGroup, setCensusAgeGroup] = censusAgeGroupState;
  const [loading, setLoading] = loadingState;
  const [censusInitialLoadViz, setCensusInitialLoadViz] =
    censusInitialLoadVizState;
  const words = useTranslations();
  const [data2012, setData2012] = useState({});
  const [data2022, setData2022] = useState({});
  const [dataAge2012, setDataAge2012] = useState({});
  const [dataAge2022, setDataAge2022] = useState({});

  // use effect to bring default viz data
  useEffect(() => {
    const fetchCensusVizNational = async () => {
      setLoading(true);
      // Viz
      if (censusInitialLoadViz === true) {
        const data = await censusService.getViz("TZ", "None");
        setCensusDataViz((prev) => {
          prev = data;
          return prev;
        });

        const age = await censusService.getAgegroup("TZ", "None");
        setCensusAgeGroup((prev) => {
          prev = age;
          return prev;
        });

        setCensusInitialLoadViz(false);
      }

      setLoading(false);
    };

    fetchCensusVizNational();
  }, []);
  // console.log(censusVizData);
  // filter the census viz data to 2012 and 2022
  useEffect(() => {
    let data2012 = censusVizData.find((item) => item.time_value === 2012) ?? {};
    let data2022 = censusVizData.find((item) => item.time_value === 2022) ?? {};
    setData2012(data2012);
    setData2022(data2022);

    let age2012 = censusAgeGroup.find((item) => item.time_value === 2012) ?? {};
    let age2022 = censusAgeGroup.find((item) => item.time_value === 2022) ?? {};
    setDataAge2012(age2012);
    setDataAge2022(age2022);

  }, [censusAgeGroup]);

  function maleAge(ages) {
    let values = [
      ages["male_04"],
      ages["male_59"],
      ages["male_1014"],
      ages["male_1519"],
      ages["male_2024"],
      ages["male_2529"],
      ages["male_3034"],
      ages["male_3539"],
      ages["male_4044"],
      ages["male_4549"],
      ages["male_5054"],
      ages["male_5559"],
      ages["male_6064"],
      ages["male_6569"],
      ages["male_7074"],
      ages["male_7579"],
      ages["male_80p"],
    ];

    for (let i = 0; i < values.length; i++) {
      if (typeof values[i] === "undefined") {
        values[i] = null;
      }
    }

    return values;
  }

  function femaleAge(ages) {
    let values = [
      ages["female_04"],
      ages["female_59"],
      ages["female_1014"],
      ages["female_1519"],
      ages["female_2024"],
      ages["female_2529"],
      ages["female_3034"],
      ages["female_3539"],
      ages["female_4044"],
      ages["female_4549"],
      ages["female_5054"],
      ages["female_5559"],
      ages["female_6064"],
      ages["female_6569"],
      ages["female_7074"],
      ages["female_7579"],
      ages["female_80p"],
    ];

    for (let i = 0; i < values.length; i++) {
      if (typeof values[i] === "undefined") {
        values[i] = null;
      }
    }
    return values;
  }
  const [yourData, setYourData] = useState(censusVizData);

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="">
        <CensusVisualDropDown />
      </div>
      {loading === true ? (
        <CensusLoadingCmp />
      ) : (
        <div className="p-2 relative flex flex-col gap-2 w-full">
          <div className="flex md:flex-row flex-col justify-between gap-2">
            <div className="basis-1/3 bg-white rounded-xl shadow-xl">
              <CensusVisualPopulationSize
              population = {words.populationSize}
                total={{
                  2012: data2012?.["total"] ?? null,
                  2022: data2022?.["total"] ?? null,
                }}
                male={{
                  2012: data2012?.["male"] ?? null,
                  2022: data2022?.["male"] ?? null,
                }}
                female={{
                  2012: data2012?.["female"] ?? null,
                  2022: data2022?.["female"] ?? null,
                }}
              />
            </div>
            <div className="basis-1/3 bg-white rounded-xl shadow-xl ">
              <CensusSexRatioVisual
                sexratio={{
                  2012: data2012?.["sex_ratio"] ?? null,
                  2022: data2022?.["sex_ratio"] ??null,
                }}
              />
            </div>
            <div className="basis-1/3 bg-white rounded-xl shadow-xl ">
              <CensusHouseHoldViz
                totalhousehold={{
                  2012: data2012?.["total_household"] ?? null,
                  2022: data2022?.["total_household"] ?? null,
                }}
                averagehousehold={{
                  2012: data2012?.["average_household_size"] ?? null,
                  2022: data2022?.["average_household_size"] ?? null,
                }}
              />
            </div>
          </div>
          <div className="flex md:flex-row flex-col justify-between gap-2">
            <div className="basis-1/3 bg-white rounded-xl shadow-xl">
              <CensusGrowthRateViz
                averagegrowthrate={{
                  2012:
                    data2012?.["average_population_growth_rate"] ??
                    null,
                  2022:
                    data2022?.["average_population_growth_rate"] ??
                    null,
                }}
              />
            </div>
            <div className="basis-1/3 bg-white rounded-xl shadow-xl">
              <CensusLifeExpectancyViz
                lifexp={{
                  2012: data2012?.["life_expectancy_at_birth"] ?? null,
                  2022: data2022?.["life_expectancy_at_birth"] ?? null,
                }}
              />
            </div>
            <div className="basis-1/3 bg-white rounded-xl shadow-xl">
              <CensusPopulationDensityViz
                popdensity={{
                  2012: data2012?.["population_density"] ?? null,
                  2022: data2022?.["population_density"] ?? null,
                }}
              />
            </div>
          </div>
          <div className="flex flex-col justify-between gap-2 bg-white rounded-xl shadow-xl p-5 w-full">
            <Typography>{words.countLabel}</Typography>
            <div className="flex md:flex-row flex-col gap-2 ">
              <div className="basis-1/2 flex flex-col w-full justify-center items-center">
                <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
                  <Typography style={{ padding: "2px", fontSize: "bold" }}>
                    2012
                  </Typography>
                </div>
                <CensusPyramid
                  maleValue={maleAge(dataAge2012)}
                  femaleValue={femaleAge(dataAge2012)}
                  male={words.male}
                  female={words.female}
                />
              </div>
              <div className="basis-1/2 flex flex-col w-full justify-center items-center">
                <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
                  <Typography style={{ padding: "2px", fontSize: "bold" }}>
                    2022
                  </Typography>
                </div>
                <CensusPyramid
                  maleValue={maleAge(dataAge2022)}
                  femaleValue={femaleAge(dataAge2022)}
                  male={words.male}
                  female={words.female}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    male: language ? "Male" : "Wanaume",
    female: language ? "Female" : "Wanawake",
    urban: language ? "Urban" : "Mjini",
    rural: language ? "Rural" : "Vijijini",
    tanzaniaMainLand: language ? "Tanzania Mainland" : "Tanzania Bara",
    countLabel: language
      ? "Population Pyramid by Age Group and Sex"
      : "Piramidi ya Idadi ya Watu kwa Makundi ya umri na Jinsi",
    averagePopulationGrowthRate: language
      ? "Average population growth rate"
      : "Wastani wa Kiwango cha Ukuaji wa Idadi ya Watu",
    censusYear: language ? "Census Year" : "Mwaka wa sensa",
    lifeExpetancyAtBirth: language
      ? "Life epxectancy at Birth"
      : "Matarajio ya maisha wakati wa Kuzaliwa",
    numberOfHouseholds: language ? "Number of households" : "Idadi ya Kaya",
    populationSize: language ? "Population Size" : "Idadi ya watu",
    populationDensity: language ? "Population density" : "Msongamano wa Watu",
    populationProjection: language
      ? "Population Projection"
      : "Makadirio ya idadi ya watu",
    noData: language ? "No Data " : "Hakuna Taarifa",
    language,
  };
};
