import React from "react";
import Button from "@mui/material/Button";

export default function CencusButton(props) {
  return (
    <Button
      href={props.link ? props.link : ""}
      target="_blank"
      onClick={props.handleClick}
      variant="contained"
      size="small"
      sx={{
        color: props.active ? "white" : "#1985A1",
        backgroundColor: props.active ? "#0f7895" : "white",
        textTransform: "none",
        borderRadius: 0,
      }}
      className=" whitespace-nowrap hover:text-white"
      disabled={props.disabled}
    >
      {props.name}
    </Button>
  );
}
