import React from "react";
import ReactECharts from "echarts-for-react";
import { useContext } from "react";
import { DashboardContext } from "../../../states/DashboardContext";
import "echarts/lib/component/toolbox";

export default function CensusBarGraph() {
  // import global state
  const {
    censusIndicatorNameState,
    censusMapDataState,
    censusYearSliderValueState,
    censusSubGroupNameState,
    censusIsDefaultDataState,
    censusDefaultMapDataState,
    censusMapIsDrillDownState,
    censusMapDrillDownLevelState,
    censusMapDrillDownDataState,
  } = useContext(DashboardContext);
  const [censusIndicatorName] = censusIndicatorNameState;
  const [censusMapData, setCensusMapData] = censusMapDataState;
  const [censusYearSliderValue, setCensusYearSliderValue] =
    censusYearSliderValueState;
  const [censusSubGroupName, setCensusSubGroupName] = censusSubGroupNameState;
  const [censusIsDefaultData, setCensusIsDefaultData] =
    censusIsDefaultDataState;
  const [censusDefaultMapData, setCensusDefaultMapData] =
    censusDefaultMapDataState;
  const [censusMapIsDrillDown, setCensusMapIsDrillDown] =
    censusMapIsDrillDownState;
  const [censusMapDrillDownLevel, setCensusMapDrillDownLevel] =
    censusMapDrillDownLevelState;
  const [censusMapDrillDownData, setCensusMapDrillDownData] =
    censusMapDrillDownDataState;

  function graphData() {
    if (censusIsDefaultData === true) {
      // check if drill down
      if (censusMapIsDrillDown === true) {
        return censusDefaultMapData.filter(
          (e) =>
            parseInt(e.time_name.trim()) ===
              parseInt(censusYearSliderValue.trim()) &&
            e.area_level === censusMapDrillDownLevel &&
            parseInt(e.parent_code) === parseInt(censusMapDrillDownData)
        );
      } else {
        // if not drill down
        return censusDefaultMapData.filter(
          (e) =>
            parseInt(e.time_name.trim()) ===
              parseInt(censusYearSliderValue.trim()) && e.area_level === "LVL3"
        );
      }
    } else {
      if (censusMapIsDrillDown === true) {
        return censusMapData?.filter(
          (e) =>
            parseInt(e.time_name.trim()) ===
              parseInt(censusYearSliderValue.trim()) &&
            e.area_level === censusMapDrillDownLevel &&
            parseInt(e.parent_code) === parseInt(censusMapDrillDownData)
        );
      } else {
        return censusMapData?.filter(
          (e) =>
            parseInt(e.time_name.trim()) ===
              parseInt(censusYearSliderValue.trim()) && e.area_level === "LVL3"
        );
      }
    }
  }

  const options = {
    toolbox: {
      feature: {
        saveAsImage: {}, // Enable the "Save Image" option in the toolbox
      },
    },

    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "10%"];
      },
    },

    title: {
      left: "left",
      text: censusIsDefaultData
        ? `\t \t\t\t\t\t\t\t Population Size`
        : `\t \t\t\t\t\t\t\t ${censusIndicatorName} - ${censusSubGroupName}`,
      top: "top",
      textStyle: {
        color: "#000000",
        fontSize: 12,
      },
    },

    dataset: [
      {
        dimensions: ["area_name", "data_value", "area_code"],
        source: graphData(),
      },
      {
        transform: {
          type: "sort",
          config: { dimension: "area_code", order: "asc" },
        },
      },
    ],
    xAxis: {
      type: "category",
      axisLabel: { interval: 0, rotate: 45 },
    },
    yAxis: {},
    series: {
      type: "bar",
      encode: { x: "area_name", y: "data_value" },
      datasetIndex: 1,
      label: {
        show: censusMapIsDrillDown ? true : false,
        position: "top",
      },
    },
  };
  return <ReactECharts className="w-full h-full" option={options} />;
}
