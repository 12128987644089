import { Typography } from "@mui/material";
import React from "react";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Tooltip from "@mui/material/Tooltip";

export default function CensusReport(props) {
  return (
    <div className="w-[10%] h-[40vh] flex flex-col gap-2">
      <Tooltip title="Click to view report">
        <a href={props.url} target="_blank" rel="noreferrer">
        <img src={props.img} alt="img" className="shadow shadow-black"  />
      </a>
      </Tooltip>
      <div className="flex flex-row items-start">
        <Typography className="basis-4/5">{props.name}</Typography>
        <Tooltip title="Download Report">
          <IconButton
            className="basis-1/5"
            color="primary"
            aria-label="Download report"
            onClick={props.downloadPDF}
          >
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}
