import { useContext } from "react";
import censusTrendArrow from "resources/img/censusTrendArrow.png";
import censusFemaleLogo from "resources/img/censusFemale.png";
import censusMaleLogo from "resources/img/censusMale.png";
import { DashboardContext } from "states/DashboardContext";

function PopulationTrend() {
  const words = useTranslations();
  return (
    <div className="flex flex-col lg:flex-row w-full gap-4 font-bold bg-white shadow-xl rounded-xl overflow-clip">
      <div className="w-full lg:w-[50%] h-auto p-5 rounded-xl flex flex-col items-center space-y-10 py-10">
        <p className="w-full font-normal text-xl">{words.trend}</p>
        <div className="grow flex items-center space-x-2 justify-center">
          <div className="flex flex-row items-center gap-2">
            <div className="flex h-[50%] w-full items-center justify-center">
              <img src={censusTrendArrow} alt="" className="h-full" />
            </div>

            <div className="flex flex-col">
              <p className="text-3xl">3.2</p>
              {/* for urban and rural uncomment below code */}
              
              {/* <div className="flex flex-row gap-5">
                <div>
                  <p className="text-sm font-normal">{words.urban}</p>
                  <p className="text-xl">92</p>
                </div>
                <div>
                  <p className="text-sm font-normal">{words.rural}</p>
                  <p className="text-xl">96</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-[50%] p-5 rounded-xl">
        <div className="flex">
          <div className="w-[50%] flex flex-col items-center space-y-2">
            <p className="w-full text-center text-white bg-[#057693] rounded-l-lg px-3 py-2">
              {words.tanzaniaMainLand}
            </p>
            <div className="h-[300px]  md:py-[10%] lg:py-[10%] py-[30%]  w-full p-2 flex items-center justify-center">
              <div className="h-[80%] aspect-square bg-[#057693] rounded-full flex items-center justify-center">
                <div className="h-[90%] aspect-square bg-[#fff] rounded-full flex items-center justify-center">
                  <p className="text-2xl">3.2</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[50%] flex flex-col items-center space-y-2">
            <p className="w-full text-center text-white bg-[#057693] rounded-r-lg px-3 py-2">
              Tanzania Zanzibar
            </p>
            <div className="h-[300px] w-full md:py-[0%] lg:py-[0%] py-[20%] p-2 flex items-center justify-center">
              <div className="h-[80%] aspect-square bg-[#057693] rounded-full flex items-center justify-center">
                <div className="h-[90%] aspect-square bg-[#fff] rounded-full flex items-center justify-center">
                  <p className="text-2xl">3.7</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopulationTrend;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    trend: language
      ? "Population growth rate (2012-2022), Tanzania"
      : "Wastani wa kasi ya Ongezeko la idadi ya watu kwa mwaka (2012-2022), Tanzania",
    tanzaniaMainLand: language ? "Tanzania Mainland" : "Tanzania Bara",
    countLabel: language ? "Population in Tanzania" : "Idadi ya Watu, Tanzania",
    urban: language ? "Urban" : "Mjini",
    rural: language ? "Rural" : "Vijijini",
    language,
  };
};
