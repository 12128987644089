import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import { useContext } from "react";
import Papa from "papaparse";
import { DashboardContext } from "../../../states/DashboardContext";
import FileDownload from "js-file-download"; // Import the library for file download

export default function CensusVisualizationDataDownload({ data }) {
  const words = useTranslations();
  // console.log(data);
  const handleDownload = () => {
    const transformedData = [];
    const data2012 = data[0];
    const data2022 = data[1];

    transformedData.push({
      indicator_name: "Population Size",
      sub_group: "Total",
      2012: formatValue(data2012.total),
      2022: formatValue(data2022.total),
    });

    transformedData.push({
      indicator_name: "Sex Ratio",
      sub_group: "in Male per 100 Females",
      2012: formatValue(data2012.sex_ratio),
      2022: formatValue(data2022.sex_ratio),
    });

    transformedData.push({
      indicator_name: "Number of Households",
      sub_group: "Total",
      2012: formatValue(data2012.total_household),
      2022: formatValue(data2022.total_household),
    });

    transformedData.push({
      indicator_name: "Average Household Size",
      sub_group: "Total",
      2012: formatValue(data2012.average_household_size),
      2022: formatValue(data2022.average_household_size),
    });

    transformedData.push({
      indicator_name: "Population Growth Rate",
      sub_group: "Average",
      2012: formatValue(data2012.average_population_growth_rate),
      2022: formatValue(data2022.average_population_growth_rate),
    });

    transformedData.push({
      indicator_name: "Life Expectancy at Birth",
      sub_group: "in years",
      2012: formatValue(data2012.life_expectancy_at_birth),
      2022: formatValue(data2022.life_expectancy_at_birth),
    });

    transformedData.push({
      indicator_name: "Population Density",
      sub_group: "in Km2",
      2012: formatValue(data2012.population_density),
      2022: formatValue(data2022.population_density),
    });

    const csvHeader = ["indicator_name", "sub_group", "2012", "2022"];
    const csvData = [csvHeader];
    transformedData.forEach((item) => {
      const row = [
        item.indicator_name,
        item.sub_group,
        item["2012"],
        item["2022"],
      ];
      csvData.push(row);
    });

    const csv = Papa.unparse(csvData);
    FileDownload(csv, "Census Visualization Data.csv");
  };

  const formatValue = (value) => {
    if (value !== null && typeof value === "number") {
      // Format numbers with separators
      return value.toLocaleString();
    } else {
      return value !== null ? String(value) : "No Data";
    }
  };

  return (
    <div className="flex flex-row justify-between w-full h-full">
      <div className="flex items-center">
        <div className="pt-1">
          <Button
            onClick={handleDownload}
            variant="contained"
            startIcon={<DownloadIcon />}
            sx={{
              background: "#525252",
              marginRight: "0.5rem", // Adjust this value for the desired space
            }}
          >
            <p className="text-xs">{words.download}</p>
          </Button>
        </div>
      </div>
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    download: language ? "Download" : "Pakua",
    language,
  };
};
