import { Typography } from "@mui/material";
import React, { useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DashboardContext } from "states/DashboardContext";

export default function CensusSexRatioVisual(props) {
  const words = useTranslations();
  function roundedToFixed(input, digits) {
    var rounder = Math.pow(10, digits);
    return Math.round(input * rounder) / rounder;
  }
  const data2012 = [
    {
      name: `${words.male}`,
      y:
        roundedToFixed(
          (props?.sexratio["2012"] * 100) / (props.sexratio["2012"] + 100),
          1
        ) ?? "",
    },
    {
      name: `${words.female}`,
      y: roundedToFixed((100 * 100) / (props?.sexratio["2012"] + 100), 1) ?? "",
    },
  ];

  const options2012 = {
    chart: {
      type: "pie",
      height: "200px",
    },
    legend: {
      itemStyle: {
        fontSize: "14px",
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.1f}%",
          distance: -30,
          color: "white",
        },
        showInLegend: true,
        connectorWidth: 0,
        connectorPadding: 0,
        startAngle: 0,
        endAngle: 360,
        cursor: "pointer",
        borderWidth: 0,
        size: "80%",
        // innerSize: "70%",
        slicedOffset: 0,
        animation: false,
        point: {
          events: {
            legendItemClick: function () {
              return false; // Disallow legend item click
            },
          },
        },
      },
    },
    title: false,
    series: [
      {
        name: 2012,
        data: data2012,
        colors: ["#1985A1", "#FF3B95"],
      },
    ],
  };
  const data2022 = [
    {
      name: `${words.male}`,
      y:
        roundedToFixed(
          (props?.sexratio["2022"] * 100) / (props.sexratio["2022"] + 100),
          1
        ) ?? "",
    },
    {
      name: `${words.female}`,
      y: roundedToFixed((100 * 100) / (props?.sexratio["2022"] + 100), 1) ?? "",
    },
  ];

  const options2022 = {
    chart: {
      type: "pie",
      height: "200px",
    },
    title: false,
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.1f}%",
          distance: -30,
          color: "white",
        },
        showInLegend: true,
        connectorWidth: 0,
        connectorPadding: 0,
        startAngle: 0,
        endAngle: 360,
        cursor: "pointer",
        borderWidth: 0,
        size: "80%",
        slicedOffset: 0,
        animation: false,
        point: {
          events: {
            legendItemClick: function () {
              return false; // Disallow legend item click
            },
          },
        },
      },
    },
    series: [
      {
        name: 2022,
        data: data2022,
        colors: ["#1985A1", "#FF3B95"],
      },
    ],
  };

  return (
    <div className="flex flex-col w-full p-5">
      <Typography>
        {words.sexRatio} ({words.sexratiounit})
      </Typography>
      <div className="flex flex-col justify-around">
        <div className="flex flex-row justify-around w-full divide-x-2">
          <div className=" flex flex-col basis-1/2 items-center">
            <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
              <Typography style={{ padding: "2px" }}>2012</Typography>
            </div>
            {props.sexratio["2012"] ? (
              <Typography fontSize={40} fontWeight={"bold"}>
                {props.sexratio["2012"]}
              </Typography>
            ) : (
              <Typography fontSize={20}>{words.noData}</Typography>
            )}
          </div>
          <div className=" flex flex-col basis-1/2 items-center">
            <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
              <Typography style={{ padding: "2px" }}>2022</Typography>
            </div>
            {props.sexratio["2022"] ? (
              <Typography fontSize={40} fontWeight={"bold"}>
                {props.sexratio["2022"]}
              </Typography>
            ) : (
              <Typography fontSize={20}>{words.noData}</Typography>
            )}
          </div>
        </div>
        <Typography>{words.sexinpercentage}</Typography>
        <div className=" flex flex-row w-full divide-x-2">
          <div className="w-full basis-1/2">
            <HighchartsReact highcharts={Highcharts} options={options2012} />
          </div>
          <div className="w-full basis-1/2">
            <HighchartsReact highcharts={Highcharts} options={options2022} />
          </div>
        </div>
      </div>
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    total: language ? "Total" : "Jumla",
    sex: language ? "Sex" : "Jinsi",
    sexratiounit: language
      ? "Number of Males per 100 Females"
      : "Idadi ya wanaume kwa kila wanawake 100",
    male: language ? "Male" : "Wanaume",
    female: language ? "Female" : "Wanawake",
    sexRatio: language ? "Sex Ratio" : "Uwiano wa Kijinsi",
    sexinpercentage: language
      ? "Sex Ratio (in Percentage)"
      : "Uwiano Kijinsi (katika Asilimia)",

    noData: language ? "No Data " : "Hakuna Taarifa",
    language,
  };
};
