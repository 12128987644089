import React, { useContext, useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DashboardContext } from "states/DashboardContext";

const TableHeaderCell = ({ header }) => (
  <TableCell
    sx={{
      fontWeight: "bold",
      fontFamily: '"Poppins", sans-serif',
      color: "black",
    }}
  >
    {header}
  </TableCell>
);

const TableContentCell = ({ content, bold }) => (
  <TableCell
    sx={{
      fontWeight: bold ? "bold" : "normal",
      fontFamily: '"Poppins", sans-serif',
      justifyContent:"center"
    }}
  >
    {content}
  </TableCell>
);

export default function CensusTable() {
  const {
    languageState,
    censusDefaultMapDataState,
    censusMapIsDrillDownState,
    censusMapDrillDownAreaNameState,
    censusMapDrillDownDataState,
    censusMapDrillDownLevelState,
    censusIsDefaultDataState,
    censusYearSliderValueState,
    censusMapDataState,
    subgroupState,
    censusSelectedIndicatorState
  } = useContext(DashboardContext);

  const [language] = languageState;
  const [censusDefaultMapData] = censusDefaultMapDataState;
  const [censusMapIsDrillDown] = censusMapIsDrillDownState;
  const [censusMapDrillDownLevel] = censusMapDrillDownLevelState;
  const [censusMapDrillDownData] = censusMapDrillDownDataState;
  const [censusIsDefaultData] = censusIsDefaultDataState;
  const [censusYearSliderValue] = censusYearSliderValueState;
  const [censusMapData] = censusMapDataState;
  const [censusSelectedIndicator] = censusSelectedIndicatorState;

  const [searchText, setSearchText] = useState("");
  const handleSearchTextChange = (e) => setSearchText(e.target.value);
  const [filteredRows, setFilteredRows] = useState([]);
  const [subgroup, setSubgroup] = subgroupState

  useEffect(() => {
    let data = censusIsDefaultData ? censusDefaultMapData : censusMapData;

    if (censusMapIsDrillDown) {
      data = data.filter(
        (row) =>
          parseInt(row.time_name) === parseInt(censusYearSliderValue.trim()) &&
          row.area_level === censusMapDrillDownLevel &&
          parseInt(row.parent_code) === parseInt(censusMapDrillDownData)
      );
    } else {
      data = data.filter(
        (row) =>
          parseInt(row.time_name) === parseInt(censusYearSliderValue.trim()) &&
          ["LVL1", "LVL2", "LVL3"].includes(row.area_level)
      );
    }

    if (searchText !== "") {
      data = data.filter((row) =>
        row.area_name
          .toLowerCase()
          .split(" ")
          .join("")
          .includes(searchText.toLowerCase().split(" ").join(""))
      );
    }

    // Filter out unique area codes
    const uniqueAreas = [
      ...new Map(
      data.map(v => [JSON.stringify({ area_code: v.area_code, area_name: v.area_name, area_level: v.area_level }), { area_code: v.area_code, area_name: v.area_name,  area_level: v.area_level }])
      ).values()
    ];
    // Map data to unique area codes
    const mappedData = uniqueAreas.map(area => {
      const areaData = {
        area_code: area.area_code,
        area_name: area.area_name,
        area_level: area.area_level,
        indicators: censusIsDefaultData===true?
       [{
        indicatorkey: 1, subgroupkey: [1]
       }].flatMap(indicator => 
          indicator.subgroupkey.map(subgroup => {
            const indicatorData = data.find(
              row =>
                row.area_code === area.area_code
            );
            return {
              indicatorKey: indicator.indicatorkey,
              subgroupKey: subgroup,
              value: indicatorData ? indicatorData.data_value : null,
              indicatorName: indicatorData ? indicatorData.indicator_name : "",
              subgroupName: indicatorData ? indicatorData.subgroup_name  : ""
            };
          })
        )
        :
        censusSelectedIndicator.flatMap(indicator => 
          indicator.subgroupkey.map(subgroup => {
            const indicatorData = data.find(
              row =>
                row.area_code === area.area_code &&
                parseInt(row.indicatorkey) === indicator.indicatorkey &&
                row.subgroupkey === subgroup
            );
            return {
              indicatorKey: indicator.indicatorkey,
              subgroupKey: subgroup,
              value: indicatorData ? indicatorData.data_value : null,
              indicatorName: indicatorData ? indicatorData.indicator_name : "",
              subgroupName: indicatorData ? indicatorData.subgroup_name  : ""
            };
          })
        )
      };

      return areaData;
    });


    setFilteredRows(mappedData);
  }, [
    searchText,
    censusMapData,
    censusDefaultMapData,
    censusMapIsDrillDown,
    censusMapDrillDownLevel,
    censusYearSliderValue,
    censusSelectedIndicator
  ]);

  function numberWithCommas(x) {
    if (x){

      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else {
      return '-'
    }
  }

  return (
    <div className="flex w-full flex-col overflow-clip">
      <div className="px-3 w-full flex items-center justify-between gap-2 text-sm tracking-wide border border-[#C5C5C5] bg-[#F7F7F7]  outline-none">
        <BsSearch className="text-lg text-gray-500" />
        <input
          className="w-full h-full py-2 bg-transparent outline-none"
          placeholder={language === true ? "Search by name" : "Tafuta kwa jina"}
          value={searchText}
          onChange={handleSearchTextChange}
        />
      </div>
      <TableContainer component={Paper} elevation={0} style={{ height: "60vh" }}>
        <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
          <TableHead sx={{ position: "sticky", top: 0 }}>
            <TableRow sx={{ backgroundColor: '#cfe4ea'}}>
              <TableHeaderCell header={language === true ? "No." : "Na."} />
              <TableHeaderCell 
                header={
                  language === true
                    ? censusMapIsDrillDown !== true
                      ? "Regions"
                      : censusMapDrillDownLevel === "LVL5"
                      ? "Councils"
                      : censusMapDrillDownLevel === "LVL6"
                      ? "Constituencies"
                      : "Wards / Shehia"
                    : censusMapIsDrillDown !== true
                    ? "Mikoa"
                    : censusMapDrillDownLevel === "LVL5"
                    ? "Halmashauri"
                    : censusMapDrillDownLevel === "LVL6"
                    ? "Majimbo"
                    : "Kata / Shehia"
                }
              />
              {censusIsDefaultData===true?
              <TableHeaderCell
              header={'Population size (Total)'}
            />
              : censusSelectedIndicator.flatMap(indicator => 
                indicator.subgroupkey.map((subgroupkey, index) => {
                  const obj = subgroup.find(row =>
                    parseInt(indicator.indicatorkey) === row.indicatorkey &&
                    row.subgroupkey === subgroupkey
                  );
                  return (
                    <TableHeaderCell
                      key={`${indicator.indicatorkey}-${subgroupkey}-${index}`}
                      header={obj ? `${obj.indicator_name} (${obj.subgroup_name})` : 'N/A'}
                    />
                  );
                })
              )}
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflowY: "scroll" }}>
            {filteredRows.sort((a, b) => a.area_level > b.area_level ? 1 : -1).map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableContentCell content={index + 1} />
                <TableContentCell bold content={row.area_name} />
                {row.indicators.map((indicator, subIndex) => (
                  <TableContentCell
                    key={subIndex}
                    content={numberWithCommas(indicator.value)}
                  />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
