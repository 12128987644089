import React from "react";
import ReactPlayer from "react-player";

export default function CensusGuideVideo() {
  return (
    <ReactPlayer
      url="https://www.youtube.com/watch?v=F8oL3NE_KR0"
      className="react-player"
      playing
      width="100%"
      height="100%"
    />
  );
}
