import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useContext } from "react";
import { DashboardContext } from "../../../states/DashboardContext";

export default function CensusFloatingLegend() {
  // import global state
  const {
    censusMapDataState,
    censusYearSliderValueState,
    censusIsDefaultDataState,
    censusDefaultMapDataState,
    censusMapIsDrillDownState,
    censusMapDrillDownAreaNameState,
  } = useContext(DashboardContext);
  const [censusMapData, setCensusMapData] = censusMapDataState;
  const [censusYearSliderValue, setCensusYearSliderValue] =
    censusYearSliderValueState;
  const [censusIsDefaultData, setCensusIsDefaultData] =
    censusIsDefaultDataState;
  const [censusDefaultMapData, setCensusDefaultMapData] =
    censusDefaultMapDataState;
  const [censusMapIsDrillDown, setCensusMapIsDrillDown] =
    censusMapIsDrillDownState;
  const [censusMapDrillDownAreaName, setCensusMapDrillDownAreaName] =
    censusMapDrillDownAreaNameState;

  // local

  // add comma to numbers
  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // Get level data
  function getLevelData(level, area_code) {
      let data = censusIsDefaultData === true? censusDefaultMapData: censusMapData
      let value = data.find(
        (e) =>
          parseInt(e.time_name.trim()) ===
            parseInt(censusYearSliderValue.trim()) &&
          e.area_level === level &&
          e.area_code === area_code
      );
      if (value) {
        return numberWithCommas(value.data_value);
      } else return "No Data";
  }
  return (
    <div
      className="justify-center p-4"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <div style={{ flex: "1", padding: "4px", textAlign: "center" }}>
          <Typography fontSize={"small"} sx={{ fontWeight: "bold" }}>
            {censusMapIsDrillDown === false
              ? "Tanzania"
              : censusMapDrillDownAreaName[0]}
          </Typography>
          <Typography fontSize={"small"} align="center">
            {censusMapIsDrillDown === false
              ? getLevelData("LVL1", "TZ")
              : numberWithCommas(censusMapDrillDownAreaName[1])}
          </Typography>
        </div>
       
        <div
          style={{
            flex: "1",
            padding: "4px",
            textAlign: "center",
            display: censusMapIsDrillDown === false ? "block" : "none",
          }}
        >
          <Typography fontSize={"small"} sx={{ fontWeight: "bold" }}>
            Mainland
          </Typography>
          <Typography fontSize={"small"} align="center">
            {getLevelData("LVL2", "TZMAIN")}
          </Typography>
        </div>
       
        <div
          style={{
            flex: "1",
            padding: "4px",
            textAlign: "center",
            display: censusMapIsDrillDown === false ? "block" : "none",
          }}
        >
          <Typography fontSize={"small"} sx={{ fontWeight: "bold" }}>
            Zanzibar
          </Typography>
          <Typography fontSize={"small"} align="center">
            {getLevelData("LVL2", "TZ002")}
          </Typography>
        </div>
      </div>
    </div>
  );
}
