import { useContext } from "react";
import CensusBuidingWithAddressLogo from "resources/img/CensusBuidingWithAddress.png";
import CensusBuildingLogo from "resources/img/CensusBuilding.png";
import { DashboardContext } from "states/DashboardContext";
import CensusInfoCrds from "ui/components/census/CensusInfoCrds";

function ResidentialAddresses() {
  const words = useTranslations();
  return (
    <>
      <div className="flex flex-col w-full gap-4 p-4 font-bold bg-white shadow-xl rounded-xl">
        <p className="w-full border-b-2 border-b-[#E6E6E6] font-normal text-xl">
          {words.residentialAddress}, Tanzania
        </p>
        <div className="flex gap-4 flex-wrap px-[5%]">
          <div className="flex items-center space-x-2 w-full lg:w-[45%]">
            <img
              src={CensusBuidingWithAddressLogo}
              alt=""
              className="h-[80px]"
            />
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-normal">
                {words.majengoyenyeanwani}
              </p>
              <p className="text-2xl font-bold">10,751,123</p>
            </div>
          </div>

          <div className="flex items-center space-x-2 w-full lg:w-[45%]">
            <img src={CensusBuildingLogo} alt="" className="h-[80px]" />
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-normal">
                {words.majengoyasionaanwani}
              </p>
              <p className="text-2xl font-bold">3,597,249</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full gap-4 p-4 bg-white shadow-xl rounded-xl">
        <p className="w-full border-b-2 border-b-[#E6E6E6] text-xl font-normal">
          {words.residentialAddress}
        </p>
        <CensusInfoCrds
          cardData={[
            {
              label: words.tanzaniaMainLand,
              data: [
                {
                  image: CensusBuidingWithAddressLogo,
                  count: "10,406,722                  ",
                  label: `${words.majengoyenyeanwani}`,
                },
                {
                  image: CensusBuildingLogo,
                  count: "3,501,229                  ",
                  label: `${words.majengoyasionaanwani}`,
                },
              ],
            },
            {
              label: "Tanzania Zanzibar",
              data: [
                {
                  image: CensusBuidingWithAddressLogo,
                  count: "344,401                  ",
                  label: `${words.majengoyenyeanwani}`,
                },
                {
                  image: CensusBuildingLogo,
                  count: "96,020                  ",
                  label: `${words.majengoyasionaanwani}`,
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
}

export default ResidentialAddresses;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    residentialAddress: language ? "Residential Address" : "Anuani za makazi",
    tanzaniaMainLand: language ? "Tanzania MainLand" : "Tanzania Bara",
    countLabel: language ? "Population in Tanzania" : "Idadi ya Watu, Tanzania",
    majengoyenyeanwani: language ?  "Number of Building with physical Address":"Idadi ya Majengo yenye anwani za makazi" ,
    majengoyasionaanwani: language ? "Number of Building without physical Address": "Idadi ya Majengo yasiyo na anwani za makazi",
    language,
  };
};
