import { Typography } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import CensusFilterPane from "../../components/census/CensusFilterPane";
import CensusDataDownloadPane from "../../components/census/CensusDataDownloadPane";
import CencusButton from "../../elements/census/CencusButton";
import YearSlider from "../../elements/census/YearSlider";
import { DashboardContext } from "../../../states/DashboardContext";
import CensusBarChartWithLegend from "../../components/census/CensusBarChartWithLegend";
import CensusMapWithLegend from "../../components/census/CensusMapWithLegend";
import CensusTableWithLegend from "ui/components/census/CensusTableWithLegend";
import CensusFloatingLegend from "../../elements/census/CensusFloatingLegend";
import Divider from "@mui/material/Divider";
import CensusDropDownGroup from "../../elements/census/CensusDropDownGroup";
import CensusTable from "ui/elements/census/CensusTable";
import CensusLoadingCmp from "ui/elements/census/CensusLoadingCmp";

export default function CensusMapView() {
  const words = useTranslations();
  const {
    showCensusMapState,
    censusYearSliderValueState,
    censusSelectedIndicatorState,
    loadingState
  } = useContext(DashboardContext);
  const [showCensusMap, setCensusShowMap] = showCensusMapState;
  const [censusYearSliderValue, setCensusYearSliderValue] =
    censusYearSliderValueState;
  const [censusSelectedIndicator, setCensusSelectedIndicator] = censusSelectedIndicatorState
  const [loading, setLoading] = loadingState
  const [selectedButton, setSelectedButton] = useState(0);
  const [isAllVisual, setIsAllVisual] = useState(true)
  const handleSelectedButtonChange = (index) => setSelectedButton(index);
  const visualSelection = ["Map", "Bar Chart", "Table"];

  // Function to handle year slider value change
  function handleChange(value) {
    setCensusYearSliderValue(value);
  }


  useEffect(() => {
    // Check the length of selectedSubgroups array and set selectedButton accordingly
    if (censusSelectedIndicator.length!==0  ) {
      if(censusSelectedIndicator.length>1){
        
        setSelectedButton(2);
        setIsAllVisual(false)
      }
      else if (censusSelectedIndicator[0].subgroupkey.length>1){
        setSelectedButton(2);
        setIsAllVisual(false)
      }
      else {
        setIsAllVisual(true)
      }
    }
  }, [censusSelectedIndicator]);
  return (
    <main
      id="map-view"
      className="flex flex-col bg-gray-100 md:h-screen md:flex-row"
    >
      {/* Visualization Pane */}
      <div className=" w-full p-6 md:basis-4/4">
        <div className=" w-full flex flex-col items-center justify-around gap-4">
          {/* Visulization Controls */}
          <div className="flex flex-col gap-2 w-full h-full rounded-lg md:basis-1/5">
            <div className=" md:basis-1/3 items-center justify-center w-full h-full bg-white">
              <Typography color={"#1985A1"}>
                {/* Filter Control */}
                <div className="flex flex-col justify-around w-full h-full">
                  {/* Drop Downs */}
                  <div className="w-full h-[50%] max-h-[40vh] overflow-auto border rounded-md border-[#333333]">
                    <CensusDropDownGroup />
                  </div>
                  {/* Data source */}
                </div>
              </Typography>
            </div>
            <div className="w-full h-full md:basis-1/3">
              <YearSlider handleChange={handleChange} />
            </div>
            <div className="flex items-center gap-2">
              {visualSelection.map((data, index) => {
                return (
                  <CencusButton
                    key={index}
                    name={data}
                    handleClick={() => handleSelectedButtonChange(index)}
                    active={selectedButton === index}
                    disabled={['Map', 'Bar Chart'].includes(data) ? !isAllVisual : false}
                  />
                );
              })}
            </div>
          </div>

          {/* Map & Bar Visualization */}

          <div className=" items-center justify-center w-full h-full bg-white rounded-lg shadow-md md:basis-5/5 ">
            <div style={{  display: selectedButton !== 2 ? "block" : "none" }}>
              <CensusFloatingLegend />
            </div>
            <Divider />
            <div
              className="flex w-full lg:flex-row flex-col justify-center p-4"
              style={{ display: selectedButton === 0 ? "block" : "none" }}
            >
              <CensusMapWithLegend />
            </div>

            <div
              className="flex w-full lg:flex-row flex-col justify-center p-4"
              style={{ display: selectedButton === 1 ? "block" : "none" }}
            >
             {loading? <CensusLoadingCmp />: <CensusBarChartWithLegend /> }
            </div>

            <div
              className="flex w-full lg:flex-row flex-col justify-center bg-transparent p-4"
              style={{ display: selectedButton === 2 ? "block" : "none" }}
            >
              {loading? <CensusLoadingCmp />:  <CensusTable />  }
            </div>
          </div>
          <div className=" w-full flex flex-col items-center justify-around py-2 gap-4">
          {/* Visulization Controls */}
          <div className="flex flex-col w-full h-full rounded-lg md:basis-1/5 ">
            <div className=" md:basis-1/3 items-center justify-center w-full h-full bg-white p-1">
              <Typography color={"#1985A1"}>
                {/* Filter Control */}
                <CensusDataDownloadPane />
              </Typography>
            </div>
          </div>
        </div>
        </div>
        
      </div>
    </main>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    dashboard: language ? "Dashboard" : "Dashibodi",
    bar: language ? "Bar" : "Grafu ya bar",
    map: language ? "Map" : "Ramani",
    table: language ? "Table" : "Jedwari",
    language,
  };
};
