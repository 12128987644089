import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DashboardContext } from "states/DashboardContext";
import { useEffect, useContext, useState } from "react";
import CensusService from "_services/Census/CensusService";
import CensusLoadingCmp from "ui/elements/census/CensusLoadingCmp";
import axios from "axios";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import { environment } from "environment";

export default function PopulationSpecificGroups() {
  const { languageState, censusSpecialGroupState } =
    useContext(DashboardContext);
  const [language] = languageState;
  const [censusSpecialGroup, setCensusSpecialGroup] = useState([]);
  //
  const { loading, data, subGroupsMap } = useAppHook();

  //


  if (loading)
    return (
      <div>
        <CensusLoadingCmp />
      </div>
    );

  function exportToExcel(data) {
    const columns = [
      "Group Name",
      "Total (Tanzania)",
      "Rural (Tanzania)",
      "Urban (Tanzania Mainland)",
      "Total (Tanzania Mainland)",
      "Rural (Tanzania Mainland)",
      "Urban (Tanzania Mainland)",
      "Total (Zanzibar)",
      "Rural (Zanzibar)",
      "Urban (Zanzibar)",
    ];

    const excelData = [columns, ...data];

    const worksheet = XLSX.utils.aoa_to_sheet(excelData); // Use aoa_to_sheet for array of arrays
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Population_Data");
    XLSX.writeFile(workbook, "population_data.xlsx");
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <h2></h2>
        <Button
          onClick={() => exportToExcel(data)}
          variant="contained"
          startIcon={<DownloadIcon />}
          sx={{
            background: "#525252",
          }}
        >
          Download
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="spanning table" size="small">
          <TableHead sx={{ background: "#057693" }}>
            <TableRow>
              <TableCell align="left" sx={{ color: "white" }}>
                Population Group
              </TableCell>
              <TableCell align="center" colSpan={3} sx={{ color: "white" }}>
                Tanzania
              </TableCell>
              <TableCell align="center" colSpan={3} sx={{ color: "white" }}>
                Mainland
              </TableCell>
              <TableCell align="center" colSpan={3} sx={{ color: "white" }}>
                Zanzibar
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell></TableCell>

              <TableCell align="center" sx={{ color: "white" }}>
                Total
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Rural
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Urban
              </TableCell>

              <TableCell align="center" sx={{ color: "white" }}>
                Total
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Rural
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Urban
              </TableCell>

              <TableCell align="center" sx={{ color: "white" }}>
                Total
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Rural
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Urban
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((dt, index) => {
              if (dt.length === 1) {
                return (
                  <TableRow>
                    <p>{dt}</p>
                  </TableRow>
                );
              }
              return (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor:
                      dt[0].includes("Male") || dt[0].includes("Female")
                        ? undefined
                        : "#e5e7eb",
                  }}
                >
                  {dt.map((val, valIndex) => {
                    const firstCol =
                      valIndex === 0
                        ? subGroupsMap[val] ??
                          ((value) => {
                            if (value.includes("Male_")) return "Male";
                            if (value.includes("Female_")) return "Female";
                            return value;
                          })(val)
                        : val;
                       
                    return <TableCell key={valIndex}>{firstCol !== '0' ? firstCol : "No Data"}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function useAppHook() {
  const subGroups = [
    "0",
    "0-4",
    "0-8",
    "0-14",
    "0-17",
    "13-19",
    "15-24",
    "15-35",
    "6-12",
    "7-13",
    "14-17",
    "15-64",
  ];

  const subGroupsMap = {
    "0": "Children (Under 1 year)", 
    "0-4": "Children (0–4 years)",
    "0-8": "Children (0–8 years)",
    "0-14": "Young Population (0–14 years)",
    "0-17": "Young Population (0–17 years)",
    "13-19": "Teenagers (13–19 years)",
    "15-24": "Youth Population (15–24 years)1",
    "15-35": "Youth Population (15–35 years)2",
    "6-12": "Primary School (6–12 years)3",
    "7-13": "Primary School (7–13 years)4",
    "14-17": "Secondary School (14-17 years)5",
    "15-64": "Working Age Population (15-64 years)",
  };

  const url = `${environment.base_url}census/specialgroup/`;

  const [loading, setLoading] = useState(false);
  const [groupsData, setgroupsData] = useState(undefined);

  const [data, setData] = useState([]);
  
  const censusService = CensusService();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const data = await censusService.getSpecialGroup();
      setgroupsData(data);
      setLoading(false);
    }

    getData();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setLoading(true);
  //       const response = await axios.get(url);
  //       setgroupsData(response.data);
  //     } catch (error) {
  //       throw error;
  //     } finally {
  //       setLoading(false);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (groupsData) {
      const allData = [];
      const groupings = ["", "Male_", "Female_"];
      subGroups.forEach((group) => {
        groupings.forEach((grouping) => {
          const groupName = `${grouping}${group}`;

          const _dt = [groupName];
          const locations = ["TZ", "TZMAIN", "TZ002"];
          locations.forEach((location) => {
            const locationTotal =
              groupsData.find(
                (dt) =>
                  dt.subgroup_name === groupName && dt.area_code === location
              )?.data_value ?? null;
            const locationRural =
              groupsData.find(
                (dt) =>
                  dt.subgroup_name === `Rural_${groupName}` &&
                  dt.area_code === location
              )?.data_value ?? null;
            const locationUrban =
              groupsData.find(
                (dt) =>
                  dt.subgroup_name === `Urban_${groupName}` &&
                  dt.area_code === location
              )?.data_value ?? null;

            _dt.push(
              ...[locationTotal, locationRural, locationUrban].map((num) =>
                new Intl.NumberFormat().format(num)
              )
            );
          });

          allData.push(_dt);
        });
      });
      console.log("allData", allData);
      setData(allData);
    }
  }, [groupsData]);

  return { loading, data, subGroupsMap };
}
