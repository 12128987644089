import React from "react";
import { Audio } from "react-loader-spinner";
export default function CensusLoadingCmp() {
  return (
    <div className="flex items-center justify-center w-full h-screen">
      <Audio
        color="#00BFFF"
        height={40}
        width={40}
        timeout={20000} // 20 secs
      />
    </div>
  );
}
