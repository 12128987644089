import { ExpandLess, ExpandMore } from "@mui/icons-material";
import clsx from "clsx";
import { useContext, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { DashboardContext } from "states/DashboardContext";
import CensusLandingNavbar from "ui/components/census/CensusLandingNavbar";

const styles = {
  backgroundGradient: {
    background: `      
        linear-gradient(45deg, #fff 30%, transparent 70%), 
        linear-gradient(-45deg, #fff 30%, transparent 70%), 
        linear-gradient(-45deg, #61A1F1 30%, transparent 70%), 
        linear-gradient(35deg, #6CEB9A 30%, transparent 70%),         
        linear-gradient(185deg, #fff 30%, transparent 70%),         
        linear-gradient(-135deg, #6CEB9A 30%, transparent 70%), 
        linear-gradient(135deg, #fff 30%, transparent 70%), 
        linear-gradient(9deg, #fff 30%, transparent 70%),                        
        linear-gradient(-135deg, #fff 30%, transparent 70%)   `,
  },
};

const CensusDataSharing = () => {
  const [expandedSections, setExpandedSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);

  const handleSectionClick = (index) => {
    if (expandedSections.includes(index)) {
      setExpandedSections(expandedSections.filter((i) => i !== index));
    } else {
      setExpandedSections([...expandedSections, index]);
    }
    setSelectedSection(index);
    setSelectedSubsection(null);
  };

  const handleSubsectionClick = (sectionIndex, subIndex) => {
    setSelectedSection(sectionIndex);
    setSelectedSubsection(subIndex);
  };

  const words = useTranslations();

  const { pathname } = useLocation();

  return (
    <div className="min-h-screen " style={styles.backgroundGradient}>
      <CensusLandingNavbar />
      <div className="flex flex-col md:flex-row justify-center">
        <div className="w-full md:w-[80%] p-4">
          <div className="flex flex-col md:flex-row">
            <aside className="w-full md:w-1/4 bg-gray-50 rounded-l-md">
              <div className="text-3xl bg-[#0f7895] p-4 text-white text-center font-semibold rounded-tl-md">
                {words.title}
              </div>

              <SharedNavLink
                path={"/data-sharing/introduction"}
                label={"Introduction"}
              />

              <SharedNavLink
                label={"Getting Started"}
                isParentActive={
                  pathname.includes("api-basics") ||
                  pathname.includes("indicators") ||
                  pathname.includes("sub-groups") ||
                  pathname.includes("sectors") ||
                  pathname.includes("area") ||
                  pathname.includes("indicator-values") ||
                  pathname.includes("sub-sectors")
                }
                subLinks={[
                  {
                    path: "/data-sharing/api-basics",
                    label: "Api Basics",
                  },
                  {
                    path: "/data-sharing/sectors",
                    label: "Sectors",
                  },
                  {
                    path: "/data-sharing/sub-sectors",
                    label: "Sub-Sectors",
                  },
                  {
                    path: "/data-sharing/indicators",
                    label: "Indicators",
                  },
                  {
                    path: "/data-sharing/sub-groups",
                    label: "Sub-Groups",
                  },
                  {
                    path: "/data-sharing/area",
                    label: "Area",
                  },
                  {
                    path: "/data-sharing/indicator-values",
                    label: "Indicator Values",
                  },
                ]}
              />
            </aside>
            <main className="w-full  bg-white md:w-3/4 p-4 overflow-auto">
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CensusDataSharing;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    title: language ? "Census API" : "API Ya Sensa",
    language,
  };
};

function SharedNavLink({ path, label, subLinks = [], isParentActive }) {
  const [opened, setOpened] = useState(false);
  const Icon = opened ? <ExpandLess /> : <ExpandMore />;
  const navigate = useNavigate();
  if (subLinks?.length)
    return (
      <div className="flex flex-col gap-0">
        <button
          onClick={() => setOpened((prev) => !prev)}
          className={clsx(
            "bg-gray-100 py-2 px-4 flex items-center justify-between gap-2 w-full border border-gray-200",
            isParentActive && "font-semibold"
          )}
        >
          <p
            style={{
              color: isParentActive ? "rgb(55 65 81)" : "rgb(56 63 75)",
            }}
          >
            {label}
          </p>
          {Icon}
        </button>

        <div
          style={{ display: opened ? "flex" : "none" }}
          className={clsx("flex-col hidden", opened === true && "flex")}
        >
          {subLinks.map((link) => (
            <NavLink
              key={link?.path}
              to={link?.path ?? "."}
              style={({ isActive }) => ({
                color: isActive ? "rgb(55 65 81)" : "rgb(156 163 175)",
              })}
              className={({ isActive }) =>
                clsx(
                  isActive && "font-bold bg-gray-200",
                  "py-2 hover:bg-gray-200 transition-all px-8"
                )
              }
            >
              {link?.label}
            </NavLink>
          ))}
        </div>
      </div>
    );
  return (
    <div
      onClick={() => navigate(path ?? ".")}
      className="w-full px-4 py-2 bg-gray-100 border border-gray-200 hover:bg-gray-200 cursor-pointer"
    >
      <NavLink
        to={path ?? "."}
        style={({ isActive }) => ({
          color: isActive ? "rgb(55 65 81)" : "rgb(156 163 175)",
        })}
        className={({ isActive }) =>
          clsx("transition-all", isActive && "font-bold")
        }
      >
        {label}
      </NavLink>
    </div>
  );
}
