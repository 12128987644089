import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export default function CensusCard(props) {
  return (
    <div className="flex w-full flex-row h-[20vh] items-center bg-white  shadow-md">
      <div className="basis-3/4 w-full h-full flex flex-col pl-4">
        <div className="basis-1/2 w-full h-full flex flex-col justify-center pt-1">
          <Typography align="start" gutterBottom>
            {props.name}
          </Typography>
          <div className="flex flex-row gap-2 items-center">
          {props.valueUnit && props.language===false && <Typography>{props.valueUnit}</Typography>}
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold" }}
              component="div"
            >
              {props.putComas ? numberWithCommas(props.value) : props.value}
            </Typography>
            {props.valueUnit && props.language===true && <Typography>{props.valueUnit}</Typography>}
          </div>
        </div>
        {props.populationCard && (
          <div className="basis-1/2 w-full h-full flex flex-row">
            <div className="basis-1/2 w-full h-full flex flex-row gap-2 justify-start items-center">
              <img src={props.iconFemale} alt="logo" className="h-10" />
              <Typography component="div" sx={{ fontWeight: "bold" }}>
                {numberWithCommas(props.femaleValue)}
              </Typography>
            </div>

            <div className="basis-1/2 w-full h-full flex flex-row gap-2 justify-start items-center">
              <img src={props.iconMale} alt="logo" className="h-10" />
              <Typography component="div" sx={{ fontWeight: "bold" }}>
                {numberWithCommas(props.maleValue)}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div className="basis-1/4 flex justify-cent">
        <img src={props.icon} className="h-12" alt="logo" />
      </div>
    </div>
  );
}
