import ReactECharts from "echarts-for-react";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "states/DashboardContext";

function ZahanatiKwaMikoa() {
  const hook = useZahanatiKwaMikoa();
  const words = useTranslations();

  const options = {
    title: {
      text: `${words.chartTitle}`,
      textStyle: {
        fontSize: "20",
      },
    },
    maintainAspectRatio: false,
    grid: {
      containLabel: true,
      left: "9%",
      bottom: "0%",
      right: "5%",
      top: "15%",
    },
    tooltip: { trigger: "axis" },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar"] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    dataZoom: [
      { show: false },
      { type: "inside" },
      {
        show: false,
        yAxisIndex: 10,
        filterMode: "empty",
        width: 30,
        height: "70%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    xAxis: {
      type: "category",
      data: ((regions) => {
        const dt = regions.map((region) => {
          return region.name;
        });
        return dt;
      })(hook.regionData),
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 90,
        fontSize: "16",
        fontWeight: "bold",
      },
    },
    yAxis: [
      {
        type: "value",
        // name: words.chartYAxis,
        nameLocation: "middle",
        nameGap: 50,
        axisLabel: {
          formatter: "{value}",
          show: true,
          interval: 0,
          rotate: 0,
          fontSize: "16",
          fontWeight: "800",
        },
      },
    ],
    series: [
      {
        type: "bar",
        name: "",
        barMaxWidth: 20,
        data: ((regions) => {
          const regionCounts = regions.map((region) => region.value);
          const dt = regionCounts.map((count, index) => {
            return {
              value: count,
              itemStyle: {
                color: "#1985A1",
              },
            };
          });
          return dt;
        })(hook.regionData),
      },
    ],
  };
  return (
    <div className="h-full text-[1.5vw] flex items-center justify-center">
      <div className="relative flex flex-col items-center justify-center w-full h-full gap-2">
        {hook.regionData && (
          <ReactECharts
            style={{ height: "60vh", minHeight: 200, width: "100%" }}
            option={options}
          />
        )}
      </div>
    </div>
  );
}

export default ZahanatiKwaMikoa;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    chartTitle: language
      ? "Number of Dispensaries by region"
      : "Idadi ya Zahanati kwa Mikoa",
    chartYAxis: language ? "Number in 100,000" : "Idadi kwa 100,000",
    language,
  };
};

const useZahanatiKwaMikoa = () => {
  const censusYears = [1967, 1978, 1988, 2002, 2012, 2022];
  const lastIndex = censusYears.length - 1;
  const [selectedYearIndex, setSelectedYearIndex] = useState(lastIndex);
  const censusYear = censusYears[selectedYearIndex];
  const previousCensusYear = censusYears[selectedYearIndex - 1] ?? null;
  const nextCensusYear = censusYears[selectedYearIndex + 1] ?? null;

  // year change handlers
  const handleNext = () => {
    if (selectedYearIndex === lastIndex) return;
    setSelectedYearIndex((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (selectedYearIndex === 0) return;
    setSelectedYearIndex((prev) => prev - 1);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const _data = regionDataGenerator(censusYear);
    setData((prev) => {
      prev = _data;
      return prev;
    });
  }, [selectedYearIndex]);

  return {
    regionData: data,
    selectedYearIndex,
    lastIndex,
    handleNext,
    handlePrevious,
    censusYear,
    previousCensusYear,
    nextCensusYear,
  };
};

const regionDataGenerator = (year) => {
  return [
    { name: "Dodoma", value: 402 },
    { name: "Arusha", value: 310 },
    { name: "Kilimanjaro", value: 362 },
    { name: "Tanga", value: 412 },
    { name: "Morogoro", value: 415 },
    { name: "Pwani", value: 365 },
    { name: "Dar es Salaam", value: 454 },
    { name: "Lindi", value: 253 },
    { name: "Mtwara", value: 257 },
    { name: "Ruvuma", value: 307 },
    { name: "Iringa", value: 283 },
    { name: "Mbeya", value: 336 },
    { name: "Singida", value: 229 },
    { name: "Tabora", value: 337 },
    { name: "Rukwa", value: 228 },
    { name: "Kigoma", value: 246 },
    { name: "Shinyanga", value: 250 },
    { name: "Kagera", value: 301 },
    { name: "Mwanza", value: 376 },
    { name: "Mara", value: 280 },
    { name: "Manyara", value: 236 },
    { name: "Njombe", value: 271 },
    { name: "Katavi", value: 271 },
    { name: "Simiyu", value: 243 },
    { name: "Geita", value: 227 },
    { name: "Songwe", value: 200 },
    { name: "Kaskazini Unguja", value: 41  },
    { name: "Kusini Pemba", value: 48  },
    { name: "Mjini Magharibi", value: 119 },
    { name: "Kaskazini Pemba", value: 39  },
    { name: "Kusini Unguja", value: 38 },
  ].sort((a, b) => (a.value < b.value ? 1 : -1));
};
