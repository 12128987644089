import { Typography } from "@mui/material";
import React, { useContext } from "react";
import total from "resources/img/censusLifeExpectancy.png";
import { DashboardContext } from "states/DashboardContext";

export default function CensusLifeExpectancyViz(props) {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;
  return (
    <div className="flex flex-col p-5 gap-2">
      <Typography>
        {language
          ? "Life Expectancy at Birth (in years) "
          : "Wastani wa Umri wa Kuishi (katika miaka)"}
      </Typography>
      <div className="flex flex-col justify-center gap-2">
        <div className="flex w-full justify-center">
          <img src={total} alt="img" style={{ width: "50%" }} />
        </div>
        <div className="w-full flex flex-row justify-around">
          <div className="flex flex-col justify-center items-center">
            <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
              <Typography
                style={{
                  padding: "2px",
                  fontSize: "bold",
                  paddingBottom: "0px",
                  paddingTop: "0px",
                }}
              >
                2012
              </Typography>
            </div>
            {props.lifexp["2012"] ? (
              <Typography fontSize={30} fontWeight={"bold"}>
                {props.lifexp["2012"]}
              </Typography>
            ) : (
              <Typography fontSize={20}>
                {language ? "No Data" : "Hakuna Taarifa"}
              </Typography>
            )}
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
              <Typography
                style={{
                  padding: "2px",
                  fontSize: "bold",
                  paddingBottom: "0px",
                  paddingTop: "0px",
                }}
              >
                2022
              </Typography>
            </div>
            {props.lifexp["2022"] ? (
              <Typography fontSize={30} fontWeight={"bold"}>
                {props.lifexp["2022"]}
              </Typography>
            ) : (
              <Typography fontSize={20}>
                {language ? "No Data" : "Hakuna Taarifa"}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
