import { useContext } from "react";
import censusFemaleLogo from "resources/img/censusFemale.png";
import censusMaleLogo from "resources/img/censusMale.png";
import { DashboardContext } from "states/DashboardContext";

function SexRatio() {
  const words = useTranslations();
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full h-fit font-bold bg-white shadow-xl rounded-xl">
      <div className="w-full h-auto p-5 pt-2 rounded-xl flex flex-col items-center space-y-10">
        <p className="w-full font-normal text-xl">{words.sexRatio}</p>
        <div className="grow flex items-center justify-center">
          <div className="flex flex-row items-center">
          <div className="grid grid-cols-[1fr_3px_1fr] gap-2 justify-center w-full h-full ">
                <img src={censusMaleLogo} alt="" className="h-[120px] object-contain" />
                <div className="h-[120px] w-1 bg-[brown]" />
                <img src={censusFemaleLogo} alt="" className="h-[120px] object-contain" />
              </div>
            <div className="flex flex-col">
              <p className="text-3xl">95</p>
              <div className="flex flex-row gap-5">
                <div>
                  <p className="text-sm font-normal">{words.urban}</p>
                  <p className="text-xl">92</p>
                </div>
                <div>
                  <p className="text-sm font-normal">{words.rural}</p>
                  <p className="text-xl">96</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-full rounded-xl gap-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 p-4 w-full h-full">
          <div className="flex flex-col items-center space-y-2">
            <p className="w-full text-center text-white bg-[#057693] rounded-lg px-3 py-2">
              {words.tanzaniaMainLand}
            </p>
            <div className="flex flex-col items-center ">
              <div className="grid grid-cols-[1fr_3px_1fr] gap-2 justify-center w-full h-full ">
                <img src={censusMaleLogo} alt="" className="h-[120px] object-contain" />
                <div className="h-[120px] w-1 bg-[brown]" />
                <img src={censusFemaleLogo} alt="" className="h-[120px] object-contain" />
              </div>
              <div className="flex flex-col">
                <p className="text-3xl">95</p>
                <div className="flex flex-row gap-5">
                  <div>
                    <p className="text-sm font-normal">{words.urban}</p>
                    <p className="text-xl">92</p>
                  </div>
                  <div>
                    <p className="text-sm font-normal">{words.rural}</p>
                    <p className="text-xl">96</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center space-y-2">
            <p className="w-full text-center text-white bg-[#057693] rounded-lg px-3 py-2">
              Tanzania Zanzibar
            </p>
            <div className="flex flex-col items-center ">
              <div className="grid grid-cols-[1fr_3px_1fr] gap-2 justify-center w-full h-full ">
                <img src={censusMaleLogo} alt="" className="h-[120px] object-contain" />
                <div className="h-[120px] w-1 bg-[brown]" />
                <img src={censusFemaleLogo} alt="" className="h-[120px] object-contain" />
              </div>
              <div className="flex flex-col">
                <p className="text-3xl">94</p>
                <div className="flex flex-row gap-5">
                  <div>
                    <p className="text-sm font-normal">{words.urban}</p>
                    <p className="text-xl">92</p>
                  </div>
                  <div>
                    <p className="text-sm font-normal">{words.rural}</p>
                    <p className="text-xl">96</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SexRatio;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    sexRatio: language
      ? "Sex ratio (in %) Tanzania (In number of males per every 100 females)"
      : "Uwiano Kijinsi, Tanzania (kwa idadi ya wanaume katika kila wanawake 100)",
    tanzaniaMainLand: language ? "Tanzania Mainland" : "Tanzania Bara",
    countLabel: language ? "Population in Tanzania" : "Idadi ya Watu, Tanzania",
    male: language ? "Male" : "Wanaume",
    female: language ? "Female" : "Wanawake",
    urban: language ? "Urban" : "Mjini",
    rural: language ? "Rural" : "Vijijini",
    language,
  };
};
