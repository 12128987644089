import { environment } from "../../environment";
import { UseFetchWrapper } from "../../_helpers/fetch-wrapper";

export default function CensusService() {
  const fetchWrapper = UseFetchWrapper();
  const BASE_URL = environment.base_url;
  const model = "census/";

  function getLandingPageData() {
    return fetchWrapper.get(geturl() + "landingsummary/");
  }

  function getFilterPaneData() {
    return fetchWrapper.get(geturl() + "filterpane/");
  }

  function getDefaultMapData() {
    return fetchWrapper.get(geturl() + "dmdata/");
  }
  function getDashboardMapData(data) {
    return fetchWrapper.post(geturl() + "dashboarddatavalue/", data);
  }

  function getAreas() {
    return fetchWrapper.get(geturl() + "area/");
  }

  function getViz(area_code, parent_code) {
    let route =
      area_code === "TZ"
        ? "viz/" + `?area_code=${area_code}`
        : "viz/" + `?area_code=${area_code}&parent_code=${parent_code}`;
    return fetchWrapper.get(geturl() + route);
  }

  function getAgegroup(area_code, parent_code) {
    let route =
      area_code === "TZ"
        ? "agegroup/" + `?area_code=${area_code}`
        : "agegroup/" + `?area_code=${area_code}&parent_code=${parent_code}`;
    return fetchWrapper.get(geturl() + route);
  }

  function getDatavalue(sectorkey, subsectorkey, indicatorkey, subgroupkey) {
    return fetchWrapper.get(
      geturl() +
        `datavalue/?sectorkey=${sectorkey}&subsectorkey=${subsectorkey}&indicatorkey=${indicatorkey}&subgroupkey=${subgroupkey}`
    );
  }

  function getSpecialGroup() {
    return fetchWrapper.get(geturl() + "specialgroup/");
  }


  function geturl() {
    return `${BASE_URL}${model}`;
  }

  return {
    getLandingPageData,
    getFilterPaneData,
    getDatavalue,
    getDefaultMapData,
    getAreas,
    getViz,
    getAgegroup,
    getSpecialGroup,
    getDashboardMapData
  };
}
