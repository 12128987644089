import React, { useState, useEffect, useContext } from "react";
import cencusHouseHold from "../../../resources/img/cencusHouseHold.png";
import censusMale from "../../../resources/img/censusMale.png";
import censusFemale from "../../../resources/img/censusFemale.png";
import censusDensity from "../../../resources/img/censusDensity.png";
import censusPopulation from "../../../resources/img/censusPopulation.png";
import censusExpectancy from "../../../resources/img/censusLifeExpectancy.png";
import CensusCard from "../../elements/census/CensusCard";
import ReactECharts from "echarts-for-react";
import censusGrowthRate from "../../../resources/img/censusGrowthRate.png";
import CensusService from "../../../_services/Census/CensusService";
import { DashboardContext } from "states/DashboardContext";
import YearSlider from "../../elements/census/YearSlider";
import CensusLoadingCmp from "ui/elements/census/CensusLoadingCmp";

export default function CensusHomePage() {
  // import global state
  const { currentHomeYearState } = useContext(DashboardContext);
  // local state
  const censusService = CensusService();
  const [currentHomeYear, setCurrentHomeYear] = currentHomeYearState;
  const [indicatorList, setIndicatorList] = useState([]);

  // Fetch landing indicators
  useEffect(() => {
    const fetchLandingIndicators = async () => {
      const data = await censusService.getLandingPageData();
      setIndicatorList(data);
    };

    fetchLandingIndicators();
  }, []);

  // Funtion to set the values of the indicators
  function getIndicatorData(tag, unit = null) {
    if (indicatorList != []) {
      let value = indicatorList.find(
        (e) =>
          parseInt(e.time_value.trim()) === parseInt(currentHomeYear.trim()) &&
          e.indicator_tag === tag
      );
      if (value) {
        if (unit) {
          return value.data_value + " " + unit;
        } else {
          return value.data_value;
        }
      } else return `${words.noData}`;
    }
  }

  // Function to handle year slider value change
  function handleChange(value) {
    // return the respective time value chosen on slider
    setCurrentHomeYear(value);
  }

  const labelOption = {
    show: true,
    rotate: 0,
    fontSize: 16,
    rich: {
      name: {},
    },
  };

  const words = useTranslations();

  const sources = {
    1967: 'Population and Housing Census (PHC) 1988, National Bureau of Statistics, Tanzania',
    1978: 'Population and Housing Census (PHC) 1988, National Bureau of Statistics, Tanzania',
    1988: 'Population and Housing Census (PHC) 1988, National Bureau of Statistics, Tanzania',
    2002: 'Population and Housing Census (PHC) 2002, National Bureau of Statistics, Tanzania',
    2012: 'Population and Housing Census (PHC) 2012, National Bureau of Statistics, Tanzania',
    2022: 'Population and Housing Census (PHC) 2022, National Bureau of Statistics, Tanzania'
  }

  const options = {
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "10%"];
      },
    },
    title: {
      left: "center",
      text: words.chartTitle,
      top: "bottom",
      textStyle: {
        color: "#000000",
        fontSize: 12,
      },
    },

    xAxis: {
      type: "category",
      data: ["1967", "1978", "1988", "2002", "2012", "2022"],
      boundaryGap: true,
    },
    yAxis: {
      type: "value",
      name: words.chartYAxis,
      nameLocation: "middle",
      nameGap: 35,
    },

    series: [
      {
        data: [12.3, 17.5, 23.1, 34.4, 44.9, 61.7],
        type: "line",
        lineStyle: { color: "#1985A1" },
        label: labelOption,
      },
    ],
  };



  return (
    <div
      className="flex flex-col items-center bg-[#EFEFEF] w-full  h-full"
      id="census-home"
    >
      {/* Buttons */}

      {indicatorList.length === 0 ? (
        <CensusLoadingCmp />
      ) : (
        <div className="flex flex-col gap-4 w-full p-5">
          <div>
            <YearSlider handleChange={handleChange} />
          </div>

         <div className="flex justify-center"> <p>Source: {sources[currentHomeYear]}</p>  </div>
          <div className="flex flex-col w-full gap-4  lg:flex-row md:flex-row">
            <CensusCard
              name={words.population}
              icon={censusPopulation}
              iconFemale={censusFemale}
              iconMale={censusMale}
              value={getIndicatorData("POPT")}
              maleValue={getIndicatorData("POPM")}
              femaleValue={getIndicatorData("POPF")}
              populationCard={true}
              putComas={true}
            />

            <CensusCard
              name={words.averagePopulationGrowthRate}
              value={`${getIndicatorData("AVPGR", "%")}`}
              icon={censusGrowthRate}
            />

            <CensusCard
              name={words.numberOfHouseholds}
              value={getIndicatorData("NH")}
              icon={cencusHouseHold}
              putComas={true}
            />
          </div>
          <div className="flex flex-col lg:flex-row md:flex-row w-full gap-4 ">
            <CensusCard
              name={words.populationDensity}
              value={`${getIndicatorData("POPD", `/${words.popdensityunit}`)}`}
              icon={censusDensity}
            />

            <CensusCard
              name={words.lifeExpetancyAtBirth}
              value={`${getIndicatorData("LEAB")}`}
              valueUnit={`${words.years}`}
              language={words.language}
              icon={censusExpectancy}
            />
          </div>
          <div className="bg-white">
            <ReactECharts style={{ height: "50vh" }} option={options} />
          </div>
        </div>
      )}
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    averagePopulationGrowthRate: language
      ? "Average population growth rate"
      : "Wastani wa Kiwango cha Ukuaji wa Idadi ya Watu",
    censusYear: language ? "Census Year" : "Mwaka wa sensa",
    lifeExpetancyAtBirth: language
      ? "Life expectancy at Birth"
      : "Matarajio ya maisha wakati wa Kuzaliwa",
    numberOfHouseholds: language ? "Number of households" : "Idadi ya Kaya",
    population: language ? "Population" : "Idadi ya watu",
    populationDensity: language ? "Population density" : "Msongamano wa Watu",
    populationProjection: language
      ? "Population Projection"
      : "Makadirio ya idadi ya watu",
    years: language ? "Years" : "Miaka",
    popdensityunit: language ? "Square Kilometer" : "Kilomita za mraba",
    chartTitle: language
      ? "Population Trends in Tanzania, 1967 – 2022 Censuses"
      : "Mwenendo wa Idadi ya watu Tanzania, sensa 1967 - 2022",
    chartYAxis: language
      ? "Population in millions"
      : "Idadi ya watu kwa mamilioni",
    noData: language ? "No Data" : "Hakuna Taarifa",
    language,
  };
};
