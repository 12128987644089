import CensusBuildingLogo from "resources/img/CensusBuilding.png";
import censusStoreyBuilding from "resources/img/censusStoreyBuilding.png";
import censusNonStoreyBuilding from "resources/img/censusNonStoreyBuilding.png";
import censusBuildingsUnderConstruction from "resources/img/censusBuildingsUnderConstruction.png";
import CensusInfoCrds from "ui/components/census/CensusInfoCrds";
import { useContext } from "react";
import { DashboardContext } from "states/DashboardContext";

function BuildingsTanzania2() {

  // global state
  const words = useTranslations();
  return (
    <>
      <div className="flex flex-col w-full gap-4 p-4 font-bold bg-white shadow-xl rounded-xl">
        <p className="w-full border-b-2 border-b-[#E6E6E6] font-normal text-xl">
          {words.buildings}, Tanzania
        </p>
        <div className="flex gap-4 flex-wrap px-[5%]">
          <div className="flex items-center space-x-2 w-full lg:w-[45%]">
            <img src={CensusBuildingLogo} alt="" className="h-[80px]" />
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-normal">{words.idadiyamajengo}</p>
              <p className="text-2xl font-bold">14,348,372</p>
            </div>
          </div>

          
          <div className="flex items-center space-x-2 w-full lg:w-[45%]">
            <img
              src={censusBuildingsUnderConstruction}
              alt=""
              className="h-[80px]"
            />
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-normal">
              {words.ongoingconstruction}
              </p>
              <p className="text-2xl font-bold">739,285</p>
            </div>
          </div>

          <div className="flex items-center space-x-2 w-full lg:w-[45%]">
            <img src={censusStoreyBuilding} alt="" className="h-[80px]" />
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-normal">{words.idadiyamajengoyagorofa}</p>
              <p className="text-2xl font-bold">68,724</p>
            </div>
          </div>

          <div className="flex items-center space-x-2 w-full lg:w-[45%]">
            <img src={censusNonStoreyBuilding} alt="" className="h-[80px]" />
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-normal">
              {words.idadiyamajengoyasiyoyagorofa}
              </p>
              <p className="text-2xl font-bold">13,540,363</p>
            </div>
          </div>

        </div>
      </div>

      <div className="flex flex-col w-full gap-4 p-4 bg-white shadow-xl rounded-xl">
        <p className="w-full border-b-2 border-b-[#E6E6E6] text-xl font-normal">
          {words.buildings}
        </p>
        <CensusInfoCrds
          cardData={[
            {
              label: words.tanzaniaMainLand,
              data: [
                {
                  image: CensusBuildingLogo,
                  count: "13,907,951                  ",
                  label: `${words.idadiyamajengo}`,
                },
                {
                  image: censusStoreyBuilding,
                  count: "61,258                  ",
                  label: `${words.idadiyamajengoyagorofa}`,
                },
                {
                  image: censusNonStoreyBuilding,
                  count: "13,152,298                  ",
                  label: `${words.idadiyamajengoyasiyoyagorofa}`,
                },
                {
                  image: censusBuildingsUnderConstruction,
                  count: "694,395                  ",
                  label: `${words.ongoingconstruction}`,
                },
              ],
            },
            {
              label: "Tanzania Zanzibar",
              data: [
                {
                  image: CensusBuildingLogo,
                  count: "440,421                  ",
                  label: `${words.idadiyamajengo}`,
                },
                {
                  image: censusStoreyBuilding,
                  count: "7,466                  ",
                  label: `${words.idadiyamajengoyagorofa}`,
                },
                {
                  image: censusNonStoreyBuilding,
                  count: "388,065                  ",
                  label: `${words.idadiyamajengoyasiyoyagorofa}`,
                },
                {
                  image: censusBuildingsUnderConstruction,
                  count: "44,890                  ",
                  label: `${words.ongoingconstruction}`,
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
}

export default BuildingsTanzania2;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    buildings: language ? "Buildings" : "Majengo",
    tanzaniaMainLand: language ? "Tanzania Mainland" : "Tanzania Bara",
    countLabel: language ? "Population in Tanzania" : "Idadi ya Watu, Tanzania",
    idadiyamajengo: language? 'Number of buildings' : 'Idadi ya majengo',
    idadiyamajengoyagorofa: language? 'Number of  buildings with storey' : 'Idadi ya Majengo ya Ghorofa',
    idadiyamajengoyasiyoyagorofa: language? 'Number of non storey buildings' : 'Idadi ya Majengo yasiyo ya Ghorofa',
    ongoingconstruction: language? 'Number of buildings under construction' : 'Idadi ya Majengo yanayoendelea na Ujenzi',
    language,
  };
};
