import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

export function CensusMaterialTabs({ value, handler, options, navFn }) {
    return (
      <Tabs
        value={value}
        onChange={handler}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        className="bg-[#0F7995]"
        TabIndicatorProps={{
          style: { background: "#F6C904", textColor: "#F6C904" },
        }}
      >
        {options.map((option, index) => (
          <Tab
            key={index}
            label={option?.label}
            TabIndicatorProps={{ style: { color: "#F6C904" } }}
            style={{ textTransform: "capitalize", color: "white" }}
            onClick={() => {
              if (option?.path) {
                navFn(option.path);
              }
            }}
          />
        ))}
      </Tabs>
    );
  }