import React, { useContext, useState } from "react";
import { Button, Typography } from "@mui/material";
import ukFlag from "resources/img/censusUkFlag.png";
import tzFlag from "resources/img/censusTzFlag.png";
import { CensusGlobalHamburgerMenu } from "./CensusHamburgerMenu";

import tzSeal from "resources/img/censusTzSeal.png";
import nbsLogo from "resources/img/censusNbsLogo.png";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "states/DashboardContext";
import clsx from "clsx";

function CensusLandingNavBar() {
  const navigate = useNavigate();

  // 1. GLobalstate variable to store selected language
  const { languageState } = useContext(DashboardContext);
  const [language, setLanguage] = languageState;

  const useTranslations = () => {
    return {
      tutorial: language ? "Tutorial" : "Mafunzo",
      title: language ? "United Republic of Tanzania" : "Jamhuri ya Muungano wa Tanzania",
      censusHub: language? "Census Hub":"Kitovu cha Sensa",
      language,
    };
  };

  const words = useTranslations();

  // 2.  handle language change

  function handleLanguageChange(selectedLanguage) {
    if (selectedLanguage === "English") {
      setLanguage(true);
    } else if (selectedLanguage === "Kiswahili") {
      setLanguage(false);
    }
  }

  return (
    <div className="px-4 md:px-8">
      <header className="p-4 md:p-6 flex justify-between items-center">
        <img
          src={tzSeal}
          alt="TZ Seal"
          className="w-10 h-10 md:w-14 md:h-14 lg:w-24 lg:h-24"
        />
        <div className="text-center">
          <div className="text-lg md:text-3xl lg:text-4xl font-bold">
            {words.title}
          </div>
          <div className="text-lg md:text-3xl lg:text-4xl font-bold text-[#0f7895]">
             {words.censusHub}
          </div>
        </div>
        <img
          src={nbsLogo}
          alt="NBS Logo"
          className="w-10 h-10 md:w-14 md:h-14 lg:w-24 lg:h-24"
        />
      </header>

      <div className="h-[1px] bg-black absolute left-0 right-0"></div>

      <div className="px-4 md:px-8 -my-2">
        <div className="flex justify-between items-center">
          <div className="px-4 md:px-6">
            <CensusGlobalHamburgerMenu color="black" />
          </div>
          <nav className="p-4 md:p-6 flex items-center">
            <button
              className="mx-1"
              onClick={() => handleLanguageChange("English")}
            >
              {" "}
              <p className="text-sm md:text-base">English</p>{" "}
            </button>
            {language}
            <img
              src={ukFlag}
              alt="UK Flag"
              className={clsx("w-5 rounded-full mx-1 mr-4", language && "outline outline-[#0f7895] outline-offset-2")}
              onClick={() => handleLanguageChange("English")}
            />
            <button
              className="mx-1"
              onClick={() => handleLanguageChange("Kiswahili")}
            >
              <p className="text-sm md:text-base">Kiswahili</p>
            </button>
            <img
              src={tzFlag}
              alt="Tanzania Flag"
              className={clsx("w-5 rounded-full mx-1 mr-6", !language && "outline outline-[#0f7895] outline-offset-2")}
              onClick={() => handleLanguageChange("Kiswahili")}
            />
            {/* <Button
              variant="outlined"
              style={{ color: "black", borderColor: "black" }}
              className=""
              onClick={() => navigate("/tutorials")}
            >
              <p className="text-sm md:text-base">{words.tutorial}</p>
            </Button> */}
          </nav>
        </div>
      </div>

      <div className="h-[1px] bg-black absolute left-0 right-0"></div>
    </div>
  );
}

export default CensusLandingNavBar;
