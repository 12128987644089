import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { DashboardContext } from "states/DashboardContext";

export default function CensusGrowthRateViz(props) {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;
  return (
    <div className="flex flex-col p-5 gap-2">
      <Typography>
        {language
          ? "Population Growth Rate"
          : "Wastani wa kasi ya Ongezeko la idadi ya watu"}
      </Typography>

      <div className="flex flex-row justify-around items-center ">
        <div className="flex flex-col justify-center items-center">
          <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
            <Typography
              style={{
                padding: "2px",
                fontSize: "bold",
                paddingBottom: "0px",
                paddingTop: "0px",
              }}
            >
              2002 - 2012
            </Typography>
          </div>
          {props.averagegrowthrate["2012"] ? (
            <div className="h-[150px] w-full p-2 flex items-center justify-center">
              <div className="h-[80%] aspect-square bg-[#057693] rounded-full flex items-center justify-center">
                <div className="h-[90%] aspect-square bg-[#fff] rounded-full flex items-center justify-center">
                  <p className="text-2xl">{props.averagegrowthrate["2012"]}</p>
                </div>
              </div>
            </div>
          ) : (
            <Typography fontSize={20}>
              {language ? "No Data" : "Hakuna Taarifa"}
            </Typography>
          )}
        </div>

        <div className="flex flex-col justify-center items-center">
          <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
            <Typography
              style={{
                padding: "2px",
                fontSize: "bold",
                paddingBottom: "0px",
                paddingTop: "0px",
              }}
            >
              2012 - 2022
            </Typography>
          </div>
          {props.averagegrowthrate["2022"] ? (
            <div className="h-[200px] w-full p-2 flex items-center justify-center">
              <div className="h-[80%] aspect-square bg-[#057693] rounded-full flex items-center justify-center">
                <div className="h-[90%] aspect-square bg-[#fff] rounded-full flex items-center justify-center">
                  <p className="text-2xl">{props.averagegrowthrate["2022"]}</p>
                </div>
              </div>
            </div>
          ) : (
            <Typography fontSize={20}>
              {language ? "No Data" : "Hakuna Taarifa"}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
