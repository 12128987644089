import { Typography } from "@mui/material";
import React, { useContext } from "react";
import img from "resources/img/CensusPopulationDensity2.png";
import { DashboardContext } from "states/DashboardContext";

export default function CensusPopulationDensityViz(props) {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;
  return (
    <div className="flex flex-col p-5 gap-2">
      {language ? (
        <Typography>
          Population Density (in Km<sup>2</sup>)
        </Typography>
      ) : (
        <Typography>
          Msongamano wa Watu (katika Km<sup>2</sup>)
        </Typography>
      )}
      <div>
        <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
          <Typography style={{ padding: "2px", fontSize: "bold" }}>
            2012
          </Typography>
        </div>
        <div>
          <div className="flex flex-row justify-around items-center">
            <img src={img} style={{ height: "100px" }} />
            {props.popdensity["2012"] ? (
              <Typography fontSize={40} fontWeight={"bold"}>
                {props.popdensity["2012"]}
              </Typography>
            ) : (
              <Typography fontSize={20}>
                {language ? "No Data" : "Hakuna Taarifa"}
              </Typography>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="border border-[#333333] border-opacity-[50%] shadow-md rounded w-fit">
          <Typography style={{ padding: "2px", fontSize: "bold" }}>
            2022
          </Typography>
        </div>
        <div>
          <div className="flex flex-row justify-around items-center">
            <img src={img} style={{ height: "100px" }} />
            {props.popdensity["2022"] ? (
              <Typography fontSize={40} fontWeight={"bold"}>
                {props.popdensity["2022"]}
              </Typography>
            ) : (
              <Typography fontSize={20}>
                {language ? "No Data" : "Hakuna Taarifa"}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
