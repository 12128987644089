import { useContext } from "react";
import CensusHospitali from "resources/img/CensusHospitali.png";
import CensusKituoAfya from "resources/img/CensusKituoAfya.png";
import CensusZahanati from "resources/img/CensusZahanati.png";
import { DashboardContext } from "states/DashboardContext";
import CensusInfoCrds from "ui/components/census/CensusInfoCrds";
import { Info } from "../buildingsTanzania/BuildingsTanzania";

function CommunityServicesHealth() {
  const words = useTranslations();

  return (
    <>
      <div className="flex flex-col w-full gap-4 p-4 font-bold bg-white shadow-xl rounded-xl">
        <p className="w-full border-b-2 border-b-[#E6E6E6] text-xl font-normal">
          {words.socialServices}
        </p>
        <p className="text-lg font-[500]">
          A. Idadi ya vituo vya kutolea huduma za Afya, Tanzania
        </p>
        <div className="flex flex-col w-full gap-3 py-4 md:items-center md:flex-row justify-evenly">
          <Info image={CensusZahanati} label={words.clinic} data="7,132" />
          <Info image={CensusKituoAfya} label={words.healthCenter} data="976" />
          <Info image={CensusHospitali} label={words.hospital} data="409" />
        </div>
      </div>

      <div className="flex flex-col w-full gap-4 p-4 bg-white shadow-xl rounded-xl">
        <p className="w-full border-b-2 border-b-[#E6E6E6] text-xl font-normal">
          A. Idadi ya vituo vya kutolea huduma za Afya, Tanzania
        </p>
        <CensusInfoCrds
          cardData={[
            {
              label: words.tanzaniaMainland,
              data: [
                {
                  image: CensusZahanati,
                  count: "5,874",
                  label: words.clinic,
                },
                {
                  image: CensusKituoAfya,
                  count: "685",
                  label: words.healthCenter,
                },
                {
                  image: CensusHospitali,
                  count: "326",
                  label: words.hospital,
                },
              ],
            },
            {
              label: "Tanzania Zanzibar",
              data: [
                {
                  image: CensusZahanati,
                  count: "1,268",
                  label: words.clinic,
                },
                {
                  image: CensusKituoAfya,
                  count: "291",
                  label: words.healthCenter,
                },
                {
                  image: CensusHospitali,
                  count: "83",
                  label: words.hospital,
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
}

export default CommunityServicesHealth;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    socialServices: language ? "Social Services" : "Huduma za Jamii",
    section: language
      ? "Number of health centers"
      : "Idadi ya vituo vya kutolea huduma za afya",
    clinic: language ? "Clinic" : "Zahanati",
    healthCenter: language ? "Health center" : "Vituo vya afya",
    hospital: language ? "Hospital" : "Hospitali",
    tanzaniaMainland: language ? "Tanzania Mainland" : "Tanzania Bara",
    language,
  };
};

// -------------------- health card item components ------------------------
export const HealthCardLabel = ({ label = "" }) => (
  <p className="text-xl capitalize ">{label}</p>
);
export const HealthCardImage = ({ image, biggerImage }) => (
  <div className={`h-[100px] aspect-square flex items-center justify-start`}>
    <img
      src={image}
      alt=""
      className={`${biggerImage ? "h-full" : "h-[90%]"} object-contain`}
    />
  </div>
);
export const HealthCardContainer = ({ children }) => (
  <div className="w-full min-w-[50vh] flex flex-col gap-2 mb-3 px-5">
    {children}
  </div>
);
