import React, { useEffect, useContext, useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ListItem, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CensusService from "_services/Census/CensusService";
import { DashboardContext } from "states/DashboardContext";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { alpha } from "@mui/material";
import CensusVisualizationDataDownload from "ui/components/census/CensusVisualizationDataDownload";

// Cesnsus service
const censusService = CensusService();

export default function CensusVisualDropDown() {
  // Global state
  const {
    languageState,
    loadingState,
    censusVizDataState,
    censusVizNameState,
    censusAgeGroupState,
  } = useContext(DashboardContext);
  const [language] = languageState;
  const [loading, setLoading] = loadingState;
  const [censusVizData, setCensusVizData] = censusVizDataState;
  const [censusAgeGroup, setCensusAgeGroup] = censusAgeGroupState;
  const [vizAreaName, setVizAreaName] = censusVizNameState;
  const [dataDown, setYourData] = useState(censusVizData);

  // local state


  // component state
  const [openDropDown, setOpenDropDown] = React.useState(false);
  const [openNational, setOpenNational] = React.useState(0);
  const [openSubNational, setOpenSubNational] = React.useState(0);
  const [openRegion, setOpenRegion] = React.useState(0);
  const [openCouncil, setOpenCouncil] = React.useState(0);
  const [openConstituent, setOpenConstituent] = React.useState(0);
  const [openWard, setOpenWard] = React.useState(0);

  const National = [
    {
      NationalKey: "TZ",
      National_name: "Tanzania",
    },
  ];

  // Sub National
  const subNational = [
    {
      subNationalKey: "TZMAIN",
      subNational_name: "Tanzania Mainland",
      NationalKey: "TZ",
    },
    {
      subNationalKey: "TZ002",
      subNational_name: "Tanzania Zanzibar",
      NationalKey: "TZ",
    },
  ];

  //   Area  names
  const [areaName, setAreaName] = React.useState("Tanzania");

  // Region
  const [Region, setRegion] = React.useState([]);

  // Council
  const [Council, setCouncil] = React.useState([]);

  // Constituents
  const [Constituent, SetConstituent] = React.useState([]);

  // wards
  const [Ward, setWard] = React.useState([]);

  // Fetch Areas from API
  useEffect(() => {
    if (Region.length === 0) {
      const fetchAreaData = async () => {
        const data = await censusService.getAreas();

        setRegion(
          data
            .filter((item) => item.area_level === "LVL3")
            .sort((a, b) => {
              return a.area_name.localeCompare(b.area_name);
            })
        );
        setCouncil(
          data
            .filter((item) => item.area_level === "LVL5")
            .sort((a, b) => {
              return a.area_name.localeCompare(b.area_name);
            })
        );
        SetConstituent(
          data
            .filter((item) => item.area_level === "LVL6")
            .sort((a, b) => {
              return a.area_name.localeCompare(b.area_name);
            })
        );
        const newData = data
          .filter((item) => item.area_level === "LVL7")
          .map((item) => {
            const constObject = data.find(
              (constObj) =>
                constObj.area_level === "LVL6" &&
                constObj.area_code === item.parent_code
            );
            if (constObject) {
              const councilObject = data.find(
                (councilObj) =>
                  councilObj.area_level === "LVL5" &&
                  councilObj.area_code === constObject.parent_code
              );
              if (councilObject) {
                return {
                  ...item,
                  grandpa_code: councilObject.area_code,
                };
              }
            }
            return item;
          })
          .sort((a, b) => {
            return a.area_name.localeCompare(b.area_name);
          });

        setWard(newData);
      };

      fetchAreaData();
    }
  }, []);

  // get data from api
  async function getVisualData(areaId, name, parent = null) {
    setOpenDropDown(!openDropDown);
    // loading true
    setVizAreaName(name);
    setLoading(true);

    // Viz
    let data = await censusService.getViz(areaId, parent);

    setCensusVizData((prev) => {
      prev = data;
      return prev;
    });

    // Age
    const age = await censusService.getAgegroup(areaId, parent);
    setCensusAgeGroup((prev) => {
      prev = age;
      return prev;
    });

    // loading false
    setLoading(false);
  }
  const handleOpenDropDown = () => {
    setOpenDropDown(!openDropDown);
  };
  // When National clicked
  const handleClickNational = (id) => {
    if ((openNational !== id) | (openNational === 0)) {
      setOpenNational(id);
      setOpenSubNational(0);
      setOpenRegion(0);
    } else {
      setOpenNational(0);
    }
  };

  // when SubNational clicked
  const handleClickSubNational = (id) => {
    if ((openSubNational !== id) | (openSubNational === 0)) {
      setOpenSubNational(id);
      setOpenRegion(0);
    } else {
      setOpenSubNational(0);
    }
  };

  // when Region clicked
  const handleClickRegion = (id, Region) => {
    if ((openRegion !== id) | (openRegion === 0)) {
      setOpenRegion(id);
      // Reset council
      setOpenCouncil(0);
    } else {
      setOpenRegion(0);
    }
  };

  // When council clicked
  const handleClickCouncil = (id, Areaname) => {
    if ((openCouncil !== id) | (openCouncil === 0)) {
      setOpenCouncil(id);
      setOpenConstituent(0);
    } else {
      setOpenCouncil(0);
    }
  };

  // When constituent clicked
  const handleClickConstituent = (id, Constituentname) => {
    if ((openConstituent !== id) | (openConstituent === 0)) {
      setOpenConstituent(id);

      setOpenWard(0);
    } else {
      setOpenConstituent(0);
    }
  };

  return (
    <div
      className="flex flex-col"
      style={{ position: "relative", overflow: "visible" }}
    >
      <div className="flex flex-row items-center gap-2">
        <Button
          className="bg-opacity-20"
          style={{ background: alpha("#2C9443", 0.2), color: "black" }}
          endIcon={<ArrowDropDownIcon fontSize="inherit" />}
          onClick={handleOpenDropDown}
        >
          {vizAreaName}
        </Button>
        <CensusVisualizationDataDownload data={dataDown} />

      
      </div>
      <Collapse
        in={openDropDown === true}
        timeout="auto"
        unmountOnExit
        style={{
          position: "absolute",
          top: "calc(100% + 10px)", // adjust the distance from the button as needed
          left: 0,
          right: 0,
          zIndex: 1,
          overflow: "auto",
          // maxHeight: "50vh", // adjust the maximum height as needed
          backgroundColor: "white",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)", // optional shadow effect
        }}
      >
        <List component="nav">
          {National.map((item) => (
            <ListItem
              key={item.NationalKey}
              value={item.NationalKey}
              disablePadding
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              <div className="flex flex-row items-center">
                {openNational === item.NationalKey ? (
                  <RemoveIcon
                    className="border mr-1"
                    fontSize="small"
                    onClick={() => handleClickNational(item.NationalKey)}
                  />
                ) : (
                  <AddIcon
                    className="border mr-1"
                    fontSize="small"
                    onClick={() => handleClickNational(item.NationalKey)}
                  />
                )}
                <ListItemButton
                  sx={{ pl: 2 }}
                  onClick={() =>
                    getVisualData(item.NationalKey, item.National_name)
                  }
                  dense={true}
                >
                  <ListItemText primary={item.National_name} />
                </ListItemButton>
              </div>
              {/* Sub National */}
              <Collapse
                in={openNational === item.NationalKey}
                timeout="auto"
                unmountOnExit
              >
                <List component="div">
                  {subNational.map(
                    (item) =>
                      openNational === item.NationalKey && (
                        <ListItem
                          key={item.subNationalKey}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "start",
                          }}
                        >
                          <div className="flex flex-row items-center">
                            {openSubNational === item.subNationalKey ? (
                              <RemoveIcon
                                className="border mr-1"
                                fontSize="small"
                                onClick={() =>
                                  handleClickSubNational(item.subNationalKey)
                                }
                              />
                            ) : (
                              <AddIcon
                                className="border mr-1"
                                fontSize="small"
                                onClick={() =>
                                  handleClickSubNational(item.subNationalKey)
                                }
                              />
                            )}
                            <ListItemButton
                              sx={{ pl: 2 }}
                              onClick={() =>
                                getVisualData(
                                  item.subNationalKey,
                                  item.subNational_name,
                                  item.NationalKey
                                )
                              }
                              dense={true}
                            >
                              <ListItemText primary={item.subNational_name} />
                            </ListItemButton>
                          </div>
                          {/* Region */}
                          <Collapse
                            in={openSubNational === item.subNationalKey}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div">
                              {Region.map(
                                (item) =>
                                  // display only required values
                                  openSubNational === item.parent_code && (
                                    <ListItem
                                      key={item.area_code}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "start",
                                        alignItems: "start",
                                      }}
                                    >
                                      <div className="flex flex-row items-center">
                                        {openRegion === item.area_code ? (
                                          <RemoveIcon
                                            className="border mr-1"
                                            fontSize="small"
                                            onClick={() =>
                                              handleClickRegion(
                                                item.area_code,
                                                item.area_name
                                              )
                                            }
                                          />
                                        ) : (
                                          <AddIcon
                                            className="border mr-1"
                                            fontSize="small"
                                            onClick={() =>
                                              handleClickRegion(
                                                item.area_code,
                                                item.area_name
                                              )
                                            }
                                          />
                                        )}
                                        <ListItemButton
                                          sx={{ pl: 2 }}
                                          onClick={() =>
                                            getVisualData(
                                              item.area_code,
                                              item.area_name,
                                              item.parent_code
                                            )
                                          }
                                          dense={true}
                                        >
                                          <ListItemText
                                            primary={item.area_name}
                                          />
                                        </ListItemButton>
                                      </div>
                                      {/* Council */}
                                      <Collapse
                                        in={openRegion === item.area_code}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <List component="div">
                                          {Council.map(
                                            (item) =>
                                              openRegion ===
                                                item.parent_code && (
                                                <ListItem
                                                  key={item.area_code}
                                                  sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "start",
                                                    alignItems: "start",
                                                  }}
                                                >
                                                  <div className="flex flex-row items-center">
                                                    {openCouncil ===
                                                    item.area_code ? (
                                                      <RemoveIcon
                                                        className="border mr-1"
                                                        fontSize="small"
                                                        onClick={() =>
                                                          handleClickCouncil(
                                                            item.area_code,
                                                            item.area_name
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <AddIcon
                                                        className="border mr-1"
                                                        fontSize="small"
                                                        onClick={() =>
                                                          handleClickCouncil(
                                                            item.area_code,
                                                            item.area_name
                                                          )
                                                        }
                                                      />
                                                    )}
                                                    <ListItemButton
                                                      sx={{ pl: 3 }}
                                                      onClick={() =>
                                                        getVisualData(
                                                          item.area_code,
                                                          item.area_name,
                                                          item.parent_code
                                                        )
                                                      }
                                                      dense={true}
                                                    >
                                                      <ListItemText
                                                        primary={item.area_name}
                                                      />
                                                    </ListItemButton>
                                                  </div>
                                                  {/* wards */}
                                                  <Collapse
                                                    in={
                                                      openCouncil ===
                                                      item.area_code
                                                    }
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <List component="div">
                                                      {Ward.map(
                                                        (item) =>
                                                          openCouncil ===
                                                            item.grandpa_code && (
                                                            <ListItem
                                                              key={
                                                                item.area_code
                                                              }
                                                              sx={{
                                                                display: "flex",
                                                                flexDirection:
                                                                  "column",
                                                                justifyContent:
                                                                  "start",
                                                                alignItems:
                                                                  "start",
                                                              }}
                                                            >
                                                              <div className="flex flex-row items-center">
                                                                <ListItemButton
                                                                  sx={{ pl: 3 }}
                                                                  onClick={() =>
                                                                    getVisualData(
                                                                      item.area_code,
                                                                      item.area_name,
                                                                      item.parent_code
                                                                    )
                                                                  }
                                                                  dense={true}
                                                                >
                                                                  <ListItemText
                                                                    primary={
                                                                      item.area_name
                                                                    }
                                                                  />
                                                                </ListItemButton>
                                                              </div>
                                                            </ListItem>
                                                          )
                                                      )}
                                                    </List>
                                                  </Collapse>
                                                </ListItem>
                                              )
                                          )}
                                        </List>
                                      </Collapse>
                                    </ListItem>
                                  )
                              )}
                            </List>
                          </Collapse>
                        </ListItem>
                      )
                  )}
                </List>
              </Collapse>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
}
