import React, { useContext } from "react";
import CensusReport from "ui/elements/census/CensusReport";
import book1 from "resources/img/censusSocialEconomicTZ.PNG";
import book2 from "resources/img/censusSocialEconomicMain.PNG";
import book3 from "resources/img/censusSocialEconomicZNZ.PNG";
import playStore from "resources/img/censusPlayStore.png";
import Link from "@mui/material/Link";
import { DashboardContext } from "states/DashboardContext";

export default function CensusPublication() {
  // global state
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  const publication = [
    {
      id: 1,
      title: "URT Demographic and Socioeconomic Profile",
      url: "/publication/01. URT_Demographic_and_Socioeconomic_Profile.pdf",
      img: book1,
    },
    {
      id: 2,
      title: "Mainland Demographic and Socioeconomic Profile",
      url: "/publication/02. Mainland_Demographic_and_Socioeconomic_Profile.pdf",
      img: book2,
    },
    {
      id: 3,
      title: "Zanzibar Demographic and Socioeconomic Profile",
      url: "/publication/03. Zanzibar_Demographic_and_Socioeconomic_Profile.pdf",
      img: book3,
    },
    
    
  ];

  const publicationDist = [
    {
      id: 13,
      title: "Tanzania Building Census 2022",
      url: "/publication/04. Building_Census2022_English08April.pdf",
    },
    {
      id: 14,
      title: "Sensa ya Majengo Tanzania ya Mwaka 2022",
      url: "/publication/05. Building_Census2022_Swahili08April2024.pdf",
    },
    {
      id: 18,
      title: "UTILIZING BASIC DEMOGRAPHIC AND SOCIO-ECONOMICDATA FOR EFFECTIVE POLICY AND DEVELOPMENT PLANNING IN TANZANIA",
      url: "/publication/06A. POLICY_BRIEF_Final12Feb24.pdf",
    },
    {
      id: 15,
      title: "UTILIZING DATA ON BUILDING INFORMATION FOR EFFECTIVE POLICY AND DEVELOPMENT PLANNING IN TANZANIA",
      url: "/publication/06B. POLICY_BRIEF_Buildings_Final12Feb2024.pdf",
    },
    {
      id: 16,
      title: "Ripoti ya Takwimu za Msingi za Kidemografia, Kijamii na Kiuchumi Matokeo Muhimu",
      url: "/publication/07. Key_Findings_Basic_Demographic_and_Socio-economic_kisw_12.06.2024_Final.pdf",
    },
    {
      id: 17,
      title: "BASIC DEMOGRAPHIC AND SOCIO-ECONOMIC PROFILE Key Findings",
      url: "/publication/08. Key_Findings_Basic_Demographic_and_Socio-economic_ Eng_ 12.06.2024 Final.pdf",
    },
    {
      id: 19,
      title: "Final_TRIFOLD BROCHURE_DEMOGRAPHIC AND SOCIO-ECONOMIC PROFILE REPORT_Swahili",
      url: "/publication/Final_TRIFOLD BROCHURE_DEMOGRAPHIC AND SOCIO-ECONOMIC PROFILE REPORT_Swahili.pdf",
    },
    {
      id: 20,
      title: "Final_TRIFOLD_BROCHURE_DEMOGRAPHIC_AND SOCIO-ECONOMIC_PROFILE REPORT_English",
      url: "/publication/Final_TRIFOLD_BROCHURE_DEMOGRAPHIC_AND SOCIO-ECONOMIC_PROFILE REPORT_English.pdf",
    },
    {
      id: 21,
      title: "MATUMIZI YA TAKWIMU ZA MSINGI ZA KIDEMOGRAFIA, KIJAMII NA KIUCHUMI KWA AJILI YA SERA NA MIPANGO YA MAENDELEO YENYE UFANISI TANZANIA",
      url: "/publication/POLICY_BRIEF_FINAL_Dec2023swahili.pdf",
    },
    {
      id: 22,
      title: "Wallchat__English",
      url: "/publication/Wallchat__English.pdf",
    },
    {
      id: 23,
      title: "Wallchat__Swahili",
      url: "/publication/Wallchat__Swahili.pdf",
    },
    {
      id: 11,
      title: "Matokeo ya mwanzo",
      url: "/publication/matokeo.pdf",
    },
    {
      id: 12,
      title: "Mwongozo wa matumimzi",
      url: "/publication/mwongozo.pdf",
    },
    {
      id: 1,
      title: "Administrative units Population Distribution  Report Tanzania",
      url: "/publication/volume1a.pdf",
    },
    {
      id: 2,
      title:
        "Administrative units Population Distribution  Report Tanzania Mainland",
      url: "/publication/volume1b.pdf",
    },
    {
      id: 3,
      title:
        "Administrative units Population Distribution  Report Tanzania Zanzibar",
      url: "/publication/volume1c.pdf",
    },
    {
      id: 4,
      title: "Age and Sex Distribution  Report Tanzania",
      url: "/publication/volume2a.pdf",
    },
    {
      id: 5,
      title: "Age and Sex Distribution  Report Tanzania Mainland",
      url: "/publication/volume2b.pdf",
    },
    {
      id: 6,
      title: "Age and Sex Distribution  Report Tanzania Zanzibar",
      url: "/publication/volume2c.pdf",
    },
    {
      id: 7,
      title: "Ripoti ya Idadi ya Watu katika Majimbo ya Uchaguzi Tanzania",
      url: "/publication/majimbo3a.pdf",
    },
    {
      id: 8,
      title:
        "Ripoti ya Idadi ya Watu katika Majimbo ya Uchaguzi Tanzania Zanzibar",
      url: "/publication/majimbo3b.pdf",
    },
    {
      id: 9,
      title: "Age and Sex Distribution Key Finding Report Tanzania",
      url: "/publication/report7.pdf",
    },
    {
      id: 10,
      title:
        "Report ya Mgawanyiko wa Idadi ya watu kwa Umri na Jinsi Tanzania (Matokeo Muhimu)",
      url: "/publication/report8.pdf",
    },
  ];

  const dodoso_kisw = [
    {
      id: 1,
      title: "Dodolo la Sensa",
      url: "/publication/Sensa_Kiswahili.pdf",
    },
    {
      id: 2,
      title: "Dodoso la Majengo",
      url: "/publication/Majengo_Kiswahili.pdf",
    },
    {
      id: 3,
      title: "Dodoso la Jamii",
      url: "/publication/huduma_za_jamii_Kiswahili.pdf",
    },
    {
      id: 4,
      title: "Dodoso la Makundi Maalum",
      url: "/publication/Makundi_Maalum_Kiswahili.pdf",
    },
  ];

  const dodoso_Eng = [
    {
      id: 1,
      title: "Census Questionnaire",
      url: "/publication/Sensa_English.pdf",
    },
    {
      id: 2,
      title: "Building Quastionnaire",
      url: "/publication/Majengo_English.pdf",
    },
    {
      id: 3,
      title: "Community Questionnaire",
      url: "/publication/huduma_za_jamii_English.pdf",
    },
    {
      id: 4,
      title: "Special Groups Questionnaire",
      url: "/publication/Makundi_Maalum_English.pdf",
    },
  ];

  return (
    <div className="w-full h-full p-5 pt-0">
      <div className="flex flex-col w-full gap-10">
        <div className="flex flex-col w-full gap-2">
          <p className="w-full border-b-2 border-b-[#E6E6E6] font-normal text-xl">
            {language === true ? "2022 Publications" : "Machapisho ya 2022"}
          </p>
          <div className="flex flex-wrap w-full justify-around g-2">
            {publication.map((item) => (
              <CensusReport
                key={item.id}
                img={item.img}
                name={item.title}
                url={item.url}
              />
            ))}
          </div>
          <br/>
          <br/>
          <br/>
          <div className="flex w-full justify-center items-center">
            <p>
              {language === true
                ? "More 2022 Census Publications"
                : "Machapisho0 Zaidi za Sensa 2022"}
            </p>
          </div>
          <div className="flex w-full flex-col gap-2 justify-center items-center">
            {publicationDist.map((item) => (
              <Link
                rel="noreferrer"
                target="_blank"
                key={item.id}
                href={item.url}
                underline="hover"
              >
                {item.title}
              </Link>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <p className="w-full border-b-2 border-b-[#E6E6E6] font-normal text-xl">
            {language === true ? "Questionares" : "Madodoso"}
          </p>
          <div className="flex flex-row w-full justify-around">
            <div className="flex w-full flex-col gap-2 justify-center items-center">
              {dodoso_kisw.map((item) => (
                <Link
                  rel="noreferrer"
                  target="_blank"
                  key={item.id}
                  href={item.url}
                  underline="hover"
                >
                  {item.title}
                </Link>
              ))}
            </div>
            <div className="flex w-full flex-col gap-2 justify-center items-center">
              {dodoso_Eng.map((item) => (
                <Link
                  rel="noreferrer"
                  target="_blank"
                  key={item.id}
                  href={item.url}
                  underline="hover"
                >
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <p className="w-full border-b-2 border-b-[#E6E6E6] font-normal text-xl">
            {language === true
              ? "Other Dissemination Channels"
              : "Njia Zingine za Kutangaza Matokeo"}
          </p>
          <div className="flex flex-row justify-around">
            <Link
              rel="noreferrer"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=tz.go.nbs.tphc22"
              underline="hover"
            >
              Matokeo ya Sensa App
              <img
                style={{ height: "10vh" }}
                src={playStore}
                alt="palystore-icon"
              />
            </Link>

            <div>
              <ol>
                <lh>
                  {language === true
                    ? "Census Info Via USSD"
                    : "Kupata taarifa kwa njia ya USSD"}
                </lh>
                <li>
                  {language === true ? "1. Press" : "1. Piga"}{" "}
                  <strong>*152*00#</strong>
                </li>
                <li>
                  {language === true ? "2. Then press" : "2. Kisha chagua"}{" "}
                  3.Ajira/Utambuzi
                </li>
                <li>
                  {language === true ? "3. Then press" : "3. Kisha chagua"}{" "}
                  5.Sensa 2022
                </li>
              </ol>
            </div>
            <Link
              rel="noreferrer"
              target="_blank"
              href="http://data.nbs.go.tz:81/trs"
              underline="hover"
            >
              {"Table Retrieval System (TRS)"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
