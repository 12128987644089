import { Typography } from "@mui/material";

export function CensusDisseminationDesktopTitle({ language, censuslogo }) {
  return (
    <>
      <div className="hidden md:flex flex-row items-center justify-center">
        <div className="justify-end basis-1/2">
          <Typography
            align="end"
            variant="h5"
            sx={{ fontWeight: "bold", color: "white" }}
          >
            CENSUS INFORMATION
          </Typography>
        </div>
        <img className="h-20 pr-[3vw] pl-[3vw]" src={censuslogo} alt="logo" />
        <div className="basis-1/2">
          <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
            DISSEMINATION PLATFORM
          </Typography>
        </div>
      </div>

      <div className="hidden md:flex flex-row items-center justify-center">
        <div className="w-[10%] h-0.5 bg-white"></div>
        <Typography
          className="pl-[3vw] pr-[3vw]"
          style={{ fontFamily: "cursive", color: "white" }}
        >
          {language === true ? "Census Results" : "Matokeo ya Sensa"}
        </Typography>
        <div className="w-[10%] h-0.5 bg-white"></div>
      </div>
    </>
  );
}
