import clsx from "clsx";
import { useNavigate } from "react-router-dom";

export function CensusDisseminationMenu({
    handleMenuClick,
    censusLayoutHook,
    menuList,
    menuList2,
    onClickMenuList2,
    language
  }) {
    const navigate = useNavigate()
    const navFn = path => navigate(`/dissemination/${path}`)
    return (
      <div className="">
        <div className="flex flex-col space-y-2 p-4">
          {menuList.map((menu, index) => (
            <div
              key={index}
              onClick={() => {
                handleMenuClick(index);
                navFn(menu.path)
              }}
              className={`
              w-full px-2 py-3 text-sm rounded-lg cursor-pointer hover:bg-[#0f7895] hover:text-white transition-all
              ${
                index === censusLayoutHook.selectedMainTabIndex &&
                "bg-[#0f7895] text-white"
              }
              `}
            >
              {menu?.label}
            </div>
          ))}
        </div>
  
        <hr className="text-[#0f7895]" />
  
        <div className="flex flex-col space-y-2 p-4">
          {menuList2.map((menu, index) => (
            <div
              key={index}
              onClick={() => {
                onClickMenuList2(menu)
              }}
              className={clsx("w-full px-2 py-3 text-sm rounded-lg cursor-pointer hover:bg-[#0f7895] hover:text-white transition-all", !menu?.show && "hidden")}
            >
              {language ? "Badili kwenda" : "Change to"} {menu.label}
            </div>
          ))}
        </div>
      </div>
    );
  }