import React, { useContext, useState } from 'react';
import CensusLandingNavBar from 'ui/components/census/CensusLandingNavbar';


import FacebookIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import PublicIcon from "@mui/icons-material/Public";
import TwitterIcon from "@mui/icons-material/Twitter";
import sensaLogo from "resources/img/censusSensaLogo.png";
import { DashboardContext } from 'states/DashboardContext';


function CensusTutorialPage() {

    const styles = {
        backgroundGradient: {
          background: `      
            linear-gradient(45deg, #fff 30%, transparent 70%), 
            linear-gradient(-45deg, #61A1F1 30%, transparent 70%), 
            linear-gradient(-45deg, #fff 30%, transparent 70%), 
            linear-gradient(35deg, #6CEB9A 30%, transparent 70%),         
            linear-gradient(185deg, #fff 30%, transparent 70%),         
            linear-gradient(-135deg, #6CEB9A 30%, transparent 70%), 
            linear-gradient(135deg, #fff 30%, transparent 70%), 
            linear-gradient(9deg, #fff 30%, transparent 70%),                        
            linear-gradient(-135deg, #fff 30%, transparent 70%)
          `
        },};
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

const words = useTranslations();

  const videos = [
    {
      title: "Explore the Census Hubs Capabilities",
      description: "Gain a more comprehensive understanding of how to go about using all the provide information and features in the Dissemination Platform",
      thumbnail: "path/to/thumbnail1.jpg",
      src: "path/to/video1.mp4"
    },
    // ... more videos
    {
        title: "Explore the Census Hubs Capabilities",
        description: "Gain a more comprehensive understanding of how to go about using all the provide information and features in the Dissemination Platform",
        thumbnail: "path/to/thumbnail1.jpg",
        src: "path/to/video1.mp4"
      },
      {
        title: "Explore the Census Hubs Capabilities",
        description: "Gain a more comprehensive understanding of how to go about using all the provide information and features in the Dissemination Platform",
        thumbnail: "path/to/thumbnail1.jpg",
        src: "path/to/video1.mp4"
      },
  ];

  return (
    <div className="flex flex-col min-h-screen" style={styles.backgroundGradient}>
            <CensusLandingNavBar />
            
            {/* Main Content */}
            <div className="flex flex-col items-center space-y-6 mt-10 px-4">
                <h1 className="text-5xl  text-[#0f7895] font-bold mb-6">{words.title}</h1>
                {videos.map(video => (
                    <div key={video.title} className="flex flex-col space-x-4 md:space-x-0 md:flex-row w-full md:w-3/4 lg:w-1/2">
                        <div className="w-96 h-56 cursor-pointer" onClick={() => {
                            setSelectedVideo(video);
                            setIsModalOpen(true);
                        }}>
                            <img src={video.thumbnail} alt="Video Thumbnail" className="w-full h-full object-cover" />
                        </div>
                        <div className="flex flex-col space-y-2">
                            <h3 className="text-xl font-bold">{video.title}</h3>
                            <p className="text-gray-600">{video.description}</p>
                        </div>
                    </div>
                ))}
                {isModalOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="relative bg-white p-4">
                            <button onClick={() => setIsModalOpen(false)} className="absolute top-2 right-2 text-xl font-bold">X</button>
                            <video width="1020" height="1050" controls>
                                <source src={selectedVideo?.src} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                )}
            </div>
       {/* Footer */}
       <div className="flex-col mt-auto justify-between items-center">
      <div className="flex my-8 border-t border-black w-full"></div>

      <footer className="flex p-4 mt-auto md:px-10 justify-between items-center">
        <div className="flex space-x-2 md:space-x-4">
          <a href="#" className="mx-2">
            <FacebookIcon fontSize="large" className="rounded-full" />
          </a>
          <a href="#" className="mx-2">
            <TwitterIcon fontSize="large" className="rounded-full" />
          </a>
          <a href="#" className="mx-2">
            <InstagramIcon fontSize="large" className="rounded-full" />
          </a>
          <a href="#" className="mx-2">
            <PublicIcon fontSize="large" className="rounded-full" />
          </a>
        </div>
        <img src={sensaLogo} alt="logo" className="w-12 h-12 md:w-24 md:h-24 rounded-full" />
      </footer>

      </div>
        </div>
    );
}

export default CensusTutorialPage;


const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    title: language ? 'Tutorial Videos' : 'Video za Mafunzo',
    language
  };
};
