import { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { CensusPageNotFound } from "ui/views/census/CensusPageNotFound";
import { DashboardProvider } from "./states/DashboardContext";
import CensusLayoutWrapper from "./ui/views/census/CensusLayoutWrapper";
import CensusLayoutWrapper2 from "./ui/views/census/CensusLayoutWrapper2";
import CensusLandingPage from "ui/views/census/CensusLandingPage";
import CensusStory from "ui/views/census/CensusStory";
import CensusDataSharing from "ui/views/census/CensusDataSharing";
import CensusTutorialPage from "ui/views/census/CensusTutorialPage";
import CensusHomePage from "ui/views/census/CensusHomePage";
import CensusMapView from "ui/views/census/CensusMapView";
import CensusResults, {
  CensusResultsBuildings,
  CensusResultsPopulation,
  CensusResultsPyramidAndGroups,
  CensusResultsServices,
} from "ui/views/census/CensusResults";
import CensusDemographyVisual from "ui/views/census/CensusVisuals/CensusDemographyVisual";
import CensusVisulaizationsMajengo from "ui/views/census/CensusVisualizationsMajengo/CensusVisulaizationsMajengo";
import CensusVisulaizationsCommunityServices from "ui/views/census/CensusVisualizationsCommunityServices/CensusVisulaizationsCommunityServices";
import CensusPublication from "ui/views/census/CensusPublication";
import { CensusVisualization } from "ui/views/census/CensusVisualization";
import { DataSharingMarkdown } from "ui/views/census/DataSharingMarkdown";
// Create new QueryClient
const queryClient = new QueryClient();

const MyApp = () => {
  // Subdomain routing boolean
  var [subDomain, setSubDomain] = useState(false);

  if (typeof window !== "undefined") {
    const [subdomain] = window.location.hostname.split(".");
    if (subdomain && subdomain.length > 1) {
      if (subdomain === "zanzibar") setSubDomain(true);
    }
  }

  return (
    <div className="h-full w-full">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route element={<MainLayout />}>
              <Route
                path="/"
                element={
                  <DashboardProvider>
                    <CensusLandingPage />
                  </DashboardProvider>
                }
              />

              <Route
                path="/dissemination"
                element={
                  <DashboardProvider>
                    <CensusLayoutWrapper />
                  </DashboardProvider>
                }
              >
                <Route index element={<CensusHomePage />} />
                <Route path="dashboard" element={<CensusMapView />} />
                <Route path="publications" element={<CensusPublication />} />
                <Route path="results" element={<CensusResults />}>
                  <Route index element={<CensusResultsPopulation />} />
                  <Route
                    path="population-and-groups"
                    element={<CensusResultsPyramidAndGroups />}
                  />
                  <Route
                    path="buildings"
                    element={<CensusResultsBuildings />}
                  />
                  <Route path="services" element={<CensusResultsServices />} />
                </Route>
                <Route path="visualization" element={<CensusVisualization />}>
                  <Route index element={<CensusDemographyVisual />} />
                  <Route
                    path="buildings"
                    element={<CensusVisulaizationsMajengo />}
                  />
                  <Route
                    path="services"
                    element={<CensusVisulaizationsCommunityServices />}
                  />
                </Route>
              </Route>

              <Route
                path="/publications"
                element={
                  <DashboardProvider>
                    <CensusLayoutWrapper2 />
                  </DashboardProvider>
                }
              />

              <Route
                path="/story"
                element={
                  <DashboardProvider>
                    <CensusStory />
                  </DashboardProvider>
                }
              />
              <Route
                path="/data-sharing"
                element={
                  <DashboardProvider>
                    <CensusDataSharing />
                  </DashboardProvider>
                }
              >
                <Route
                  index
                  element={
                    <Navigate to={"/data-sharing/introduction"} replace />
                  }
                />
                <Route
                  path="/data-sharing/:markdownKey"
                  element={<DataSharingMarkdown />}
                />
              </Route>

              <Route
                path="/tutorials"
                element={
                  <DashboardProvider>
                    <CensusTutorialPage />
                  </DashboardProvider>
                }
              />
              <Route path="*" element={<CensusPageNotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
};

export default MyApp;

function MainLayout() {
  return (
    <div className="w-full bg-[#0f7895]">
      <Outlet />
    </div>
  );
}
