import { useContext } from "react";
import CensusPrimarySchools from "resources/img/CensusPrimarySchools.png";
import CensusSecondarySchools from "resources/img/CensusSecondarySchools.png";
import { DashboardContext } from "states/DashboardContext";
import CensusInfoCrds from "ui/components/census/CensusInfoCrds";
import { Info } from "../buildingsTanzania/BuildingsTanzania";

function CommunityServicesSchools() {
  const words = useTranslations();

  return (
    <>
      <div className="flex flex-col w-full gap-4 p-4 font-bold bg-white shadow-xl rounded-xl">
        <p className="w-full border-b-2 border-b-[#E6E6E6] text-xl font-normal">
          {words.socialServices}
        </p>
        <p className="text-lg font-[500]">B. Idadi ya Shule, Tanzania</p>
        <div className="flex flex-col w-full gap-3 py-4 md:items-center md:flex-row justify-evenly">
          <Info
            image={CensusPrimarySchools}
            label={words.primarySchool}
            data="7,132"
          />
          <Info
            image={CensusSecondarySchools}
            label={words.secondarySchool}
            data="976"
          />
        </div>
      </div>

      <div className="flex flex-col w-full gap-4 p-4 bg-white shadow-xl rounded-xl">
        <p className="w-full border-b-2 border-b-[#E6E6E6] text-xl font-normal">
          B. Idadi ya Shule
        </p>
        <CensusInfoCrds
          cardData={[
            {
              label: words.tanzaniaMainland,
              data: [
                {
                  image: CensusPrimarySchools,
                  count: "5,874",
                  label: words.primarySchool,
                },
                {
                  image: CensusSecondarySchools,
                  count: "685",
                  label: words.secondarySchool,
                },
              ],
            },
            {
              label: "Tanzania Zanzibar",
              data: [
                {
                  image: CensusPrimarySchools,
                  count: "1,268",
                  label: words.primarySchool,
                },
                {
                  image: CensusSecondarySchools,
                  count: "291",
                  label: words.secondarySchool,
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
}

export default CommunityServicesSchools;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    socialServices: language ? "Social Services" : "Huduma za Jamii",
    section: language ? "Number of schools" : "Idadi ya shule",
    primarySchool: language ? "Primary School" : "Shule ya msingi",
    secondarySchool: language ? "Secondary School" : "Shule ya sekondari",
    tanzaniaMainland: language ? "Tnzania Mainland" : "Tanzania Bara",
    language,
  };
};

// -------------------- School card item components ------------------------
export const SchoolCardLabel = ({ label = "" }) => (
  <p className="text-xl capitalize">{label}</p>
);
export const SchoolCardImage = ({ image, biggerImage }) => (
  <div className={`h-[100px] aspect-square flex items-center justify-start`}>
    <img
      src={image}
      alt=""
      className={`${biggerImage ? "h-full" : "h-[90%]"} object-contain`}
    />
  </div>
);
export const SchoolCardContainer = ({ children }) => (
  <div className="w-full min-w-[50vh] flex flex-col gap-2 mb-3 px-5">
    {children}
  </div>
);
