import { useContext } from "react";
import censusFemaleLogo from "resources/img/censusFemale.png";
import censusMaleLogo from "resources/img/censusMale.png";
import censusPopulationLogo from "resources/img/censusPopulation.png";
import { DashboardContext } from "states/DashboardContext";
import CensusInfoCrds from "ui/components/census/CensusInfoCrds";

function PeopleCount() {
  const words = useTranslations();
  return (
    <>
      <div className="flex flex-col w-full gap-4 p-4 font-bold bg-white shadow-xl rounded-xl">
        <p className="w-full border-b-2 border-b-[#E6E6E6] font-normal text-xl">
          {words.countLabel}
        </p>
        <div className="flex flex-col items-center w-full gap-2 md:flex-row">
          <div className="w-full md:w-[50%] flex gap-2 items-center justify-center">
            <div className="h-[150px] aspect-square flex items-center justify-center">
              <img
                src={censusPopulationLogo}
                alt=""
                className="object-contain h-full"
              />
            </div>
            <div>
              <div>
                <p className="text-sm font-normal">{words.population}</p>
                <p className="text-3xl">61,741,120</p>
              </div>
              <div className="flex flex-row gap-5">
                <div>
                  <p className="text-sm font-normal">{words.urban}</p>
                  <p className="text-xl">21,544,623</p>
                </div>
                <div>
                  <p className="text-sm font-normal">{words.rural}</p>
                  <p className="text-xl">40,196,497</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-[50%] flex flex-col gap-4 py-[3%] items-center justify-center divide-y-2">
            <div className="flex items-center justify-center w-full gap-2">
              <div className="h-[80px] aspect-square flex items-center justify-center">
                <img
                  src={censusMaleLogo}
                  alt=""
                  className="object-contain h-full"
                />
              </div>
              <div>
                <div>
                  <p className="text-sm font-normal">{words.male}</p>
                  <p className="text-xl">30,053,130</p>
                </div>
                <div className="flex flex-row gap-5">
                  <div>
                    <p className="text-sm font-normal">{words.urban}</p>
                    <p className="text-l"> 10,333,679  </p>
                  </div>
                  <div>
                    <p className="text-sm font-normal">{words.rural}</p>
                    <p className="text-l"> 19,719,451 </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center w-full gap-2">
              <div className="h-[80px] aspect-square flex items-center justify-center">
                <img
                  src={censusFemaleLogo}
                  alt=""
                  className="object-contain h-full"
                />
              </div>
              <div>
                <div>
                  <p className="text-sm font-normal">{words.female}</p>
                  <p className="text-xl">31,687,990</p>
                </div>
                <div className="flex flex-row gap-5">
                  <div>
                    <p className="text-sm font-normal">{words.urban}</p>
                    <p className="text-l"> 11,210,944 </p>
                  </div>
                  <div>
                    <p className="text-sm font-normal">{words.rural}</p>
                    <p className="text-l"> 20,477,046   </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full gap-4 p-4 bg-white shadow-xl rounded-xl">
        <CensusInfoCrds
          cardData={[
            {
              label: words.tanzaniaMainLand,
              data: [
                {
                  image: censusPopulationLogo,
                  count: "59,851,347",
                  total: true,
                  label: `${words.population}`,
                  urban: `${words.urban}`,
                  urbanValue: '20,618,348',
                  rural: `${words.rural}`,
                  ruralValue: '39,232,999'
                },
                {
                  image: censusMaleLogo,
                  count: "29,137,638",
                  label: `${words.male}`,
                  urban: `${words.urban}`,
                  urbanValue: '9,890,218',
                  rural: `${words.rural}`,
                  ruralValue: '19,247,420'
                },
                {
                  image: censusFemaleLogo,
                  count: "30,713,709",
                  label: `${words.female}`,
                  urban: `${words.urban}`,
                  urbanValue: '10,728,130   ',
                  rural: `${words.rural}`,
                  ruralValue: '19,985,579'
                },
              ],
            },
            {
              label: "Tanzania Zanzibar",
              data: [
                {
                  image: censusPopulationLogo,
                  count: "1,889,773",
                  total: true,
                  label: `${words.population}`,
                  urban: `${words.urban}`,
                  urbanValue: '926,275',
                  rural: `${words.rural}`,
                  ruralValue: '963,498'
                },
                {
                  image: censusMaleLogo,
                  count: "915,492",
                  label: `${words.male}`,
                  urban: `${words.urban}`,
                  urbanValue: '443,461',
                  rural: `${words.rural}`,
                  ruralValue: '472,031'
                },
                {
                  image: censusFemaleLogo,
                  count: "974,281",
                  label: `${words.female}`,
                  urban: `${words.urban}`,
                  urbanValue: '482,814',
                  rural: `${words.rural}`,
                  ruralValue: '491,467'
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
}

export default PeopleCount;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    population: language ? "Total Population" : "Idadi ya watu wote",
    male: language ? "Male" : "Wanaume",
    female: language ? "Female" : "Wanawake",
    urban: language ? "Urban" : "Mjini",
    rural: language ? "Rural" : "Vijijini",
    tanzaniaMainLand: language ? "Tanzania Mainland" : "Tanzania Bara",
    countLabel: language
      ? "Population Size in Tanzania"
      : "Idadi ya Watu, Tanzania",
    language,
  };
};
