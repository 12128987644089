import React, { useContext } from "react";
import { DashboardContext } from "states/DashboardContext";
import DrillMap from "../../elements/census/DrillMap";

export default function CensusMapWithLegend() {
  // global state
  const { censusYearSliderValueState } = useContext(DashboardContext);
  const [censusYearSliderValue, setCensusYearSliderValue] =
    censusYearSliderValueState;
  return (
      <DrillMap
        year={parseInt(
          censusYearSliderValue !== "" ? censusYearSliderValue.trim() : 0
        )}
        mapHeight={"450"}
      />
  );
}
