export const Card = ({
  label = "",
  children = null,
  labelBackground = "#0F7995",
}) => {
  return (
    <div className="flex flex-col min-w-[300px] shadow-lg border border-gray-300 rounded-b-lg">
      <div
        style={{ backgroundColor: labelBackground }}
        className={`py-3 w-full flex items-center px-4 rounded-t-lg`}
      >
        <p className="text-2xl font-bold text-white">{label}</p>
      </div>
      <div className="p-5">{children}</div>
    </div>
  );
};

export const CardRow = ({ Icon, value: { count, percentage }, large }) => {
  return (
    <div className="flex items-center gap-2">
      {Icon}
      <div className="flex items-center gap-3">
        <p
          className={`${
            large && large === true ? "text-2xl" : " text-xl"
          } font-bold`}
        >
          {count}
        </p>
        {percentage && <p>({percentage}%)</p>}
      </div>
    </div>
  );
};
