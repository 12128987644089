import React from "react";
import CensusBarGraph from "../../elements/census/CensusBarGraph";

export default function CensusBarChartWithLegend() {
  return (
      
      <div className="w-full">
        <CensusBarGraph />
      </div>
  );
}
