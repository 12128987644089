import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useContext } from "react";
import { DashboardContext } from "../../../states/DashboardContext";

const marks = [
  {
    value: 10,
    label: "1967",
  },
  {
    value: 20,
    label: "1978",
  },
  {
    value: 30,
    label: "1988",
  },
  {
    value: 40,
    label: "2002",
  },
  {
    value: 50,
    label: "2012",
  },
  {
    value: 60,
    label: "2022",
  },
];

export default function YearSlider(props) {
  // import global state
  const { censusYearSliderValueState } = useContext(DashboardContext);
  const [censusYearSliderValue, setCensusYearSliderValue] =
    censusYearSliderValueState;

  // function handleChange(value) {
  //   // return the respective time value chosen on slider
  //   setCensusYearSliderValue(marks.find((e) => e.value === value).label);
  // }

  return (
    <Box sx={{ height: "inherit" }}>
      <Slider
        aria-label="Years"
        defaultValue={60}
        step={10}
        marks={marks}
        onChangeCommitted={(event, value, _) => {props.handleChange(marks.find((e) => e.value === value).label)}}
        min={0}
        max={70}
        style={{
          color: "#0F7995",
        }}
      />
    </Box>
  );
}
