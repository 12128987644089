import React, { useContext } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Typography } from "@mui/material";
import { DashboardContext } from "states/DashboardContext";

export default function CensusVisualPopulationSize(props) {
  const words = useTranslations();
  const options = {
    chart: {
      type: "column",
      height: "300px",
    },
    title: false,
    credits: {
      enabled: false,
    },

    xAxis: {
      categories: [`${words.total}`, `${words.male}`, `${words.female}`],
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    yAxis: {
      min: 0,
      title: { enabled: false },
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    legend: {
      itemStyle: {
        fontSize: "14px",
      },
      itemDistance: 20,
    },
    series: [
      {
        name: "2012",
        data: [props.total["2012"], props.male["2012"], props.female["2012"]],
        colorByPoint: true, // assign colors by category
        dataLabels: {
          enabled: true,
          color: "#000000",
          style: {
            textOutline: false,
            fontSize: "12px",
          },
          format: "{series.name}",
        },
      },
      {
        name: "2022",
        data: [props.total["2022"], props.male["2022"], props.female["2022"]],
        dataLabels: {
          enabled: true,
          color: "#000000",
          style: {
            textOutline: false,
            fontSize: "12px",
          },
          format: "{series.name}",
        },
        colorByPoint: true, // assign colors by category
      },
    ],
    colors: ["#0F7995", "#029EF5", "#E067BE"],
    legend: {
      enabled: false, // disable the legend
    },
  };
  return (
    <div className="flex flex-col w-full p-5">
      <Typography>{props.population}</Typography>
      <div className="w-full">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    total: language ? "Total" : "Jumla",
    male: language ? "Male" : "Wanaume",
    female: language ? "Female" : "Wanawake",
    urban: language ? "Urban" : "Mjini",
    rural: language ? "Rural" : "Vijijini",
    populationSize: language ? "Population Size" : "Idadi ya watu",

    noData: language ? "No Data " : "Hakuna Taarifa",
    language,
  };
};
