// import store from '@/core/store'
import axios from 'axios'
// import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
// import { getToken, setToken } from '@/utils/auth'

let requestData
let loadingE

/* axios default headers for the below initiated instances */
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
//
axios.defaults.headers['Access-Control-Allow-Methods'] = 'POST, PUT, GET, OPTIONS, DELETE'
// axios.defaults.headers['Access-Control-Allow-Credentials'] = true
// axios.defaults.headers['Cache-Control'] = 'no-cache'
axios.defaults.headers['Access-Control-Max-Age'] = 3600
axios.defaults.headers['Access-Control-Allow-Headers'] = 'access-control-allow-credentials, access-control-allow-methods, authorization, cache-control, content-disposition'

const service = axios.create({
    'X-Requested-With': 'XMLHttpRequest',
    baseURL: process.env.BASE_URL,
    timeout: 30000 // 超时时间

})

service.interceptors.request.use(
    (request) => {
        /* Check if the header is predefined in the api js file */
        if (request.headers['Authorization'] === undefined) {
            // request.headers['Authorization'] = `Bearer ${getToken()}`
        }
        /* Check if the request is set as a download file */
        if (request.isDownLoadFile) {
            request.responseType = 'blob'
        }
        /* Check if the request is set as a upload file */
        if (request.isUploadFile) {
            request.headers['Content-Type'] = 'multipart/form-data'
        }
        requestData = request


        if (request.isParams) {
            request.params = request.data
            request.data = {}
        }
        return request
    },
    (err) => {
        Promise.reject(err)
    }
)
// 响应拦截
service.interceptors.response.use(
    (res) => {
        if (requestData.afHLoading && loadingE) {
            loadingE.close()
        }
        // 如果是下载文件直接返回
        if (requestData.isDownLoadFile) {
            return res.data
        }
         const { msg, isNeedUpdateToken, updateToken, status } = res
        // // 更新token保持登录状态
        // if (isNeedUpdateToken) {
        //     setToken(updateToken)
        // }
        if (status === 200 || status === 201) {
            return res.data
        } else {
            if (requestData.isAlertErrorMsg) {
                // ElMessage({
                //     message: msg,
                //     type: 'error',
                //     duration: 2 * 1000
                // })
                return Promise.reject(msg)
            } else {
                return res.data
            }
        }
    },
    (err) => {
        if (loadingE) loadingE.close()
        if (err && err.response && err.response.code) {
            if (err.response.code === 403) {
                // ElMessageBox.confirm('Please login to continue', {
                //     confirmButtonText: 'Sign in again',
                //     cancelButtonText: 'Cancel',
                //     type: 'warning'
                // }).then(() => {
                //     store.dispatch('authentication/user/logout').then(() => {
                //         location.reload()
                //     })
                // })
            }
            else {
                // ElMessage({
                //     message: err,
                //     type: 'error',
                //     duration: 2 * 1000
                // })
            }
        } else {
            if (!requestData.url.includes('revoke')) {
                if (requestData.isAlertErrorMsg) {
                    // ElMessage({
                    //     message: err.response.data.error_description || err,
                    //     type: 'error',
                    //     duration: 2 * 1000
                    // })
                }

            }
        }
        return Promise.reject(err)
    }
)

export default function request({
                                    url, data, method, isParams, bfLoading,
                                    afHLoading, isUploadFile, isDownLoadFile,
                                    baseURL, timeout, isAlertErrorMsg, headers
                                }) {
    return service({
        url: url,
        method: method ?? 'post',
        data: data ?? {},
        headers: headers ?? {
            'Content-Type': 'application/json;charset=utf-8'
        },
        isParams: isParams ?? false,
        bfLoading: bfLoading ?? true,
        afHLoading: afHLoading ?? true,
        isUploadFile: isUploadFile ?? false,

        isDownLoadFile: isDownLoadFile ?? false,
        isAlertErrorMsg: isAlertErrorMsg ?? true,
        baseURL: baseURL ?? process.env.BASE_URL, // 设置基本基础url
        timeout: timeout ?? 15000 // 配置默认超时时间
    })
}
