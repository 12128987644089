import React from "react";
import CensusPyramid from "ui/elements/census/CensusPyramid";
import { useContext, useState } from "react";
import { DashboardContext } from "states/DashboardContext";
import CencusButton from "ui/elements/census/CencusButton";
import PopulationSpecificGroups from "./populationSpecificGroups";

export default function PopulationPyramid2() {
  const words = useTranslations();
  // visual type
  const [selectedVisualButton, setSelectedVisualButton] = useState(0);
  const handleSelectedVisualChange = (index) => setSelectedVisualButton(index);
  const visualType = ["Pyramids", "Specific Groups"];

  // sub groups
  const [selectedButton, setSelectedButton] = useState(0);
  const handleSelectedButtonChange = (index) => setSelectedButton(index);
  const subGroup = ["Total", "Urban", "Rural"];

  const dataTotal = {
    tzmaleValue: [
      4723359, 4434204, 4035586, 3096582, 2560797, 2220568, 1902825, 1532164,
      1315193, 1121993, 906162, 616939, 555227, 329847, 288954, 166357, 246373,
    ],
    tzfemaleValue: [
      4760812, 4484375, 3961652, 3185807, 3005179, 2507604, 2053133, 1676186,
      1396308, 1168071, 947236, 636403, 595847, 374238, 337913, 207937, 389289,
    ],
    mainmaleValue: [
      4584972, 4302575, 3919610, 3000548, 2474251, 2146065, 1840529, 1483255,
      1275092, 1089463, 878677, 596380, 539602, 320464, 281115, 162537, 242503,
    ],
    mainfemaleValue: [
      4622460, 4352161, 3846602, 3082947, 2905972, 2423957, 1984387, 1620278,
      1351839, 1132051, 916837, 613975, 580548, 363975, 329640, 202939, 383141,
    ],
    znzmaleValue: [
      138387, 131629, 115976, 96034, 86546, 74503, 62296, 48909, 40101, 32530,
      27485, 20559, 15625, 9383, 7839, 3820, 3870,
    ],
    znzfemaleValue: [
      138352, 132214, 115050, 102860, 99207, 83647, 68746, 55908, 44469, 36020,
      30399, 22428, 15299, 10263, 8273, 4998, 6148,
    ],
  };

  const dataUrban = {
    tzmaleValue: [
      1449008, 1313444, 1156374, 1035102, 1044277, 967785, 817904, 635170,
      523371, 419540, 317041, 204211, 171289, 101497, 80517, 42868, 54281,
    ],
    tzfemaleValue: [
      1455835, 1349838, 1224752, 1221172, 1274661, 1107584, 881704, 681230,
      541273, 419858, 314061, 204606, 182355, 110027, 92420, 53813, 95755,
    ],

    mainmaleValue: [
      1385718, 1254222, 1105367, 989229, 998059, 927615, 785924, 635170, 502750,
      402331, 302573, 193927, 163666, 96998, 77006, 41270, 52764,
    ],
    mainfemaleValue: [
      1392457, 1289267, 1171658, 1168548, 1220660, 1062229, 845721, 652883,
      517924, 401207, 298638, 193676, 174548, 105432, 88470, 51711, 93101,
    ],

    znzmaleValue: [
      63290, 59222, 51007, 45873, 46218, 40170, 31980, 24371, 20621, 17209,
      14468, 10284, 7623, 4499, 3511, 1598, 1517,
    ],
    znzfemaleValue: [
      63378, 60571, 53094, 52624, 54001, 45355, 35983, 28347, 23349, 18651,
      15423, 10930, 7807, 4595, 3950, 2102, 2654,
    ],
  };

  const dataRural = {
    tzmaleValue: [
      3274351, 3120760, 2879212, 2061480, 1516520, 1252783, 1084921, 896994,
      791822, 702453, 589121, 412728, 383938, 228350, 208437, 123489, 192092,
    ],
    tzfemaleValue: [
      3304977, 3134537, 2736900, 1964635, 1730518, 1400020, 1171429, 994956,
      855035, 748213, 633175, 431797, 413492, 264211, 245493, 154124, 293534,
    ],

    mainmaleValue: [
      3199254, 3048353, 2814243, 2011319, 1476192, 1218450, 1054605, 872456,
      772342, 687132, 576104, 402453, 375936, 223466, 204109, 121267, 189739,
    ],
    mainfemaleValue: [
      3230003, 3062894, 2674944, 1914399, 1685312, 1361728, 1138666, 967395,
      833915, 730844, 618199, 420299, 406000, 258543, 241170, 151228, 290040,
    ],

    znzmaleValue: [
      75097, 72407, 64969, 50161, 40328, 34333, 30316, 24538, 19480, 15321,
      13017, 10275, 8002, 4884, 4328, 2222, 2353,
    ],
    znzfemaleValue: [
      74974, 71643, 61956, 50236, 45206, 38292, 32763, 27561, 21120, 17369,
      14976, 11498, 7492, 5668, 4323, 2896, 3494,
    ],
  };

  const data = {
    Total: dataTotal,
    Urban: dataUrban,
    Rural: dataRural,
  };

  return (
    <div className="flex flex-col h-full w-full gap-4">
      <div className="flex items-center gap-2">
        {visualType.map((data, index) => {
          return (
            <CencusButton
              key={index}
              name={data}
              handleClick={() => handleSelectedVisualChange(index)}
              active={selectedVisualButton === index}
            />
          );
        })}
      </div>
      <div style={{display: selectedVisualButton === 1 ? 'none': undefined}} className="flex items-center gap-2">
        {subGroup.map((data, index) => {
          return (
            <CencusButton
              key={index}
              name={data}
              handleClick={() => handleSelectedButtonChange(index)}
              active={selectedButton === index}
            />
          );
        })}
      </div>
      <div>
        {selectedVisualButton == 0 ? (
          <div>
            <div className="flex flex-col w-full justify-center bg-white p-4 gap-4 rounded-xl shadow-xl ">
              <p className="w-full border-b-2 border-b-[#E6E6E6] font-normal text-xl">
                {words.countLabel}, <strong>Tanzania</strong>
              </p>

              <CensusPyramid
                maleValue={data[`${subGroup[selectedButton]}`].tzmaleValue}
                femaleValue={data[`${subGroup[selectedButton]}`].tzfemaleValue}
              />
            </div>
            <div className="flex justify-between flex-col lg:flex-row w-full bg-white rounded-xl shadow-xl">
              <div className="flex flex-col p-4 w-full justify-center">
                <p className="w-full border-b-2 border-b-[#E6E6E6] font-normal text-xl">
                  {words.tanzaniaMainLand}
                </p>
                <CensusPyramid
                  maleValue={data[`${subGroup[selectedButton]}`].mainmaleValue}
                  femaleValue={
                    data[`${subGroup[selectedButton]}`].mainfemaleValue
                  }
                />
              </div>
              <div className="flex flex-col p-4 w-full">
                <p className="w-full border-b-2 border-b-[#E6E6E6] font-normal text-xl">
                  Tanzania Zanzibar
                </p>
                <CensusPyramid
                  maleValue={data[`${subGroup[selectedButton]}`].znzmaleValue}
                  femaleValue={
                    data[`${subGroup[selectedButton]}`].znzfemaleValue
                  }
                  male={words.male}
                  female={words.female}
                />
              </div>
            </div>
          </div>
        ) : (
          <PopulationSpecificGroups />
        )}
      </div>
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    male: language ? "Male" : "Wanaume",
    female: language ? "Female" : "Wanawake",
    urban: language ? "Urban" : "Mjini",
    rural: language ? "Rural" : "Vijijini",
    tanzaniaMainLand: language ? "Tanzania Mainland" : "Tanzania Bara",
    countLabel: language
      ? "Population Pyramid by Age Group and Sex"
      : "Piramidi ya Idadi ya Watu kwa Makundi ya umri na Jinsia",
    language,
  };
};
