import { Typography } from "@mui/material";
import * as React from "react";
import { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import tzFlag from "resources/img/censusTzFlag.png";
import ukFlag from "resources/img/censusUkFlag.png";
import { CensusDisseminationDesktopTitle } from "ui/components/census/CensusDisseminationDesktopTitle";
import { CensusDisseminationMenu } from "ui/components/census/CensusDisseminationMenu";
import { CensusDisseminationMobileTitle } from "ui/components/census/CensusDisseminationMobileTitle";
import { CensusGlobalHamburgerMenu } from "ui/components/census/CensusHamburgerMenu";
import { CensusMaterialTabs } from "ui/components/census/CensusMaterialTabs";
import { DashboardContext } from "../../../states/DashboardContext";
import CensusGuideModal from "../../elements/census/CensusGuideModal";
import censuslogo from "../../../resources/img/censusIconON.png";
import clsx from "clsx";
import newGif from "../../../resources/img/CensusNewGif.gif";
import Badge from "@mui/material/Badge";

export default function CensusLayoutWrapper(props) {
  // import global state
  const { censusGuideModalState, languageState } = useContext(DashboardContext);
  const [censusGuideModal, setCensusGuideModal] = censusGuideModalState;
  const [language, setLanguage] = languageState;

  // Click to open census guide modal
  function handleOpen() {
    setCensusGuideModal(true);
  }
  function changeLanguage(isLanguage) {
    if (isLanguage) {
      setLanguage(true);
    } else {
      setLanguage(false);
    }
  }

  // Local
  const censusLayoutHook = useCensusLayoutWrapper();

  // mobile menu toggler
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);

  // mobile menu overlay
  const Overlay = ({ onClick }) => {
    return (
      <div
        onClick={onClick}
        className="fixed top-0 left-0 bottom-0 right-0 cursor-pointer bg-transparent z-[899]"
      />
    );
  };

  const menuList =
    language === true
      ? censusLayoutHook.mainMenuItems
      : censusLayoutHook.menuItemsSwahili;

  const menuList2 = [
    {
      label: "English",
      onClick: () => changeLanguage(true),
      show: !language
    },
    {
      label: "Kiswahili",
      onClick: () => changeLanguage(false),
      show: language
    },
  ];

  // mobile menu card
  const handleMenuClick = (index) => {
    censusLayoutHook.setSelectedMainTabIndex(index);
    setShowMobileMenu(false);
    // handling background scrolling
    if (!showMobileMenu) {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "scroll";
  };

  const onClickMenuList2 = (menu) => {
    menu?.onClick();
    setShowMobileMenu(false);
    // handling background scrolling
    if (!showMobileMenu) {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "scroll";
  };

  const navigate = useNavigate();

  return (
    <div className="w-full bg-[#EFEFEF] ">
      <div className="w-full bg-[#0f7895]">
        {/* Guide Modal */}
        <CensusGuideModal />

        <div className="w-full relative flex  bg-[#0f7895] py-4 text-white  items-center justify-center px-4">
          <CensusDisseminationMobileTitle
            language={language}
            censuslogo={censuslogo}
          />
        </div>

        <div className="flex flex-row">
          <div className="mt-2">
            <CensusGlobalHamburgerMenu
              CensusDisseminationMenu={
                <CensusDisseminationMenu
                  {...{
                    handleMenuClick,
                    censusLayoutHook,
                    menuList,
                    menuList2,
                    onClickMenuList2,
                    language
                  }}
                />
              }
            />
          </div>
          <div className="flex-1 mx-auto">
            <CensusDisseminationDesktopTitle
              censuslogo={censuslogo}
              language={language}
            />
          </div>
        </div>

        <div className="hidden md:flex flex-col items-center justify-between gap-4 p-4 lg:p-0 w-full lg:flex-row">
          <CensusMaterialTabs
            value={censusLayoutHook.selectedMainTabIndex}
            handler={censusLayoutHook.handleMainTabChange}
            options={
              language === true
                ? censusLayoutHook.mainMenuItems
                : censusLayoutHook.menuItemsSwahili
            }
            navFn={(path) => {
              navigate(`/dissemination/${path}`);
            }}
          />
          <nav className="flex items-center gap-6 mr-4">
            <div
              className="cursor-pointer flex gap-3 items-center"
              onClick={() => changeLanguage(true)}
            >
              <Typography color={"#fff"}>English</Typography>
              <img src={ukFlag} alt="UK Flag" className={clsx("w-6 rounded-full", language && "outline outline-white outline-offset-2")} />
            </div>
            <div
              className="cursor-pointer flex gap-3 items-center"
              onClick={() => changeLanguage(false)}
            >
              <Typography color={"#fff"}>Kiswahili</Typography>
              <img
                src={tzFlag}
                alt="Tanzania Flag"
                className={clsx("w-6 rounded-full", !language && "outline outline-white outline-offset-4")}
              />
            </div>
          </nav>
        </div>
      </div>

      <Outlet />
    </div>
  );
}

export function useCensusLayoutWrapper() {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  const mainMenuItems = [
    { label: "Home", path: "." },
    { label: "Dashboard", path: "dashboard" },
    { label: "Census 2022 Results", path: "results" },
    { label: "Visualization", path: "visualization" },
    { label: <Badge
      badgeContent={
        <img style={{ height: "35px" }} src={newGif} alt="loading..." />
      }
    >
      <p>2022 Publications & Other Channels</p>
    </Badge>, path: "publications" },
  ];
  const menuItemsSwahili = [
    { label: "Nyumbani", path: "" },
    { label: "Dashibodi", path: "dashboard" },
    { label: "Matokeo ya Sensa 2022", path: "results" },
    { label: "Taswira", path: "visualization" },
    { label: <Badge
      badgeContent={
        <img style={{ height: "35px" }} src={newGif} alt="loading..." />
      }
    >
      <p>Machapisho ya 2022 & Njia Zingine</p>
    </Badge>, path: "publications" },
  ];
  const [selectedMainTabIndex, setSelectedMainTabIndex] = React.useState(2);
  const navigate = useNavigate();
  const handleMainTabChange = (event, newValue, path) => {
    setSelectedMainTabIndex(newValue);
    navigate(`dissemination/${path}`);
  };

  const visualizationsMenuItems = [
    language ? "Demography" : "Demografia",
    language ? "Buildings" : "Majengo",
    language ? "Social services" : "Huduma za Jamii",
  ];

  const [selectedVisualizationTabIndex, setSelectedVisualizationTabIndex] =
    React.useState(0);
  const handleVisualizationTabChange = (event, newValue) =>
    setSelectedVisualizationTabIndex(newValue);

  const demographyMenuItems = ["Population Size"];
  const [selectedDemographyTabIndex, setSelectedDemographyTabIndex] =
    React.useState(0);
  const handleDemographyTabChange = (event, newValue) =>
    setSelectedDemographyTabIndex(newValue);

  return {
    mainMenuItems,
    menuItemsSwahili,
    selectedMainTabIndex,
    handleMainTabChange,
    visualizationsMenuItems,
    selectedVisualizationTabIndex,
    handleVisualizationTabChange,
    demographyMenuItems,
    selectedDemographyTabIndex,
    handleDemographyTabChange,
    setSelectedMainTabIndex,
  };
}
