import { Typography } from "@mui/material";
import React from "react";
import dataSource from "../../../resources/img/CensusDataSource.svg";
import { useContext } from "react";
import { DashboardContext } from "../../../states/DashboardContext";
export default function CensusDataSource() {
  // import global state
  const {
    censusMapDataState,
    censusYearSliderValueState,
    censusIsDefaultDataState,
  } = useContext(DashboardContext);
  const [censusYearSliderValue, setCensusYearSliderValue] =
    censusYearSliderValueState;
  const [censusMapData, setCensusMapData] = censusMapDataState;
  const [censusIsDefaultData, setCensusIsDefaultData] =
    censusIsDefaultDataState;

  function getDataSource() {
    if (censusMapData != []) {
      let value = censusMapData.find(
        (e) =>
          parseInt(e.time_name.trim()) ===
          parseInt(censusYearSliderValue.trim())
      );
      if (value) {
        return value.source_name;
      } else return "No Data";
    }
  }

  const words = useTranslations();

  return (
    <div className="flex flex-row pt-2 ">
      <img src={dataSource} alt="Source" className="w-5 h-5" />
      <div>
        <h1>
          {words.dataSource} :
          {censusIsDefaultData === true
            ? " NBS_ Population and Housing (PHC) 2022, National Bureau of Statistics, Tanzania"
            : getDataSource()}
        </h1>
      </div>
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    dataSource: language ? "Data Source" : "Chanzo cha taarifa",
    language,
  };
};
