export { UseFetchWrapper };

function UseFetchWrapper() {
  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    patch: request("PATCH"),
    delete: request("DELETE"),
  };

  function request(method) {
    return (url, body) => {
      const requestOptions = {
        method,

        headers: {},
      };

      if (body) {
        if (body instanceof FormData) {
          requestOptions.body = body;
        } else {
          requestOptions.headers["Content-Type"] = "application/json";
          requestOptions.body = JSON.stringify(body);
        }
      }
      return fetch(url, requestOptions).then(handleResponse);
    };
  }

  function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);

      return data;
    });
  }
}
