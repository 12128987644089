import FacebookIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import PublicIcon from "@mui/icons-material/Public";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useNavigate } from 'react-router-dom';
import censusDataSharingIcon from "resources/img/censusDataSharingIcon.png";
import censusDissemIcon from "resources/img/censusDissemPlatformIcon.png";
import sensaLogo from "resources/img/censusSensaLogo.png";
import censusStoryIcon from "resources/img/censusStoryIcon.png";
import { useContext } from "react";
import { DashboardContext } from "states/DashboardContext";

import CensusLandingNavbar from 'ui/components/census/CensusLandingNavbar';
import { Typography } from "@mui/material";


export default function CensusLandingPage() {
  const navigate = useNavigate();
  const words = useTranslations();

  const Card = ({ imgSrc, imgAlt, title1, title2, description, path }) => {
    const navigate = useNavigate();
  
    return (
      <div className="w-full md:w-72 h-auto md:h-90 my-2 mr-4 text-center bg-white shadow-md hover:shadow-lg ">
        <div className="bg-[#0f7895] p-4 md:p-8 text-white ">
          <img src={imgSrc} alt={imgAlt} className=" mx-auto mt-2 items-center h-18 w-16" />
        </div>
        <div className="p-4">
          <p className="font-bold text-lg md:text-2xl text-[#0f7895]">{title1}</p>
          {title2 && <p className="font-bold text-lg md:text-2xl text-[#0f7895]">{title2}</p>}
          {/* Setting minimum height for the description */}
          <p className="flex-wrap pt-4 pb-2 text-sm md:text-base min-h-[5rem]">{description}</p>
          <button className="bg-[#0f7895] text-white w-32 h-12 md:w-44 md:h-14 px-4 py-2 mb-8 hover:bg-green-500" onClick={() => navigate(path)}>
            {words.explore} <span className="ml-2">&#8594;</span>
          </button>
        </div>
      </div>
    );
  }
  
  
  return (
    <div className="bg-gradient-to-tr from-blue-400 via-white to-green-300 min-h-screen flex flex-col">


      <CensusLandingNavbar />


      <section className=" md:px-8 text-center text-xl md:text-4xl">
        <h2 className="text-2xl font-bold pt-4">{words.welcomeQuestion}</h2>
      </section>

      <section className="p-2 pt-4 md:px-8 text-center">
        <div className="text-md w-2/3 mx-auto md:text-md">
          {words.welcomeText}
        </div>
      </section>

      <div className="flex flex-col md:flex-row my-6 mx-4 md:my-4 justify-center">
      <Card 
        imgSrc={censusStoryIcon}
        imgAlt="census Story Icon"
        title1={words.censusWord}
        title2={words.storyWord}
        description={words.censusStoryDesc}
        path="/story"
      />

      <Card 
        imgSrc={censusDissemIcon}
        imgAlt="census Dissemination Icon"
        title1={words.disseminationPlatform}
        description={words.disseminationPlatformDesc}
        path="/dissemination/results"
      />

      <Card 
        imgSrc={censusDataSharingIcon}
        imgAlt="census Data Sharing Icon"
        title1={words.dataWord}
        title2={words.sharingWord}
        description={words.dataSharingDesc}
        path="/data-sharing"
      />
    </div>




      {/* Footer */}
      <div className="flex-col mt-auto justify-between items-center"> 

        <div className="flex my-8 border-t border-black w-full"></div>
        <footer className="flex p-4 mt-auto md:px-10 justify-between items-center">
          <div className="flex space-x-2 md:space-x-4 items-center">
            <a href="https://www.facebook.com/pages/National-Bureau-of-Statistics/1495884817315060" target="_blank" className="mx-2">
              <FacebookIcon fontSize="large" className="rounded-full" />
            </a>
            <a  href="https://x.com/NBSTanzania" target="_blank" className="mx-2 h-[30px] w-[30px] top-[2px] relative">
              {/* <TwitterIcon fontSize="large" className="rounded-full" /> */}

              <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" 
                textRendering="geometricPrecision"
                imageRendering="optimizeQuality"
                fillRule="evenodd" clipRule="evenodd" 
                viewBox="0 0 512 462.799">
                <path fillRule="nonzero" 
                d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 
                0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"/>
              </svg>

            </a>
            <a href="https://www.instagram.com/nbs.tanzania/" target="_blank" className="mx-2">
              <InstagramIcon fontSize="large" className="rounded-full" />
            </a>
            <a  href="https://www.nbs.go.tz/" target="_blank" className="mx-2">
              <PublicIcon fontSize="large" className="rounded-full" />
            </a>
          </div>
          <img src={sensaLogo} alt="logo" className="w-12 h-12 md:w-24 md:h-24 rounded-full" />
        </footer>

      </div>

    </div>



  );
}


const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    title: language ? 'Census HUB' : 'Kitovu Kimoja Cha Upatikanaji Ws Taarifa Za Sensa',
    welcomeQuestion: language ? 'What is Census Hub?' : 'Nini Kitovu cha Sensa?',
    welcomeText: language ? 'A center for Accessibility and Exchange of census information that will enable various stakeholders to access census information and various processes used to achieve the entire census process exercise, such as preparing GIS areas, creating Questionnaires, Technology used etc.' : 'Kituo cha upatikanaji na ubadilishanaji wa taarifa za sensa ambacho kitawezesha wadau mbalimbali kupata taarifa za sensa na michakato mbalimbali iliyotumika  kufanikisha zoezi zima la sensa, kama vile, kutayarisha maeneo ya kuhesabia watu, kuandaa madodoso, teknologia iliyotumika n.k',
    censusWord: language ? 'Census' : 'Historia',
    storyWord: language ? 'History' : 'ya Sensa',
    censusStoryDesc: language ? 'Learn the derivation and evolution of Tanzania\'s census ' : 'Jifunze chimbuko na maendeleo ya sensa ya Tanzania',    
    disseminationPlatform: language ? 'Dissemination Platform for census data' : 'Mfumo wa usambazaji wa takwimu za sensa',
    disseminationPlatformDesc: language ? 'Use statistics for development' : 'Tumia takwimu kwa maendeleo',
    dataWord: language ? 'Data' : 'Ubadilishanaji',
    sharingWord: language ? 'Sharing' : 'wa takwimu',
    dataSharingDesc: language ? 'Get official data with our API' : 'Pata takwimu rasmi kupitia API yetu ',
    explore: language ? 'Explore' : 'Chunguza',
    language
  };
};

