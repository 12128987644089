import React from "react";
import Highcharts from "highcharts/highmaps";
import drilldow from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
import exportModule from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import geoService from "../../../util/geoserver";
import L from "leaflet";
import { useContext, useEffect, useState, useRef, useMemo } from "react";
import { DashboardContext } from "../../../states/DashboardContext";
import CensusService from "_services/Census/CensusService";
import CensusLoadingCmp from "./CensusLoadingCmp";

drilldow(Highcharts);
dataModule(Highcharts);
exportModule(Highcharts);
/// Arrange params
function getMapParams(location) {
  return L.Util.extend({
    service: "WFS",
    request: "GetFeature",
    typeName: "cite:" + location,
    outputFormat: "application/json",
  });
}

// filter according to location shapefile
function getMapFilterParams(location, cql_filter) {
  return L.Util.extend({
    service: "WFS",
    request: "GetFeature",
    typeName: "cite:" + location,
    cql_filter: cql_filter,
    outputFormat: "application/json",
  });
}

export default function DrillMap(props) {
  //Global state
  const {
    censusMapDataState,
    censusIndicatorNameState,
    censusSubGroupNameState,
    censusIsDefaultDataState,
    censusDefaultMapDataState,
    censusMapIsDrillDownState,
    censusMapDrillDownLevelState,
    censusMapDrillDownAreaNameState,
    censusRegionShapefileState,
    censusMapDrillDownDataState,
    loadingState
  } = useContext(DashboardContext);
  const [censusMapData, setCensusMapData] = censusMapDataState;

  const [censusIndicatorName, setCensusIndicatorName] =
    censusIndicatorNameState;
  const [censusSubGroupName, setCensusSubGroupName] = censusSubGroupNameState;
  const [censusIsDefaultData, setCensusIsDefaultData] =
    censusIsDefaultDataState;
  const [censusDefaultMapData, setCensusDefaultMapData] =
    censusDefaultMapDataState;
  const [censusMapIsDrillDown, setCensusMapIsDrillDown] =
    censusMapIsDrillDownState;
  const [censusMapDrillDownLevel, setCensusMapDrillDownLevel] =
    censusMapDrillDownLevelState;
  const [censusMapDrillDownAreaName, setCensusMapDrillDownAreaName] =
    censusMapDrillDownAreaNameState;
  const [censusRegionShapefile, setCensusRegionShapefile] =
    censusRegionShapefileState;
  const [censusMapDrillDownData, setCensusMapDrillDownData] =
    censusMapDrillDownDataState;
  const [loading, setLoading] = loadingState;

  //Local state
  const chartRef = useRef();
  const [mapData, setMapData] = useState([]);
  const mapKeys = ["value", "name"];
  const censusService = CensusService();
  const words = useTranslations();

  // store temporary the values of council
  var region_temp_data = [];
  var council_temp_data = [];

  // get council shapefile
  async function getCouncilData(id, drillDownDecicion, regionData) {
    const params = getMapFilterParams("CENSUS-TZ-COUNCILS", "Region_Cod=" + id);
    const results = await geoService(params);
    const secondaryData = results?.features;
    const year = props.year;
    secondaryData?.forEach((el, i) => {
      var value = null;
      el.drilldown = drillDownDecicion; // -- Drill down Decision for council
      if (censusIsDefaultData === true) {
        value =
          censusDefaultMapData.find(
            (e) =>
              parseInt(e.area_code) === parseInt(el.properties?.Council_ID) &&
              e.area_level === "LVL5" &&
              parseInt(e.time_name) === year &&
              parseInt(e.parent_code) === parseInt(id)
          ) ?? null;
      } else {
        value =
          censusMapData.find(
            (e) =>
              parseInt(e.area_code) === parseInt(el.properties?.Council_ID) &&
              e.area_level === "LVL5" &&
              parseInt(e.time_name) === year &&
              parseInt(e.parent_code) === parseInt(id)
          ) ?? null;
      }

      if (value != null && el.properties.Council_ID != null) {
        el.value = value.data_value;
      }
      if (value === null && el.properties.Council_ID != null) {
        el.value = "";
      }
      if (value === null && el.properties.Council_ID === null) {
        el.value = null;
      }
    });

    // Set drill down state
    setCensusMapIsDrillDown((prev) => {
      prev = true;
      return prev;
    });
    setCensusMapDrillDownAreaName((prev) => {
      prev = regionData;
      return prev;
    });

    setCensusMapDrillDownData(id);
    setCensusMapDrillDownLevel((prev) => {
      prev = "LVL5";
      return prev;
    });

    // These values will be used when drilling up
    region_temp_data = regionData;
    region_temp_data.push(id);
    return secondaryData;
  }

  //get constituency shapefile
  async function getConstituencyData(id, drillDownDecision, councilData) {
    const params = getMapFilterParams(
      "CENSUS-TZ-CONSTITUENCY",
      "council_id=" + id
    );
    const results = await geoService(params);
    const secondaryData = results?.features;
    const year = props.year;
    secondaryData?.forEach((el, i) => {
      var value = null;
      el.drilldown = drillDownDecision; // -- Drill down Decision for ward
      if (censusIsDefaultData === true) {
        value =
          censusDefaultMapData.find(
            (e) =>
              parseInt(e.area_code) === parseInt(el.properties?.const_id) &&
              e.area_level === "LVL6" &&
              parseInt(e.time_name) === year &&
              parseInt(e.parent_code) === parseInt(id)
          ) ?? null;
      } else {
        value =
          censusMapData.find(
            (e) =>
              parseInt(e.area_code) === parseInt(el.properties?.const_id) &&
              e.area_level === "LVL6" &&
              parseInt(e.time_name) === year &&
              parseInt(e.parent_code) === parseInt(id)
          ) ?? null;
      }

      if (value != null && el.properties.const_id != null) {
        el.value = value.data_value;
      }
      if (value === null && el.properties.const_id != null) {
        el.value = "";
      }
      if (value === null && el.properties.const_id === null) {
        el.value = null;
      }
    });

    // Set drill down state
    setCensusMapIsDrillDown((prev) => {
      prev = true;
      return prev;
    });
    setCensusMapDrillDownAreaName((prev) => {
      prev = councilData;
      return prev;
    });

    setCensusMapDrillDownData(id);
    setCensusMapDrillDownLevel((prev) => {
      prev = "LVL6";
      return prev;
    });

    // These values will be used when drilling up
    council_temp_data = councilData;
    council_temp_data.push(id);
    return secondaryData;
  }

  // get ward shapefile

  async function getWardData(id, drillDownDecicion, constituencyData) {
    const params = getMapFilterParams("CENSUS-TZ-WARDS", "council_id=" + id);
    const results = await geoService(params);
    const secondaryData = results?.features;
    const year = props.year;
    secondaryData?.forEach((el, i) => {
      var value = null;
      if (censusIsDefaultData === true) {
        value =
          censusDefaultMapData.find(
            (e) =>
              parseInt(e.area_code) === parseInt(el.properties?.ward_code) &&
              e.area_level === "LVL7" &&
              parseInt(e.time_name) === year
            // && seInt(e.parent_code) === parseInt(id)
          ) ?? null;
      } else {
        value =
          censusMapData.find(
            (e) =>
              parseInt(e.area_code) === parseInt(el.properties?.ward_code) &&
              e.area_level === "LVL7" &&
              parseInt(e.time_name) === year
            // && parseInt(e.parent_code) === parseInt(id)
          ) ?? null;
      }
      if (value != null && el.properties.ward_code != null) {
        el.value = value.data_value;

        // force changing value of ward parent to that of council
        value.parent_code = id.toString();
      }
      if (value === null && el.properties.ward_code != null) {
        el.value = "";
      }
      if (value === null && el.properties.ward_code === null) {
        el.value = null;
      }
    });

    setCensusMapDrillDownAreaName((prev) => {
      prev = constituencyData;
      return prev;
    });

    setCensusMapDrillDownData(id);

    setCensusMapDrillDownLevel((prev) => {
      prev = "LVL7";
      return prev;
    });
    return secondaryData;
  }

  // Handle drill down
  async function handleDrillDown(e) {
    if (!e.seriesOptions) {
      var chart = chartRef.current.chart;
      var fail = setTimeout(function () {
        if (!Highcharts.maps[mapKeys]) {
          chart.showLoading('<i class="icon-frown"></i> Loading ');
          fail = setTimeout(function () {
            chart.hideLoading();
          }, 1500);
        }
      }, 1500);

      chart.showLoading('<i class="icon-spinner icon-spin icon-3x"></i>'); // Font Awesome spinner

      /// new changes checking
      let data = null;
      let chartName = null;
      let propertyName = null;
      let chartDataLabel = null;
      let hasSecondaryDrill = false;
      const isRegion = !!e?.point?.properties?.Region_Cod;
      const isCouncil = !!e?.point?.properties?.Council_ID;
      const isConstituency = !!e?.point?.properties?.const_id;
      if (isCouncil) {
        chartName = e?.point?.properties?.Council_N;
        // from council go directly to wards, skip contituents. Code for constituents is muted
        // chartDataLabel = "const";
        chartDataLabel = "ward_name";
        hasSecondaryDrill = true;
        // data = await getConstituencyData(
        //   parseInt(e?.point?.properties?.Council_ID),
        //   hasSecondaryDrill,
        //   [e.point.properties.Council_N, e.point.value]
        // );

        data = await getWardData(
          parseInt(e?.point?.properties?.Council_ID),
          hasSecondaryDrill,
          [e.point.properties.Council_N, e.point.value]
        );
      } else if (isConstituency) {
        hasSecondaryDrill = false;
        chartName = e?.point?.properties?.const;
        chartDataLabel = "ward_name";
        data = await getWardData(
          parseInt(e?.point?.properties?.const_id),
          hasSecondaryDrill,
          [e.point.properties.const, e.point.value]
        );
      } else if (isRegion) {
        hasSecondaryDrill = true;
        chartName = e?.point?.region_nam;
        chartDataLabel = "Council_N";
        data = await getCouncilData(
          parseInt(e.point.properties.Region_Cod),
          hasSecondaryDrill,
          [e.point.region_nam, e.point.value]
        );
      }

      chart.hideLoading();
      chart.addSeriesAsDrilldown(e.point, {
        name: chartName,
        data: JSON.parse(JSON.stringify(data)),
        keys: mapKeys,
        type: "map",
        dataLabels: {
          enabled: true,
          // format: `${this.point.properties.District_N}`,
          formatter: function () {
            // Customize the data label
            if (this.point.value != null && this.point.value != 0) {
              return (
                "<b>" +
                this.point.properties[chartDataLabel] +
                "</b><br/>" +
                Highcharts.numberFormat(this.point.value, 0, "", ",")
              );
            } else {
              return "<b>" + this.point.properties[chartDataLabel] + "</b>";
            }
          },
        },

        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: "<ul>",
          pointFormatter: function () {
            let value = this.value === undefined ? "" : this.value;
            let txt = ``;
            txt +=
              "<h1><b>" +
              this.properties?.[chartDataLabel].toUpperCase() +
              "</b></h1></br>";
            txt += `<h1><b> ${value.toLocaleString()}</b>  </h1> </br>`;
            return txt;
          },
          footerFormat: "</ul>",
        },
      });
      chart.setTitle(null, { text: e.point.region_nam });
    }
  }

  // Handle drill up events
  function handleDrillUp(e) {
    const isCouncil = !!e?.seriesOptions.data[0].properties.Council_ID;
    const isConstituency = !!e?.seriesOptions.data[0].properties.const_id;
    if (isCouncil || isConstituency) {
      setCensusMapDrillDownLevel((prev) => {
        prev = "LVL5";
        return prev;
      });

      setCensusMapDrillDownAreaName((prev) => {
        prev = region_temp_data;
        return prev;
      });

      setCensusMapDrillDownData(region_temp_data[2]); // the Id of the region
    }
    // else if (isConstituency) {
    //   setCensusMapDrillDownLevel((prev) => {
    //     prev = "LVL6";
    //     return prev;
    //   });

    //   setCensusMapDrillDownAreaName((prev) => {
    //     prev = council_temp_data;
    //     return prev;
    //   });

    //   setCensusMapDrillDownData(council_temp_data[2]); // the Id of the council
    // }
    else {
      setCensusMapIsDrillDown((prev) => {
        prev = false;
        return prev;
      });
    }
  }

  //   Pull region shapefile
  useEffect(() => {
    async function loadRegions() {
      //  check if the shapefile is in local storage
      setLoading(true);
      // Check if the region shapefile exist in local storage
      if (localStorage.getItem("region") !== null) {
        var retrieveShp = localStorage.getItem("region");
        setCensusRegionShapefile((prev) => {
          prev = JSON.parse(retrieveShp);
          return prev;
        });
      } else {
        // if not in local storage, retrieve it from the geoserver
        const params = getMapParams("CENSUS-TZ-REGIONS");
        const results = await geoService(params);
        localStorage.setItem("region", JSON.stringify(results));
        setCensusRegionShapefile((prev) => {
          prev = results;
          return prev;
        });
      }

      const default_data = await censusService.getDefaultMapData();

      setCensusDefaultMapData(default_data);
      setLoading(false);
    }

    if (censusMapIsDrillDown === false) {
      loadRegions();
    }
  }, []);

  //   Change map data
  useEffect(() => {
    const fetchData = async () => {
      const year = props.year;
      const temp_shapefile = structuredClone(censusRegionShapefile);
      const data = temp_shapefile.features;
      data?.forEach((el, i) => {
        el.drilldown = el.properties?.Region_Cod;
        el.region_nam = el.properties?.Region_Nam;
        var value = null;
        if (censusIsDefaultData === true) {
          value =
            censusDefaultMapData.find(
              (e) =>
                parseInt(e.area_code) === parseInt(el.properties?.Region_Cod) &&
                e.area_level === "LVL3" &&
                parseInt(e.time_name) === year
            ) ?? null;
          if (value != null && el.properties.Region_Cod != null) {
            el.value = value.data_value;
          }
          if (value === null && el.properties.Region_Cod != null) {
          }
          if (value === null && el.properties.Region_Cod === null) {
            el.value = null;
          }
        }
        if (censusIsDefaultData === false) {
          value =
            censusMapData.find(
              (e) =>
                parseInt(e.area_code) === parseInt(el.properties?.Region_Cod) &&
                e.area_level === "LVL3" &&
                parseInt(e.time_name) === year
            ) ?? null;
          if (value != null && el.properties.Region_Cod != null) {
            el.value = value.data_value;
          }
          if (value === null && el.properties.Region_Cod != null) {
          }
          if (value === null && el.properties.Region_Cod === null) {
            el.value = null;
          }
        }
        if (value === null && el.properties.Region_Cod === null) {
          el.value = null;
        }
      });
      setMapData(data);
      chartRef?.current?.chart?.drillUp();
      // Ensure that the drill is false
      setCensusMapIsDrillDown((prev) => {
        prev = false;
        return prev;
      });
    };

    fetchData();
  }, [censusMapData, props.year, censusRegionShapefile, censusDefaultMapData]);

  // The map options
  const options = useMemo(() => {
    return {
      chart: {
        type: "map",
        height: `${props.mapHeight}`,
        animation: {
          defer: 0,
          duration: 1000,
        },
        events: {
          drilldown: async (e) => {
            handleDrillDown(e);
          },
          drillup: (e) => {
            // Set drilldown state
            handleDrillUp(e);
          },
          load: (e) => {
            //  cleanup area
          },
        },
      },
      title: {
        // if default data, display name population size, else indicator
        text: censusIsDefaultData
          ? words.populationSizeTotal
          : `${censusIndicatorName} - ${censusSubGroupName}`,
      },
      subtitle: {},
      credits: {
        enabled: true,
        position: {
          align: "center",
          x: 20,
        },
        text: "NBS Tanzania",
        href: "https://www.nbs.go.tz/index.php/en/census-surveys/gis/568-tanzania-districts-shapefiles-2019",
      },
      legend: {
        layout: "vertical",
        align: "left",
        verticalAlign: "middle",
      },

      colorAxis: {
        min: 0,
        minColor: "#F2FFE9",
        maxColor: "#1E6F5C",
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: "top",
          align: "right",
        },
        x: 10,
      },

      plotOptions: {
        map: {
          states: {
            hover: {
              color: "#EEDD66",
            },
          },
          animation: {
            defer: 0,
            duration: 1000,
          },
        },
      },

      series: [
        {
          name: "base",
          nullColor: "#87cefa",
          allAreas: true,
          allAreas: false,
          color: "#fff",
        },
        {
          data: mapData,
          nullColor: "#87cefa",
          type: "map",
          name: "Region",
          allAreas: false,
          color: "#fff",
          borderWidth: 1,
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.point.value != null && this.point.value != 0) {
                return (
                  "<b>" +
                  this.point.properties.Region_Nam +
                  "</b><br/>" +
                  Number(String(this.point.value))
                  // Highcharts.numberFormat(this.point.value, 2, ".", ",")
                );
              } else {
                return "<b>" + this.point.properties.Region_Nam + "</b>";
              }
            },

            style: {
              color: "#fff",
              textShadow: "1px 1px 0 #000",
              fontSize: "14px",
            },
          },
          tooltip: {
            shared: true,
            useHTML: true,
            headerFormat: "<ul>",
            pointFormatter: function () {
              let value = this.value === undefined ? "" : this.value;
              let txt = ``;
              txt +=
                "<h1><b>" +
                this.properties?.Region_Nam.toUpperCase() +
                "</b></h1></br>";
              txt += `<h1><b> ${value.toLocaleString()}</b>  </h1> </br>`;
              return txt;
            },
            footerFormat: "</ul>",
          },
        },
      ],
      drilldown: {
        activeDataLabelStyle: {
          color: "#FFFFFF",
          textDecoration: "none",
          textOutline: "1px #000000",
        },
        drillUpButton: {
          relativeTo: "spacingBox",
          position: {
            x: 0,
            y: 60,
          },
        },
      },
    };
  }, [mapData, props.year]);

  return (
    <div className="w-full h-full justify-center items-center">
      <div className="w-full justify-center flex items-center">
        <p className="text-[#50A93F]">{words.drillMessage}</p>
      </div>
      <div className="w-full">
        {loading ? (
          <CensusLoadingCmp />
        ) : (
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={options}
            constructorType={"mapChart"}
          />
        )}
      </div>
    </div>
  );
}

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    populationSizeTotal: language
      ? "Population size - total"
      : "Idadi ya watu -Jumla",
    drillMessage: language
      ? "Click a specific region to get lower level data."
      : "Bofya mkoa husika kupata taarifa za ngazi ya chini.",
    language,
  };
};
