import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { BsSearch } from "react-icons/bs";
import { CircularProgress, ListItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import IconButton from '@mui/material/IconButton';
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from '@mui/icons-material/Close';
import CensusService from "../../../_services/Census/CensusService";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { DashboardContext } from "../../../states/DashboardContext";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
// Cesnsus service
const censusService = CensusService();

export default function CensusDropDownGroup() {
  // global state


  const {
    dataSourceState,
    censusMapDataState,
    filterDataState,
    censusIndicatorNameState,
    censusSubGroupNameState,
    censusIsDefaultDataState,
    languageState,
    censusSelectedIndicatorState,
    loadingState,
    sectorState,
    subsectorState, 
    indicatorState,
    subgroupState
  } = useContext(DashboardContext);

  const [dataSource, setDatasource] = dataSourceState;
  const [censusMapData, setCensusMapData] = censusMapDataState;
  const [censusIndicatorName, setCensusIndicatorName] =
    censusIndicatorNameState;
  const [censusSubGroupName, setCensusSubGroupName] = censusSubGroupNameState;
  const [censusIsDefaultData, setCensusIsDefaultData] =
    censusIsDefaultDataState;
  const [filterData, setFilterData] = filterDataState;
  const [language] = languageState;
  const [censusSelectedIndicator, setCensusSelectedIndicator] = censusSelectedIndicatorState
  const [loading, setLoading] = loadingState
  const [sector, setSector] = sectorState
  const [subsector, setSubsector] = subsectorState
  const [indicator, setIndicator] = indicatorState
  const [subgroup, setSubgroup] = subgroupState
  // component state
  const [expandedSectors, setExpandedSectors] = useState(
    new Set()
  );
  const [expandedSubsectors, setExpandedSubsectors] = useState(
    new Set()
  );
  const [expandedIndicators, setExpandedIndicators] = useState(
    new Set()
  );


  // function to manage selection and disselection of indicators
  function setIndicators(indicatorkey, subgroupkey, indicator_name, subgroup_name){

    

    setCensusSelectedIndicator((state) => {
      const existingIndicatorIndex = state.findIndex(
        (item) => item.indicatorkey === indicatorkey
      );

      if (existingIndicatorIndex !== -1) {
        // If the indicator exists, remove the existing subgroupkey
        const updatedIndicators = [...state];
        const existingSubgroupIndex =
          updatedIndicators[existingIndicatorIndex].subgroupkey.indexOf(
            subgroupkey
          );

        if (existingSubgroupIndex !== -1) {
          updatedIndicators[existingIndicatorIndex].subgroupkey.splice(
            existingSubgroupIndex,
            1
          );
        }

        if (existingSubgroupIndex === -1) {
          // If subgroupkey doesn't exist, add it to the list
          updatedIndicators[existingIndicatorIndex].subgroupkey.push(
            subgroupkey
          );
        }

        // If subgroupkey is empty after removal, remove the whole indicator
        if (
          updatedIndicators[existingIndicatorIndex].subgroupkey.length === 0
        ) {
          updatedIndicators.splice(existingIndicatorIndex, 1);
        }

        // if the selected indicator list is null, reset the map data
        setCensusMapData([])

        return updatedIndicators
      } else {
        // If the indicator does not exist, add a new one
        return [
          ...state,
          { indicatorkey: indicatorkey, subgroupkey: [subgroupkey] },
        ];
      }
    });

  }

  const toggleSet = (set, key) => {
    const newSet = new Set(set);
    if (newSet.has(key)) {
      newSet.delete(key);
    } else {
      newSet.add(key);
    }
    return newSet;
  };

  const toggleSector = (sectorKey) =>
    setExpandedSectors((prev) => toggleSet(prev, sectorKey));
  const toggleSubsector = (subsectorKey) =>
    setExpandedSubsectors((prev) => toggleSet(prev, subsectorKey));
  const toggleIndicator = (indicatorKey) =>
    setExpandedIndicators((prev) => toggleSet(prev, indicatorKey));

  const isSelected = (indicatorKey, subgroupKey) => {
    
    if (subgroupKey !== undefined) {
      return censusSelectedIndicator.some((item) => {
        if (item.indicatorkey === indicatorKey) {
          return item.subgroupkey?.some((t) => subgroupKey === t);
        }
        return false;
      });
    }

    return censusSelectedIndicator.some((item) => item.indicatorkey === indicatorKey);
  };

  // for search
  const [searchText, setSearchText] = React.useState("");
  const handleSearchTextChange = (e) => setSearchText(e.target.value); //fnx to set search text
  const [filteredRows, setFilteredRows] = React.useState([]);

  // Function to remove duplicates based on all properties of the object
  function removeDuplicates(array, properties) {
    return array.filter(
      (item, index, self) =>
        self.findIndex((t) =>
          properties.every((property) => t[property] === item[property])
        ) === index
    );
  }

  // Fetch filterpane data from API
  useEffect(() => {
    if (filterData.length === 0) {
      const fetchFilterpaneData = async () => {
        try {
          setLoading(true);
          const data = await censusService.getFilterPaneData();
          setFilterData(
            removeDuplicates(data, ["sectorkey", "indicatorkey", "subgroupkey"])
          );

          const filtered =  removeDuplicates(data, ["sectorkey", "indicatorkey", "subgroupkey"])

          // sectors
          setSector(
            [
              ...new Map(
                filtered.map((v) => [JSON.stringify([v.sectorkey, v.sector_name]), v])
              ).values(),
            ]
          )

          // subsectors
          setSubsector(
            [
              ...new Map(
                filtered.map((v) => [
                  JSON.stringify([v.subsectorkey, v.subsector_name]),
                  v,
                ])
              ).values(),
            ]
          )

          // set indiccators
          setIndicator(
                      [
              ...new Map(
                filtered.map((v) => [
                  JSON.stringify([v.indicatorkey, v.indicator_name]),
                  v,
                ])
              ).values(),
            ]
          )

          // subgroups

          setSubgroup(
            [
              ...new Map(
                filtered.map((v) => [
                  JSON.stringify([v.indicatorkey, v.subgroupkey, v.subgroup_name]),
                  v,
                ])
              ).values(),
            ]
          )

        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      fetchFilterpaneData();
    }
  }, []);


 
  useEffect(() => {
    if (searchText === "") {
      setFilteredRows((prev) => {
        prev = [];
        return prev;
      });
    } else {
      setFilteredRows((prev) => {
        const searchTerms = searchText
          .toLowerCase()
          .split(/[ ,]+/) // Use a regular expression to split by spaces and commas
          .filter((term) => term !== ""); // Filter out any empty strings from the split

        prev = filterData
          .map((row) => {
            const indicatorMatch = searchTerms.filter((term) =>
              row.indicator_name.toLowerCase().includes(term)
            );
            const subgroupMatch = searchTerms.filter((term) =>
              row?.subgroup_name?.toLowerCase().includes(term)
            );
            const matchScore = indicatorMatch.length + subgroupMatch.length;
            return { ...row, matchScore };
          })
          .filter((row) => row.matchScore > 0)
          .sort((a, b) => b.matchScore - a.matchScore);
        return prev;
      });
    }
  }, [searchText, filterData]);



  // fetch datavalues
  async function getDataValue() {
    setLoading(true);
    let data = await censusService.getDashboardMapData(censusSelectedIndicator)
    setCensusMapData(data);
    setCensusIsDefaultData(false)
    setLoading(false);
  }
  // when a search result is clicked
  const handleClickSearchResult = (item) => {

    
    setIndicators(item.indicatorkey, item.subgroupkey)
    // clear the filteredrows array
    setFilteredRows((prev) => {
      prev = [];
      return prev;
    });
    
    setCensusIsDefaultData(false);
  };

  // style
  const listItemStyle = {
    backgroundColor: "#ffffff",
    borderRadius: "2px",
    marginBottom: "8px",
    paddingLeft: "16px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.2s ease-in-out", // Add a smooth transition effect
    cursor: "pointer", // Show the pointer cursor on hover
  };

  // Handle modal
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);

   
    
  };

  useEffect(()=>{
    if (censusSelectedIndicator.length !==0 && anchorEl ===null){
      getDataValue()
    }
  },[censusSelectedIndicator,anchorEl ])

  const open = Boolean(anchorEl);
  const id = open ? "Select Indicators" : undefined;
 
  return (
    <div className="relative flex flex-col gap-1 p-2">
      <div className="flex flex-row items-center justify-between">
      <Button  sx={{ backgroundColor:"#0f7895",textTransform: "none"}} aria-describedby={id} variant="contained" onClick={handleClick}>
          <AddIcon className="border mr-1" fontSize="small" />
          Select indicators
        </Button>
      <div className="sticky top-0 left-0 z-10 px-3 w-[70%] flex items-center justify-between gap-2 text-sm tracking-wide border border-[#C5C5C5] bg-[#F7F7F7] rounded-lg outline-none">
        <BsSearch className="text-lg text-gray-500" />
        <input
          className="w-full h-full py-2 bg-transparent outline-none "
          placeholder={
            language === true ? "Search by name" : "Tafuta  kwa jina"
          }
          value={searchText}
          onChange={handleSearchTextChange}
        />
      </div>
      </div>
      <Collapse in={filteredRows.length !== 0}>
        <List>
          {filteredRows.map((result, index) => (
            <ListItem
              key={index}
              style={listItemStyle}
              onClick={() =>handleClickSearchResult(result)}
            >
              <ListItemText
                primary={result.indicator_name + " (" + result.subgroup_name + ')'}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>

        <div spacing="xs" className="flex flex-row flex-wrap">
          {censusSelectedIndicator.map((item) => {
            const indicatorItem = indicator.find(
              (i) => i.indicatorkey === item.indicatorkey
            );

            const indicatorName =
              indicatorItem?.indicator_name ?? "Unknown Indicator";

            const subgroupNames = item.subgroupkey
              ? item.subgroupkey.map((subgroupKey, subgroupIndex) => {
                  const subgroupItem = subgroup.find(
                    (s) => s.subgroupkey === subgroupKey
                  );
                  const subgroupName = subgroupItem
                    ? subgroupItem.subgroup_name
                    : "";
                  return (
                    <div key={subgroupIndex}  className="flex flex-row items-center"
                    
                    style={{
                      display: "inline-flex",
                      background: "#f5f5f5",
                      border: "0.5px solid #0f7895",
                      // padding: "1px",
                      marginRight: "1px",
                      borderRadius: "4px",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "center",
                      justifyContent: "space-between",
                    }}
        
                    
                    >
                      <p color="blue" >
                        {`${indicatorName} (${subgroupName})`}
                      </p>
                      <IconButton
                        size="small"
                        onClick={() =>
                          setIndicators(item.indicatorkey, subgroupKey)
                        }
                      >
                        <CloseIcon size={12} />
                      </IconButton >
                    </div>
                  );
                })
              : [];

            return subgroupNames;
          })}
        </div>
      {/* here starts popover  */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {loading ? (
          <div className="p-6">
            <CircularProgress size={'1.25rem'} className="h-4 w-4" />
          </div>
        ) : (
          <>
            <Typography sx={{ p: 2 }}>Select Indicators</Typography>

            {filteredRows.length === 0 && searchText.length === 0 && (
              <List component="nav">
                {sector.map((sec) => (
                  <ListItem
                    key={sec.sectorkey}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                    }}
                  >
                    <ListItemButton
                      sx={{ pl: 2 }}
                      onClick={() => toggleSector(sec.sectorkey)}
                      dense={true}
                    >
                      {expandedSectors.has(sec.sectorkey) ? (
                        <RemoveIcon className="border mr-1" fontSize="small" />
                      ) : (
                        <AddIcon className="border mr-1" fontSize="small" />
                      )}
                      <ListItemText primary={sec.sector_name} />
                    </ListItemButton>
                    {/* Sub Sector */}
                    <Collapse
                      in={expandedSectors.has(sec.sectorkey)}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div">
                        {subsector.map(
                          (subsec) =>
                            sec.sectorkey === subsec.sectorkey && (
                              <ListItem
                                key={subsec.subsectorkey}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "start",
                                  alignItems: "start",
                                }}
                              >
                                <ListItemButton
                                  sx={{ pl: 2 }}
                                  onClick={() =>
                                    toggleSubsector(subsec.subsectorkey)
                                  }
                                  dense={true}
                                >
                                  {expandedSubsectors.has(subsec.subsectorkey) ? (
                                    <RemoveIcon
                                      className="border mr-1"
                                      fontSize="small"
                                    />
                                  ) : (
                                    <AddIcon
                                      className="border mr-1"
                                      fontSize="small"
                                    />
                                  )}
                                  <ListItemText primary={subsec.subsector_name} />
                                </ListItemButton>
                                {/* Indicator */}
                                <Collapse
                                  in={expandedSubsectors.has(
                                    subsec.subsectorkey
                                  )}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div">
                                    {indicator.map(
                                      (ind) =>
                                        // display only required values
                                      subsec.subsectorkey ===ind.subsectorkey && (
                                          <ListItem
                                            key={ind.indicatorkey}
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "start",
                                              alignItems: "start",
                                            }}
                                            
                                          >
                                            <ListItemButton
                                                sx={{ pl: 2 }}
                                                dense={true}
                                                onClick={() =>
                                                  toggleIndicator(
                                                    ind.indicatorkey
                                                  )
                                                }
                                              >
                                                <Checkbox
                                                  disabled={true}
                                                  checked={isSelected(
                                                    ind.indicatorkey
                                                  )}
                                                  hidden={isSelected(
                                                    ind.indicatorkey
                                                  )}
                                                  
                                                />
                                                  {expandedIndicators.has(ind.indicatorkey)? (
                                                    <RemoveIcon
                                                      className="border mr-1"
                                                      fontSize="small"
                                                      
                                                    />
                                                  ) : (
                                                    <AddIcon
                                                      className="border mr-1"
                                                      fontSize="small"
                                                      
                                                    />
                                                  )}
                                                  <ListItemText
                                                    primary={ind.indicator_name}
                                                  />
                                              </ListItemButton>
                                            {/* Sub group */}
                                            <Collapse
                                              in={expandedIndicators.has(
                                                ind.indicatorkey
                                              )}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                                {subgroup.map((subgrp) =>
                                                  ind.indicatorkey ===
                                                  subgrp.indicatorkey &&
                                                  subgrp.subgroup_name ? (
                                                    <List component="div">
                                                    <ListItem
                                                      key={subgrp.subgroupkey}
                                                      sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "start",
                                                        alignItems: "start",
                                                      }}
                                                    >
                                                      <Checkbox sx={{pl:6}}
                                                      checked={isSelected(
                                                        ind.indicatorkey,
                                                        subgrp.subgroupkey
                                                      )}
                                                      onChange={() =>
                                                        setIndicators(
                                                          ind.indicatorkey,
                                                          subgrp.subgroupkey,
                                                          ind.indicator_name,
                                                          subgrp.subgroup_name
                                                        )
                                                      }
                                                    />
                                                    <ListItemText  primary={subgrp.subgroup_name}/>
                                                    </ListItem>
                                              </List>
                                                  ) : null
                                                )}
                                            </Collapse>
                                          </ListItem>
                                        )
                                    )}
                                  </List>
                                </Collapse>
                              </ListItem>
                            )
                        )}
                      </List>
                    </Collapse>
                  </ListItem>
                ))}
              </List>
            )}
          </>
        )}
      </Popover>
      {/* Here ends popover */}
    </div>
  );
}
